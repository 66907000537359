import React from 'react';
import { Container } from 'react-bootstrap';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import AccordionUI from '../../UI/AccordionUI/AccordionUI';
import img from '../../../assets/images/jobs/section-9/FAQs.svg';

const Jobs9 = props =>(
    <section
        id="jobs-section-9"
        className="my pt-5">
            <Container>
                <div className="title-triangle top-xl fw-600"></div>
                <h2 className="h4 black fw-600 mb-5">{props.t('jobs:section-9.title')}</h2>
                <AccordionUI {...props}>
                    <Auxiliary img={img} title={props.t('jobs:section-9.1.title')} text={props.t('jobs:section-9.1.text')} link={props.t('jobs:section-9.1.link.to')} linkText={props.t('jobs:section-9.1.link.text')} />
                    <Auxiliary img={img} title={props.t('jobs:section-9.2.title')} text={props.t('jobs:section-9.2.text')} link={props.t('jobs:section-9.2.link.to')} linkText={props.t('jobs:section-9.2.link.text')}  />
                    <Auxiliary img={img} title={props.t('jobs:section-9.3.title')} text={props.t('jobs:section-9.3.text')} />
                    <Auxiliary img={img} title={props.t('jobs:section-9.4.title')} text={props.t('jobs:section-9.4.text')} />
                    <Auxiliary img={img} title={props.t('jobs:section-9.5.title')} text={props.t('jobs:section-9.5.text')} />
                    <Auxiliary img={img} title={props.t('jobs:section-9.6.title')} text={props.t('jobs:section-9.6.text')} />
                    <Auxiliary img={img} title={props.t('jobs:section-9.7.title')} text={props.t('jobs:section-9.7.text')} link={props.t('jobs:section-9.7.link.to')} linkText={props.t('jobs:section-9.7.link.text')}  />
                </AccordionUI>
            </Container>
        </section>
);

export default Jobs9;