import React from 'react';
import { Container } from 'react-bootstrap';
import ItemDashedSection from './ItemDashedSection';

const DashedSection = props => {
    const countItens = props.children.length;
    const itens = props.children.map( (child, i ) => 
        (
            <ItemDashedSection 
                key={i}
                className={countItens === i + 1 && 'last'}
                left={child.props.number%2 === 0}
                number={child.props.number}
                title={child.props.title}
                img={child.props.img} />
        )
    ); 
    return(
        <section 
          id={props.id}
          className="dashedSection my-4 py-4 my-md-5 py-md-5">
            <Container>
                <div className="title-triangle top-md fw-600"></div>
                <h2 className="h4 black fw-600 mb-5">{props.title}</h2>
                {itens}
            </Container>
        </section>
    );
}

export default DashedSection;