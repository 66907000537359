import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/products-banner/Software-Salas-Reuniao.png";
import bannerImageWebpp from "../../../../assets/images/products-banner/Software-Salas-Reuniao.webp";

const MeetingRoom = props => (
        <Banner 
            id="meetingRoom-banner" 
            className="banner black pb-5 products-banner" 
            title="products:meetingRoom:section-1.title"
            text="products:meetingRoom:section-1.txt"
            t={props.t} 
            imageBannerRight={bannerImage} 
            imageBannerRightWebp={bannerImageWebpp}
            timeDelay={1.55}    
        />
);

export default MeetingRoom;