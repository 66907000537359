import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/utility';

const initialState = {
    jobs: null,
    error: false,
    jobStructuredData: null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_JOBS_SUCESS:
            return updateObject(state, { jobs: action.jobs, error: false });
        case actionTypes.SET_JOBS_FAIL:
            return updateObject(state, { jobs: null, error: true });
        case actionTypes.SET_JOB_STRUCTURED_DATA:
            return updateObject(state, { jobStructuredData: action.structured_data });
        default:
            return state;
    }
}

export default reducer;