import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { instanceMlog } from '../../axios';

export function* trySetJobsSaga(action) {
    try {
        const response = yield instanceMlog.get('/RecruitmentRequest/GetRecruitmentRequestWebsiteSharingJobs?Page=1&PageSize=2000',
            {
                headers: {
                    "content-type": "application/json",
                },
            }
        );
        //const response = yield ats.get();
        yield put(actions.sucessSetJobs(Object.assign({}, response.data)));
    } catch (error) {
        yield put(actions.failSetJobs());
    }
}