import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/utility';

const initialState = {
    locale: null,
    redirectTo: null
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ){
        case actionTypes.SET_LOCALE_SUCESS: return updateObject( state, { locale: action.locale });
        case actionTypes.REDIRECT_SUCESS : return updateObject( state, { redirectTo: action.redirectTo } );
        case actionTypes.REDIRECT_FAIL : return updateObject( state, { redirectTo: null } );
        default:
            return state;
    }
}

export default reducer;