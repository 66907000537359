import React from 'react';

const titleDegrade = props => {
    const classes = props.className ? props.className + " grey-degrade my-3 ml-3 mr-3" : "grey-degrade my-3 ml-3 mr-3";
    return (
        <div id={props.id} className={classes}>
            <h3 className="h5 fw-600 m-0">{props.title}</h3>
        </div>
    )
};

export default titleDegrade;