import React from 'react';
import IconTitleButton from '../../UI/IconTitleButton/IconTitleButton';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import icon1 from "../../../assets/images/homepage/section-5/gestao-assiduidade.svg";
import icon2 from "../../../assets/images/homepage/section-5/gestao-projetos.svg";
import icon3 from "../../../assets/images/homepage/section-5/gestao-creditos.svg";
import icon4 from "../../../assets/images/homepage/section-5/reserva-salas-reunioes.svg";
import icon5 from "../../../assets/images/homepage/section-5/gestao-papel-comercial.svg";
import icon6 from "../../../assets/images/homepage/section-5/faturacao-bancos.svg";
import icon7 from "../../../assets/images/homepage/section-5/gestao-assembleias-acionistas.svg";

const Tecnologies = props => (
    <IconTitleButton
        id="homepage-section3"
        title={props.t("homepage:section-5.title")}>
        <Aux 
            link={props.t('routes:attendenceManagement.path')} 
            title={props.t('homepage:section-5.attendenceManagement')} 
            image={icon1} 
            alt={props.t('homepage:section-5.attendenceManagement_alt')} />
        <Aux 
            link={props.t('routes:projectManagement.path')} 
            title={props.t('homepage:section-5.projectManagement')} 
            image={icon2} 
            alt={props.t('homepage:section-5.projectManagement_alt')} />
        <Aux 
            link={props.t('routes:creditManagement.path')} 
            title={props.t('homepage:section-5.creditManagement')} 
            image={icon3} 
            alt={props.t('homepage:section-5.creditManagement_alt')} />
        <Aux 
            link={props.t('routes:meetingRoom.path')} 
            title={props.t('homepage:section-5.meetingRoom')} 
            image={icon4} 
            alt={props.t('homepage:section-5.meetingRoom_alt')} />
        <Aux 
            link={props.t('routes:comercialPaper.path')} 
            title={props.t('homepage:section-5.comercialPaper')} 
            image={icon5} 
            alt={props.t('homepage:section-5.comercialPaper_alt')} />
        <Aux 
            link={props.t('routes:billingSoftware.path')} 
            title={props.t('homepage:section-5.billingSoftware')} 
            image={icon6} 
            alt={props.t('homepage:section-5.billingSoftware_alt')} />
        <Aux 
            link={props.t('routes:shareholderMeeting.path')} 
            title={props.t('homepage:section-5.shareholderMeeting')} 
            image={icon7} 
            alt={props.t('homepage:section-5.shareholderMeeting_alt')} />
    </IconTitleButton>
);

export default Tecnologies;