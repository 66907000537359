import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/products-banner/Software-Gestao-Creditos.png";

const CreditManagementBanner = props => (
        <Banner 
            id="creditManagement-banner" 
            className="banner black pb-5 products-banner" 
            title="products:creditManagement:section-1.title"
            text="products:creditManagement:section-1.txt"
            t={props.t} 
            imageBannerRight={bannerImage} 
            timeDelay={1.55} 
        />
);

export default CreditManagementBanner;