import React from 'react';
import { Container } from 'react-bootstrap';
import AccordionUI from '../../../UI/AccordionUI/AccordionUI';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

const outsourcing2 = props => (
    <section id="outsourcing-section2" className="my pt-5 services">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('services:outsourcing:section-2.title')}</h2>
            <AccordionUI className="bg p-4 p-sm-5" {...props}>
                <Auxiliary title={props.t('services:outsourcing:section-2.subtitle1')} text={props.t('services:outsourcing:section-2.txt1')}  />
                <Auxiliary title={props.t('services:outsourcing:section-2.subtitle2')} text={props.t('services:outsourcing:section-2.txt2')}  />
                <Auxiliary title={props.t('services:outsourcing:section-2.subtitle3')} text={props.t('services:outsourcing:section-2.txt3')}  />
                <Auxiliary title={props.t('services:outsourcing:section-2.subtitle4')} text={props.t('services:outsourcing:section-2.txt4')}  />
                <Auxiliary title={props.t('services:outsourcing:section-2.subtitle5')} text={props.t('services:outsourcing:section-2.txt5')}  />
                <Auxiliary title={props.t('services:outsourcing:section-2.subtitle6')} text={props.t('services:outsourcing:section-2.txt6')}  />
            </AccordionUI>
        </Container>
    </section>
);


export default outsourcing2;