import React from 'react';
import VideoText from '../../UI/VideoText/VideoText';

const Jobs8 = props =>(
    <VideoText
        id="jobs-section8"
        className="my"
        video={props.t('jobs:section-8.video')}
        title={props.t('jobs:section-8.title')}
        subTitle={props.t('jobs:section-8.subtitle')}
        text={props.t('jobs:section-8.text')} />
);

export default Jobs8;