import React, { Component, Suspense } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import langRouter from './hoc/LangRouter/LangRouter';
import Homepage from './containers/Homepage/Homepage';
import ThankYou from './containers/ThankYou/ThankYou';
import Consulting from './containers/Services/Consulting/Consulting';
import Development from './containers/Services/Development/Development';
import Nearshoring from './containers/Services/Nearshoring/Nearshoring';
import Outsourcing from './containers/Services/Outsourcing/Outsourcing';
import Quality from './containers/Services/Quality/Quality';
import AttendenceManagement from './containers/Products/AttendenceManagement/AttendenceManagement';
import BillingSoftware from './containers/Products/BillingSoftware/BillingSoftware';
import ComercialPaper from './containers/Products/ComercialPaper/ComercialPaper';
import CreditManagement from './containers/Products/CreditManagement/CreditManagement';
import MeetingRoom from './containers/Products/MeetingRoom/MeetingRoom';
import ProjectManagement from './containers/Products/ProjectManagement/ProjectManagement';
import ShareholderMeeting from './containers/Products/ShareholderMeeting/ShareholderMeeting';
import Jobs from './containers/Jobs/Jobs';
import Cookies from './containers/Policy/Cookies/Cookies';
import Privacy from './containers/Policy/Privacy/Privacy';
import PersonalData from './containers/Policy/PersonalData/PersonalData';
import Contacts from './containers/Contacts/Contacts';
import New from './containers/Blog/New';
import Blog from './containers/Blog/Blog';
import * as actions from './store/actions/index';
import Layout from './hoc/Layout/Layout';
import './App.scss';
import Spinner from './components/UI/Spinner/Spinner';
import Rgpc from './containers/Services/Rgpc/Rgpc';
const Page404 = React.lazy(() => import('./containers/Errors/Page404/Page404'));


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showBanner: (this.props.cookiesAccepted !== null || localStorage.getItem('cookiesAccepted') !== null) ? false : true,
      arrayRoutes: {
        'en': this.props.i18n.getResource('en', 'routes'),
        'pt': this.props.i18n.getResource('pt', 'routes'),
        'de': this.props.i18n.getResource('de', 'routes'),
      }
    }
  }


  render() {
    return (
      <Layout cookies={this.props.cookies} showBanner={this.state.showBanner} hideBanner={() => this.setState({ showBanner: false })} {...this.props}>
        <Switch>
          <Route
            path={[
              this.state.arrayRoutes.pt.development.path,
              this.state.arrayRoutes.en.development.path,
              this.state.arrayRoutes.de.development.path
            ]}
            component={Development} exact />
          <Route
            path={[
              this.state.arrayRoutes.pt.consulting.path,
              this.state.arrayRoutes.en.consulting.path,
              this.state.arrayRoutes.de.consulting.path
            ]}
            component={Consulting} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.outsourcing.path,
              this.state.arrayRoutes.en.outsourcing.path,
              this.state.arrayRoutes.de.outsourcing.path
            ]}
            component={Outsourcing} exact />


          <Route
            path={[
              this.state.arrayRoutes.pt.quality.path,
              this.state.arrayRoutes.en.quality.path,
              this.state.arrayRoutes.de.quality.path
            ]}
            component={Quality} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.nearshoring.path,
              this.state.arrayRoutes.en.nearshoring.path,
              this.state.arrayRoutes.de.nearshoring.path
            ]}
            component={Nearshoring} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.projectManagement.path,
              this.state.arrayRoutes.en.projectManagement.path,
              this.state.arrayRoutes.de.projectManagement.path,
            ]}
            component={ProjectManagement} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.attendenceManagement.path,
              this.state.arrayRoutes.en.attendenceManagement.path,
              this.state.arrayRoutes.de.attendenceManagement.path,
            ]}
            component={AttendenceManagement} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.meetingRoom.path,
              this.state.arrayRoutes.en.meetingRoom.path,
              this.state.arrayRoutes.de.meetingRoom.path,
            ]}
            component={MeetingRoom} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.shareholderMeeting.path,
              this.state.arrayRoutes.en.shareholderMeeting.path,
              this.state.arrayRoutes.de.shareholderMeeting.path,
            ]}
            component={ShareholderMeeting} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.creditManagement.path,
              this.state.arrayRoutes.en.creditManagement.path,
              this.state.arrayRoutes.de.creditManagement.path,
            ]}
            component={CreditManagement} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.comercialPaper.path,
              this.state.arrayRoutes.en.comercialPaper.path,
              this.state.arrayRoutes.de.comercialPaper.path,
            ]}
            component={ComercialPaper} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.billingSoftware.path,
              this.state.arrayRoutes.en.billingSoftware.path,
              this.state.arrayRoutes.de.billingSoftware.path,
            ]} component={BillingSoftware} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.jobs.path,
              this.state.arrayRoutes.en.jobs.path,
              this.state.arrayRoutes.de.jobs.path,
            ]} component={Jobs} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.job.path,
              this.state.arrayRoutes.en.job.path,
              this.state.arrayRoutes.de.job.path,
            ]} render={() => <Suspense fallback={<Spinner />}><Jobs /></Suspense>} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt.cookies.path,
              this.state.arrayRoutes.en.cookies.path,
              this.state.arrayRoutes.de.cookies.path,
            ]}
            render={() => (<Cookies cookies={this.props.cookies}
              showBanner={() => this.setState({ showBanner: true })} />)}
            exact />

          <Route path={[
            this.state.arrayRoutes.pt.privacy.path,
            this.state.arrayRoutes.en.privacy.path,
            this.state.arrayRoutes.de.privacy.path,
          ]} component={Privacy} exact />

          <Route
            path={[
              this.state.arrayRoutes.pt['personal-data'].path,
              this.state.arrayRoutes.en['personal-data'].path,
              this.state.arrayRoutes.de['personal-data'].path,
            ]} component={PersonalData} exact />

          <Route path={[
            this.state.arrayRoutes.pt.contacts.path,
            this.state.arrayRoutes.en.contacts.path,
            this.state.arrayRoutes.de.contacts.path,
          ]} component={Contacts} exact />

          <Route path={[
            this.state.arrayRoutes.pt.new.path,
            this.state.arrayRoutes.en.new.path,
            this.state.arrayRoutes.de.new.path,
          ]} render={(props) => <New {...props} />} exact />

          <Route path={[
            this.state.arrayRoutes.pt.blog.path,
            this.state.arrayRoutes.en.blog.path,
            this.state.arrayRoutes.de.blog.path,
          ]} component={Blog} exact />

          <Route path={[
            this.state.arrayRoutes.pt.homepage.path,
            this.state.arrayRoutes.en.homepage.path,
            this.state.arrayRoutes.de.homepage.path,
          ]} component={Homepage} exact />

          <Route path={[
            this.state.arrayRoutes.pt.thank_you.path,
            this.state.arrayRoutes.en.thank_you.path,
            this.state.arrayRoutes.de.thank_you.path,
          ]} component={ThankYou} exact />

          <Route path={[
            this.state.arrayRoutes.pt.rgpc.path,
            this.state.arrayRoutes.en.rgpc.path,
            this.state.arrayRoutes.de.rgpc.path
          ]}
            component={Rgpc} exact />

          <Route render={() => <Suspense fallback={<Spinner />}><Page404 /></Suspense>} />
        </Switch>
        <Link to={this.state.arrayRoutes.en.development.path} className="d-none" />
        <Link to={this.state.arrayRoutes.de.development.path} className="d-none" />
        <Link to={this.state.arrayRoutes.pt.development.path} className="d-none" />
      </Layout>
    );
  }
}
const mapStateProps = state => {
  return {
    locale: state.lang.locale,
    cookieLocale: state.cookies.locale,
    cookiesAccepted: state.cookies.accepted,
  }
}

const mapDispatchProps = dispatch => {
  return {
    onTrySetLocale: (locale, i18n, cookies) => dispatch(actions.trySetLocale(locale, i18n, cookies)),
    onRedirectTo: (i18n, locale, cookies) => dispatch(actions.tryRedirectTo(i18n, locale, cookies)),
    onTryAutoSetLocale: (i18n, locale, cookies, isHome) => dispatch(actions.TryAutoSetLocale(i18n, locale, cookies, isHome)),
    oninitStateCookies: (cookies, cookiesInState) => dispatch(actions.initStateCookies(cookies, cookiesInState)),
    onTrySetJobs: () => dispatch(actions.trySetJobs())
  }
}

export default connect(mapStateProps, mapDispatchProps)(withCookies(langRouter(App)));
