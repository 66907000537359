import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/products-banner/Software-Faturacao-Bancos.png";
import bannerImageWebpp from "../../../../assets/images/products-banner/Software-Faturacao-Bancos.webp";

const BillingSoftwareBanner = props => (
        <Banner 
            id="billingSoftware-banner" 
            className="banner black pb-5 products-banner" 
            title="products:billingSoftware:section-1.title"
            text="products:billingSoftware:section-1.txt"
            t={props.t} 
            imageBannerRight={bannerImage} 
            imageBannerRightWebp={bannerImageWebpp}
            timeDelay={1.55} 
        />
);

export default BillingSoftwareBanner;