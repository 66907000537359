import * as actionTypes from '../actions/actionsTypes.js';

const initialState = {
    locale: null,
    accepted: null
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ){
        case actionTypes.SAVE_COOKIE: 
            return {
                ...state,
                [action.name] : action.value
            }
        case actionTypes.REMOVE_COOKIE: 
            return {
                ...state,
                [action.name] : false
            }
        default:
            return state;
    }
}

export default reducer;