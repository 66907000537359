import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Checkbox = props => (
    <Col xs={12}>
        <label htmlFor={props.id} className="checkbox">
            {props.name &&
                <input onChange={props.onChange} type="checkbox" id={props.id} name={props.name} className="d-none" />
            }
            <span className={"checkmark" + (props.checked ? ' checked' : '') }><FontAwesomeIcon icon={['fas', 'check']} /></span>
            <Link to={props.link} target="new">
                    {props.text}
            </Link>
        </ label>
    </Col>
);

export default Checkbox;