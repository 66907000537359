import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from 'react-i18next';

const Rgpc5 = props => (
    <section id="rgpc-section5" className="my pt-5 black-bg">
        <div className='blue-shape'></div>
        <Container>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <div className="title-triangle top-md fw-600"></div>
                <h2 className="h4 black fw-600 mb-5">{props.t('services:rgpc:section-5.title')}</h2>
                <p className='mb-5 text-center'>
                    <Trans
                        t={props.t}
                        i18nKey={props.t('services:rgpc:section-5.text')}
                        components={{
                            LinkIGAI: <a href="https://www.igai.pt/pt/Pages/default.aspx" rel="noreferrer" target='_blank'> </a>,
                            LinkIGAIEN: <a href="https://www.igai.pt/en/Pages/default.aspx" rel="noreferrer" target='_blank'> </a>,
                            LinkIGF: <a href="https://www.igf.gov.pt/" rel="noreferrer" target='_blank'> </a>
                        }}
                    />
                </p>
            </div>
            <div className="AccordionUI bg p-5">
                <div className='mb-5'>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='arrow-container'>
                            <div className='arrow orange'>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </div>
                        </div>
                        <h3 className='h5 fw-600 m-0 orange'>{props.t('services:rgpc:section-5.1.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-5.1.text')}</p>
                </div>
                <div className='mb-5'>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='arrow-container'>
                            <div className='arrow orange'>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </div>
                        </div>
                        <h3 className='h5 fw-600 m-0 orange'>{props.t('services:rgpc:section-5.2.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-5.2.text')}</p>
                </div>
                <div className='mb-5'>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='arrow-container'>
                            <div className='arrow orange'>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </div>
                        </div>
                        <h3 className='h5 fw-600 m-0 orange'>{props.t('services:rgpc:section-5.3.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-5.3.text')}</p>
                </div>
                <div>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='arrow-container'>
                            <div className='arrow orange'>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </div>
                        </div>
                        <h3 className='h5 fw-600 m-0 orange'>{props.t('services:rgpc:section-5.4.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-5.4.text')}</p>
                </div>
            </div>
        </Container>
    </section >
);


export default Rgpc5;