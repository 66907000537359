import React, { useEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import videoSoftware from '../../../assets/video/desenvolvimento_de-software.mp4';
import videoJobs from '../../../assets/video/oportunidades_de_emprego.mp4';
import videoOutsourcing from '../../../assets/video/outsourcing.mp4';
import videoNearshoring from '../../../assets/video/nearshoring.mp4';
import { Suspense } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
const HighlightsItem = React.lazy(() => import('./HighlightsItem'));

const Highlights = props => {
    const [inView, setInView] = useState(false);
    const ref = useRef(null);
    const checkInView = e =>{
        if ( !ref.current || (!e.target.scrollingElement && !e.target.documentElement ) ){
            return;
        }
        let scrollingElement = e.target.scrollingElement ? e.target.scrollingElement : e.target.documentElement ;

        let top = ref.current.offsetTop;
        const bottom = ref.current.clientHeight + top;
        top -= scrollingElement.clientHeight;
        const inV = top + 15 < scrollingElement.scrollTop && bottom > scrollingElement.scrollTop;
        if ( inView !== inV ){
            setInView(inV);
        }
    }
    useEffect(() => {
        document.addEventListener('scroll', checkInView, true);

        return () => {
            document.removeEventListener('scroll', checkInView, true);

        }
    });

    return(
        <section 
            id="homepage-section2"
            ref={ref}
            className="highlights my text-md-center"> 
            <Container>
                <Row>
                    <Suspense fallback={<Spinner />}>
                        <HighlightsItem id='outsourcing' video={videoOutsourcing} inView={inView} />
                        <HighlightsItem id='development' video={videoSoftware} inView={inView} />
                        <HighlightsItem id='nearshoring' video={videoNearshoring} inView={inView} />
                        <HighlightsItem id='jobs' video={videoJobs} inView={inView} />
                    </Suspense>
                </Row>        
            </Container>
        </section>
        );
}

export default Highlights;