export const SET_LOCALE_TRY = 'SET_LOCALE_TRY';
export const SET_LOCALE_SUCESS = 'SET_LOCALE_SUCESS';
export const SET_LOCALE_FAIL = 'SET_LOCALE_FAIL';
export const REDIRECT_TRY = 'REDIRECT_TRY';
export const REDIRECT_SUCESS = 'REDIRECT_SUCESS';
export const REDIRECT_FAIL = 'REDIRECT_FAIL';
export const LOCALE_CHECK_STATE = 'LOCALE_CHECK_STATE';

//COOKIES
export const SET_COOKIE = 'SET_COOKIE';
export const REMOVE_COOKIE = 'REMOVE_COOKIE';
export const DELETE_COOKIE = 'DELETE_COOKIE';
export const INIT_STATE_COOKIES = 'INIT_STATE_COOKIES';
export const SAVE_COOKIE = 'SAVE_COOKIE';
export const REMOVE_ALL_COOKIES = 'REMOVE_ALL_COOKIES';

//Jobs
export const SET_JOBS = 'SET_JOBS';
export const SET_JOBS_SUCESS = 'SET_JOBS_SUCESS';
export const SET_JOBS_FAIL = 'SET_JOBS_FAIL';

export const GET_LINK = 'GET_LINK';
export const GET_LINK_SUCESS = 'GET_LINK_SUCESS';
export const GET_LINK_FAIL = 'GET_LINK_FAIL';

export const POST_EMAIL = 'POST_EMAIL';
export const POST_EMAIL_SUCESS = 'POST_EMAIL_SUCESS';
export const POST_EMAIL_FAIL = 'POST_EMAIL_FAIL';

export const EMAIL_INIT = 'EMAIL_INIT';

export const SET_PRODUCT = 'SET_PRODUCT';
export const PRODUCT_INIT = 'PRODUCT_INIT';

//structured Data
export const SET_JOB_STRUCTURED_DATA = 'SET_JOB_STRUCTURED_DATA';