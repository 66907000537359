export const structuredDataJob = (job) => {
    const linkServer = 'http://sysmatch.com';
    let logoLink = "";
    let logoPath = "/images/logo/logo_google_jobs_sysmatch.png";
    logoLink = navigator.userAgent !== 'ReactSnap' ? window.location.origin + logoPath : linkServer + logoPath;
    let today = new Date();
    let data = {
        "@context": "http://schema.org/",
        "@type": "JobPosting",
        "datePosted": today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
        "hiringOrganization": {
            "@type": "Organization",
            "name": "Sysmatch",
            "sameAs": "https://www.sysmatch.com/",
            "logo": logoLink
        },
        "directApply": "True",
        "title": job.jobTitle,
        "description": job.functionDescription.replace(/<[^>]+>/g, "").replace(/(\r\n\r\n\r\n)/gm, ""),
        // "description" : job.functionDescription.replace(/<[^>]+>/g, "").replace(/(\r\n\r\n\r\n)/gm, "") +  job.job_requirements.replace(/<[^>]+>/g, "").replace(/(\r\n\r\n\r\n)/gm, ""),
        "jobLocation": {
            "@type": "Place",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": job.location,
                // "addressCountry": job.country_name
            }
        }
    };

    return JSON.stringify(data);
};