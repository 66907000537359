import React from 'react';
import Banner from '../../../UI/Banner/Banner';

const outsourcingBanner = (props) => (
    <Banner
        id="outsourcing-banner"
        className="banner black pb-5 services-banner"
        title="services:outsourcing:section-1.title"
        text="services:outsourcing:section-1.txt"
        t={props.t}
        timeDelay={1.55}
    >
        <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/I2MZSwvtjRc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    </Banner>
);

export default outsourcingBanner;