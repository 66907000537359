import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container';
import IconFooter from './IconFooter';
import logo from '../../assets/images/logos/logo_verde.png';
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { getRouteKey } from '../../shared/utility.js';
import { Image } from 'react-bootstrap';
import portugal from '../../assets/images/certifications/logo-portugal-2020.svg';
import ue from '../../assets/images/certifications/logo-uniao-europeia.svg';
import compete from '../../assets/images/certifications/logo-compete-2020.png';
import { useState } from 'react';

import Instagram from "../../assets/images/icon-Instagram.svg";
import InstagramOrange from "../../assets/images/icon-Instagram-orange.svg";
import Facebook from "../../assets/images/icon-Facebook.svg";
import FacebookOrange from "../../assets/images/icon-Facebook-orange.svg";
import Linkedin from "../../assets/images/icon-Linkedin.svg";
import LinkedinOrange from "../../assets/images/icon-Linkedin-orange.svg";
import Youtube from "../../assets/images/icon-youtube.svg";
import YoutubeOrange from "../../assets/images/icon-youtube-orange.svg";


import ppr_EN from "../../assets/files/Corruption-Risk-Prevention-Plan.pdf"
import ppr_PT from "../../assets/files/Plano-de-Prevencao-de-Riscos-de-Corrupcao.pdf"
import ppr_anual_PT from "../../assets/files/Relatorio-Execucao-Anual-PPR-2023.pdf"

const year = new Date().getFullYear();

const Footer = props => {
    const linkHereNow = props.i18n.language !== 'pt' ? '//www.hereandnow.agency/en' : '//www.hereandnow.agency/';

    const [instagramImage, setInstagramImage] = useState(Instagram);
    const [facebookImage, setFacebookImage] = useState(Facebook);
    const [linkedinImage, setLinkedinImage] = useState(Linkedin);
    const [youtubeImage, setYoutubeImage] = useState(Youtube);

    return (
        <footer>
            <span className="footer-shadow">
                <span className="footer-shape white-bg">
                    <Container>
                        <Row>
                            <Col xs={12} lg={3} className="mb-5">
                                <Col xs={5} md={4} lg={8} className="p-0">
                                    <Link to={props.t('routes:homepage.path') + '#top'}>
                                        <img src={logo} alt="logo Sysmatch" className="mb-4" />
                                    </Link>
                                </Col>
                                <ul>
                                    <li><Link to='#' className="click green" onClick={() => props.modal('ims')} >{props.t('partials:link-labels.sgi-policy')}</Link></li>
                                    <li><NavLink to={props.t('privacy.path') + '#top'} className="green">{props.t('partials:link-labels.privacy')}</NavLink></li>
                                    <li><NavLink to={props.t('cookies.path') + '#top'} className="green">{props.t('partials:link-labels.cookies')}</NavLink></li>
                                    {props.i18n.language === "pt" ? <li>
                                        <a className='click green' target='_blank' rel="noreferrer" href={ppr_PT}>{props.t('partials:link-labels.ppr')}</a>
                                    </li> : <li>
                                        <a className='click green' target='_blank' rel="noreferrer" href={ppr_EN}>{props.t('partials:link-labels.ppr')}</a>
                                    </li>}
                                    <li>
                                        <a className='click green' target='_blank' rel="noreferrer" href={ppr_anual_PT}>{props.t('partials:link-labels.ppr_anual')}</a>
                                    </li>
                                </ul>
                                <div className='socials d-flex'>
                                    <a className='button' href="https://www.linkedin.com/company/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setLinkedinImage(LinkedinOrange) }} onMouseOut={() => { setLinkedinImage(Linkedin) }}>
                                        <Image fluid src={linkedinImage} />
                                    </a>
                                    <a className='button' href="https://www.instagram.com/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setInstagramImage(InstagramOrange) }} onMouseOut={() => { setInstagramImage(Instagram) }}>
                                        <Image fluid src={instagramImage} />
                                    </a>
                                    <a className='button' href="https://www.facebook.com/SysMatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setFacebookImage(FacebookOrange) }} onMouseOut={() => { setFacebookImage(Facebook) }}>
                                        <Image fluid src={facebookImage} />
                                    </a>
                                    <a className='button' href="https://www.youtube.com/channel/UCusRuLSE5ZvwiXjDvK7YuWg/" target="_blank" rel="noreferrer" onMouseOver={() => { setYoutubeImage(YoutubeOrange) }} onMouseOut={() => { setYoutubeImage(Youtube) }}>
                                        <Image fluid src={youtubeImage} />
                                    </a>
                                    <IconFooter
                                        to="tel:+351213304403"
                                        icon={faMobileAlt}
                                    />
                                </div>
                                {/* only display ficha de projeto on nearshoring page */}
                                {getRouteKey(props.i18n)[0] === 'nearshoring' &&
                                    <Link to='#' className="click d-none d-lg-block" onClick={() => props.modal('project')} >
                                        <Col xs={10} md={6} lg={12} className="pt-5 mt-3 d-flex px-0">
                                            <Col className="px-2">
                                                <img src={compete} alt="Compete 2020" />
                                            </Col>
                                            <Col className="px-2">
                                                <img src={portugal} alt="Portugal" />
                                            </Col>
                                            <Col className="px-2">
                                                <img src={ue} alt="UE" />
                                            </Col>
                                        </Col>
                                    </Link>
                                }
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ offset: 1, span: 4 }}>
                                <h4 className="fw-700">{props.t('partials:link-menu.produts')}</h4>
                                <ul className="pr-lg-2">
                                    <li><NavLink to={props.t('attendenceManagement.path') + '#top'} >{props.t('partials:link-labels.attendence-management')}</NavLink></li>
                                    <li><NavLink to={props.t('projectManagement.path') + '#top'} >{props.t('partials:link-labels.project-management')}</NavLink></li>
                                    <li><NavLink to={props.t('meetingRoom.path') + '#top'} >{props.t('partials:link-labels.meeting-room')}</NavLink></li>
                                    <li><NavLink to={props.t('shareholderMeeting.path') + '#top'} >{props.t('partials:link-labels.shareholder-meeting')}</NavLink></li>
                                    <li><NavLink to={props.t('creditManagement.path') + '#top'} >{props.t('partials:link-labels.credit-management')}</NavLink></li>
                                    <li><NavLink to={props.t('comercialPaper.path') + '#top'} >{props.t('partials:link-labels.comercial-paper')}</NavLink></li>
                                    <li><NavLink to={props.t('billingSoftware.path') + '#top'} >{props.t('partials:link-labels.billing-software')}</NavLink></li>
                                </ul>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 6 }} lg={4}>
                                <h4 className="fw-700">{props.t('partials:link-menu.services')}</h4>
                                <ul>
                                    <li><NavLink to={props.t('consulting.path') + '#top'} >{props.t('partials:link-labels.consulting')}</NavLink></li>
                                    <li><NavLink to={props.t('development.path') + '#top'} >{props.t('partials:link-labels.development')}</NavLink></li>
                                    <li><NavLink to={props.t('outsourcing.path') + '#top'} >{props.t('partials:link-labels.outsourcing')}</NavLink></li>
                                    <li><NavLink to={props.t('quality.path') + '#top'} >{props.t('partials:link-labels.quality')}</NavLink></li>
                                    <li><NavLink to={props.t('rgpc.path') + '#top'} >{props.t('partials:link-labels.rgpc')}</NavLink></li>
                                    <li><NavLink to={props.t('nearshoring.path') + '#top'} >{props.t('partials:link-labels.nearshoring')}</NavLink></li>
                                    <br />
                                    <li><NavLink to={props.t('jobs.path') + '#top'} ><strong>{props.t('partials:link-labels.jobs')}</strong></NavLink></li>

                                </ul>
                            </Col>
                        </Row>
                        {/* only display ficha de projeto on nearshoring page */}
                        {getRouteKey(props.i18n)[0] === 'nearshoring' &&
                            <Link to='#' className="click d-lg-none" onClick={() => props.modal('project')} >
                                <Col xs={10} md={6} lg={12} className="pt-5 d-flex px-0">
                                    <Col className="px-2">
                                        <img src={compete} alt="Compete 2020" />
                                    </Col>
                                    <Col className="px-2">
                                        <img src={portugal} alt="Portugal" />
                                    </Col>
                                    <Col className="px-2">
                                        <img src={ue} alt="UE" />
                                    </Col>
                                </Col>
                            </Link>
                        }
                        <Row className="copy justify-content-center">
                            <p> Copyright &copy; {year} <span>&#x25cf;</span> {props.t('partials:copyright')} <Link target="_new" to={linkHereNow}>Here &amp; Now Agency</Link></p>
                        </Row>
                    </Container>
                </span>
            </span>
        </footer>
    )
}

export default withTranslation(['routes', 'partials'])(Footer);

