import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { isIE } from '../../../shared/utility';

const FormField = props => {
    const { id, _ref, title , type , required , name , minLength , maxLength , onInput, onChange , onKeyUp , pattern , placeholder , errorMsg , requiredMsg , accept } = props;
    const [error,setError] = useState(null);
    let on_input = onInput;
    let loading = true;
    const checkField = (e) => {
        if ( isIE() && loading && type === 'textarea' )    
        {
            loading = false;
            return;
        }
        const _isIE = isIE() ? ' ie' : '';

        const check = _isIE ? 
            (minLength && e.target.value !== ''  ?  
                e.target.value.length >= minLength : true) &&  e.target.checkValidity() : 
            e.target.checkValidity();
        
        if ( check ){
            e.target.classList.remove('error');
            setError(false);
        }else{
            e.target.classList.add('error');
            const required = e.target.value === '' ? 1 : 2; 
            setError(required);
        }
    }
    if ( !onInput )
        on_input = checkField;

    const input = type ? (type !== 'textarea' ? <input id={id} ref={_ref} type={type}  name={name} className={name + ( type==='file' ? ' d-none' : '')} onChange={onChange} onKeyUp={onKeyUp} placeholder={placeholder ? placeholder : title} minLength={minLength} maxLength={maxLength} onInput={on_input} pattern={pattern} accept={accept} required={required} /> 
    : <textarea id={id} ref={_ref} type={type} onKeyUp={onKeyUp} name={name} onInput={on_input} className={name} rows="7" minLength={minLength} maxLength={maxLength} required={required} placeholder={placeholder ? placeholder : title}></textarea>) : null;


    return(
        <Col xs={12} className="formField p-0">
            <label className="h5 ml-4 fw-600 w-auto" htmlFor={id}>{title}{required && '*'}</label>
            {input}
            {error && (errorMsg || requiredMsg) && type !== 'file' && <p className="errorMsg red">{error === 1 ? requiredMsg : errorMsg.replace(' : ', ` ${minLength} `)}</p>}
        </Col>
    )
};

export default FormField;