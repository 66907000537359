import React, { Component } from 'react';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Banner from '../../components/Contacts/ContactsBanner/ContactsBanner';
import { withTranslation } from 'react-i18next';
import Contacts2 from '../../components/Contacts/Contacts2/Contacts2';
import Head from '../../components/Head/Head';
class Contacts extends Component {
    render() {
        return (
            <Auxiliary>
                <Head id="contacts" />
                <Banner {...this.props} />
                <Contacts2 {...this.props} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Contacts);