import React from 'react';
import healthImg from '../../../../assets/images/consulting/1_saude.png';
import healthImgWebp from '../../../../assets/images/consulting/1_saude.webp';
import comunicationsImg  from "../../../../assets/images/consulting/2_telecomunicacoes.png";
import comunicationsImgWebp  from "../../../../assets/images/consulting/2_telecomunicacoes.webp";
import retailImg  from "../../../../assets/images/consulting/3_retalho.png";
import retailImgWebp  from "../../../../assets/images/consulting/3_retalho.webp";
import manufactoringImg  from "../../../../assets/images/consulting/4_manufatura.png";
import manufactoringImgWebp  from "../../../../assets/images/consulting/4_manufatura.webp";
import logisticImg  from "../../../../assets/images/consulting/5_logistica.png";
import logisticImgWebp  from "../../../../assets/images/consulting/5_logistica.webp";
import ButtonOvalImage from '../../../UI/ButtonOvallmage/ButtonOvalImage';
import Aux from "../../../../hoc/Auxiliary/Auxiliary";

const images = [retailImg, logisticImg, healthImg, comunicationsImg, manufactoringImg];
const imagesWeb = [retailImgWebp, logisticImgWebp, healthImgWebp, comunicationsImgWebp, manufactoringImgWebp];

const Consulting3 = props => {
    const alts = [ 
        props.t('services:consulting.section-3.1_alt'),
        props.t('services:consulting.section-3.2_alt'),
        props.t('services:consulting.section-3.3_alt'),
        props.t('services:consulting.section-3.4_alt'),
        props.t('services:consulting.section-3.5_alt')
     ]
    return(
        <section id="consulting-section3">
            <ButtonOvalImage
                title={props.t('services:consulting.section-3.title')}
                images={images}
                imagesWeb={imagesWeb}
                alts={alts}
                >
                <Aux>
                    {props.t('services:consulting.section-3.1')}
                </Aux>
                <Aux>
                    {props.t('services:consulting.section-3.2')}
                </Aux>
                <Aux>
                    {props.t('services:consulting.section-3.3')}
                </Aux>
                <Aux>
                    {props.t('services:consulting.section-3.4')}
                </Aux>
                <Aux>
                    {props.t('services:consulting.section-3.5')}
                </Aux>
            </ButtonOvalImage>
        </section>
    );
}
export default Consulting3;