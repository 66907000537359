import * as actionsTypes from "./actionsTypes";

export const trySetJobs = () => {
    return{
        type: actionsTypes.SET_JOBS
    }
}

export const sucessSetJobs = jobs => {
    return{
        type: actionsTypes.SET_JOBS_SUCESS,
        jobs
    }
}

export const failSetJobs = () => {
    return{
        type: actionsTypes.SET_JOBS_FAIL,
        jobs: null,
        error: true
    }
}

export const setJobStructuredData = (data) => {
    return{
        type: actionsTypes.SET_JOB_STRUCTURED_DATA,
        structured_data: data
    }
}