import React, { Component } from 'react';
import Banner from '../../../components/Products/CreditManagement/CreditManagementBanner/CreditManagementbanner';
import CreditManagement2 from '../../../components/Products/CreditManagement/CreditManangement2/CreditManagement2';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { withTranslation } from 'react-i18next';
import Head from '../../../components/Head/Head';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';
class CreditManagement extends Component {
    render(){
        return (
            <Auxiliary >
                <Head id="credit-management" />
                <Banner {...this.props} />
                <CreditManagement2 {...this.props} />
                <CallToAction
                    color="light-green"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnTextProducts')}
                    product='creditManagement'
                    />
            </Auxiliary>
        )
    }
}

export default withTranslation()(CreditManagement);