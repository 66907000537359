import { takeEvery, all, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionsTypes';

import { 
    removeCookieSaga, 
    trySetCookieSaga,
    removeAllCookiesSaga,
    initStateCookiesSaga
} from './cookies';

import {
    localeCheckStateSaga,
    tryRedirectToSaga,
    setLocaleSaga,
} from './lang';

import {
    trySetJobsSaga
} from './jobs';

import {
    tryGetLinkbucketSaga,
    tryPostEmailSaga
} from './form';

export function* watchLang(){
    yield all([
        takeEvery( actionTypes.SET_LOCALE_TRY , setLocaleSaga ),
        takeEvery( actionTypes.REDIRECT_TRY , tryRedirectToSaga ),
        takeEvery( actionTypes.LOCALE_CHECK_STATE , localeCheckStateSaga ),
    ]);
}

export function* watchCookies(){
    yield all([
        takeEvery(actionTypes.SET_COOKIE, trySetCookieSaga),
        takeEvery(actionTypes.REMOVE_COOKIE, removeCookieSaga),
        takeEvery(actionTypes.REMOVE_ALL_COOKIES, removeAllCookiesSaga),
        takeEvery(actionTypes.INIT_STATE_COOKIES, initStateCookiesSaga),
    ]);
}

export function* watchJobs(){
    yield takeLatest(actionTypes.SET_JOBS, trySetJobsSaga);
}

export function* watchForm(){
    yield all([
        takeEvery(actionTypes.GET_LINK, tryGetLinkbucketSaga),
        takeEvery(actionTypes.POST_EMAIL, tryPostEmailSaga)
    ]);
}