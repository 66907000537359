import React from 'react';
import { Link } from 'react-router-dom';
import { maxChar } from '../../../shared/utility';
import { Trans } from 'react-i18next';

// layout for listing the news in the blog page, section 2 and banner
const BlogItemUI = props => (
    <Link to={props.link + "#top"} target={props.target}>
        <div
            className={'blogItemUI ' + (props.className ? props.className : '')}>
            <div className="wrap-img">
                <div className="img">
                    <picture>
                        {props.imgWebp && <source srcSet={props.imgWebp} type="image/webp" />}
                        <source src={props.img} type="image/png" />
                        <img src={props.img} alt={props.alt} />
                    </picture>
                </div>
            </div>
            {props.date && <p className="mt-4 mb-0 full-date">{props.date}</p>}
            <h2 className="h4 fw-700 mt-1"><Trans key={props.key} t={props.t} i18nKey={props.title} /></h2>
            <p className=" fw-500">{maxChar(props.text, 160)}</p>
        </div>
    </Link>
);

export default BlogItemUI;