import React from 'react';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import IconTitle from '../../../UI/IconTitle/IconTitle';
import icon1 from '../../../../assets/images/attendenceManagement/section-3/leitores-biometricos.svg';
import icon2 from '../../../../assets/images/attendenceManagement/section-3/configuracao-horarios.svg';
import icon3 from '../../../../assets/images/attendenceManagement/section-3/visualizacao-relatorios.svg';
import icon4 from '../../../../assets/images/attendenceManagement/section-3/gestao-ferias.svg';
import icon5 from '../../../../assets/images/attendenceManagement/section-3/gestao-ausencias.svg';
import icon6 from '../../../../assets/images/attendenceManagement/section-3/justificacao-faltas.svg';
import icon7 from '../../../../assets/images/attendenceManagement/section-3/mapas-ferias.svg';
import icon8 from '../../../../assets/images/attendenceManagement/section-3/alertas-personalizados.svg';
import icon9 from '../../../../assets/images/attendenceManagement/section-3/parametrizacao.svg';

const AttendenceManagement3 = props => (
    <IconTitle id="attendenceManagement-section3" title={props.t('products:attendenceManagement.section-3.title')}>
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic1')} img={icon1} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic2')} img={icon2} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic3')} img={icon3} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic4')} img={icon4} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic5')} img={icon5} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic6')} img={icon6} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic7')} img={icon7} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic8')} img={icon8} />
        <Auxiliary title={props.t('products:attendenceManagement.section-3.topic9')} img={icon9} />
    </IconTitle>
);

export default AttendenceManagement3;