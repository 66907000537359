import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactComponent as BgImage } from "../../../../assets/images/oval-seccao-17.svg";
import TitleDegrade from "../../../UI/TitleDegrade/TitleDegrade"

const AttendenceManagement4 = props => (
    <section id="attendenceManagement-section4">
        <BgImage className="backgroundImg" />
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600">{props.t('products:attendenceManagement.section-4.title')}</h2>
            <Row className="pt-5">
                <Col lg={{offset:2, span:8}}>
                    <TitleDegrade className="orange text-uppercase" title={props.t('products:attendenceManagement:section-4.topic1')} />
                </Col>
                <Col lg={{offset:2, span:8}}>
                    <TitleDegrade className="orange text-uppercase" title={props.t('products:attendenceManagement:section-4.topic2')} />
                </Col>
                <Col lg={{offset:2, span:8}}>
                    <TitleDegrade className="orange text-uppercase" title={props.t('products:attendenceManagement:section-4.topic3')} />
                </Col>
                <Col lg={{offset:2, span:8}}>
                    <TitleDegrade className="orange text-uppercase" title={props.t('products:attendenceManagement:section-4.topic4')} />
                </Col>
                <Col lg={{offset:2, span:8}}>
                    <TitleDegrade className="orange text-uppercase" title={props.t('products:attendenceManagement:section-4.topic5')} />
                </Col>
            </Row>
        </Container>
    </section>
);

export default AttendenceManagement4;