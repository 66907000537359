import React from 'react';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import ButtonOvalImage from "../../UI/ButtonOvallmage/ButtonOvalImage";
import { ReactComponent as BgImg } from '../../../assets/images/jobs/section-5/oval-vagas.svg';
import img1 from '../../../assets/images/jobs/section-5/1-logos-backend.png';
import imgWeb1 from '../../../assets/images/jobs/section-5/1-logos-backend.webp';
import img2 from '../../../assets/images/jobs/section-5/2-logos_low-code.png';
import imgWeb2 from '../../../assets/images/jobs/section-5/2-logos_low-code.webp';
import img3 from '../../../assets/images/jobs/section-5/3-sistemas-gestao.png';
import imgWeb3 from '../../../assets/images/jobs/section-5/3-sistemas-gestao.webp';
import img4 from '../../../assets/images/jobs/section-5/4-cms.png';
import imgWeb4 from '../../../assets/images/jobs/section-5/4-cms.webp';
import img5 from '../../../assets/images/jobs/section-5/5-mobile.png';
import imgWeb5 from '../../../assets/images/jobs/section-5/5-mobile.webp';
import img6 from '../../../assets/images/jobs/section-5/6-web-front-end.png';
import imgWeb6 from '../../../assets/images/jobs/section-5/6-web-front-end.webp';
import img7 from '../../../assets/images/jobs/section-5/7-analytics.png';
import imgWeb7 from '../../../assets/images/jobs/section-5/7-analytics.webp';
import img8 from '../../../assets/images/jobs/section-5/8-infraestrutura.png';
import imgWeb8 from '../../../assets/images/jobs/section-5/8-infraestrutura.webp';
import img9 from '../../../assets/images/jobs/section-5/9-qa-automatico.png';
import imgWeb9 from '../../../assets/images/jobs/section-5/9-qa-automatico.webp';

const images = [img1, img2,img3,img4,img5,img6,img7,img8,img9];
const imagesWeb = [imgWeb1,imgWeb2,imgWeb3,imgWeb4,imgWeb5,imgWeb6,imgWeb7,imgWeb8,imgWeb9];

const Jobs5 = props =>(
    <section id="jobs-section5" className="my black-bg mt-5 py-5">
      <ButtonOvalImage
            title={props.t('jobs:section-5.title')}
            bg={<BgImg className="backgroundImg" />}
            images={images}
            imagesWeb={imagesWeb}
            >
            <Aux>
                {props.t('jobs:section-5.topic1')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic2')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic3')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic4')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic5')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic6')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic7')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic8')}
            </Aux>
            <Aux>
                {props.t('jobs:section-5.topic9')}
            </Aux>
        </ButtonOvalImage>
    </section>
);

export default Jobs5;