import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import icon1 from "../../../../assets/images/rgpc/section-2/1.svg";
import icon2 from "../../../../assets/images/rgpc/section-2/2.svg";
import icon3 from "../../../../assets/images/rgpc/section-2/3.svg";
import icon4 from "../../../../assets/images/rgpc/section-2/4.svg";
import icon5 from "../../../../assets/images/rgpc/section-2/5.svg";
const Rgpc2 = props => (
    <section id="rgpc-section2" className="my pt-5">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('services:rgpc:section-2.title')}</h2>
            <div className='d-flex mb-4 align-items-center'>
                {/* Icon */}
                <div className='icon-container'>
                    <div className="diamond-top-icon">
                        <div className="icon">
                            <img alt={props.alt} src={icon1} />
                        </div>
                    </div>
                </div>
                {/* Text */}
                <div className=''>
                    <div className='d-flex align-items-center mb-4'>
                        {/* <div className='arrow-container'>
                                <div className='arrow'>
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                </div>
                            </div> */}
                        <h3 className='h5 fw-700 m-0'>{props.t('services:rgpc:section-2.1.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-2.1.text')}</p>
                </div>
            </div>
            <div className='d-flex mb-4 align-items-center'>
                {/* Icon */}
                <div className='icon-container'>
                    <div className="diamond-top-icon">
                        <div className="icon">
                            <img alt={props.alt} src={icon2} />
                        </div>
                    </div>
                </div>
                {/* Text */}
                <div className=''>
                    <div className='d-flex align-items-center mb-4'>
                        <h3 className='h5 fw-700 m-0'>{props.t('services:rgpc:section-2.2.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-2.2.text')}</p>
                </div>
            </div>
            <div className='d-flex mb-4 align-items-center'>
                {/* Icon */}
                <div className='icon-container'>
                    <div className="diamond-top-icon">
                        <div className="icon">
                            <img alt={props.alt} src={icon3} />
                        </div>
                    </div>
                </div>
                {/* Text */}
                <div className=''>
                    <div className='d-flex align-items-center mb-4'>
                        <h3 className='h5 fw-700 m-0'>{props.t('services:rgpc:section-2.3.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-2.3.text')}</p>
                </div>
            </div>
            <div className='d-flex mb-4 align-items-center'>
                {/* Icon */}
                <div className='icon-container'>
                    <div className="diamond-top-icon">
                        <div className="icon">
                            <img alt={props.alt} src={icon4} />
                        </div>
                    </div>
                </div>
                {/* Text */}
                <div className=''>
                    <div className='d-flex align-items-center mb-4'>
                        <h3 className='h5 fw-700 m-0'>{props.t('services:rgpc:section-2.4.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-2.4.text')}</p>
                </div>
            </div>
            <div className='d-flex mb-4 align-items-center'>
                {/* Icon */}
                <div className='icon-container'>
                    <div className="diamond-top-icon">
                        <div className="icon">
                            <img alt={props.alt} src={icon5} />
                        </div>
                    </div>
                </div>
                {/* Text */}
                <div className=''>
                    <div className='d-flex align-items-center mb-4'>
                        <h3 className='h5 fw-700 m-0'>{props.t('services:rgpc:section-2.5.title')}</h3>
                    </div>
                    <p>{props.t('services:rgpc:section-2.5.text')}</p>
                </div>
            </div>
        </Container>
    </section>
);


export default Rgpc2;