import React from 'react';

const menuModal = props => {
    const show = typeof props.show !== 'undefined' ? props.show : '';
    const classesButton = 'buttons ' + show;
    const classesContent = 'content ' + show;
    return (
        <div id="menuModal" onMouseLeave={props.closed}>
            <div
                className={classesContent}
                onClick={props.clicked}
                style={{ left: props.left }}>
                <div className="black-bg"></div>
                <div className="green-bg"></div>
                {props.children}
            </div>
        </div>
    );
}

export default menuModal;