import React from 'react';
import AccordionUI from '../../../UI/AccordionUI/AccordionUI';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import { Container } from 'react-bootstrap';


const CreditManagement2 = props => (
  <section
    id="creditManagement-section2"
    className="my pt-5">
    <Container>
        <div className="title-triangle top-md fw-600"></div>
        <h2 className="h4 black fw-600 mb-5">{props.t('products:creditManagement:section-2.title')}</h2>
        <AccordionUI className="bg p-4 p-sm-5" {...props}>
            <Auxiliary title={props.t('products:creditManagement:section-2.subtitle1')} text={props.t('products:creditManagement:section-2.text1')}  />
            <Auxiliary title={props.t('products:creditManagement:section-2.subtitle2')} text={props.t('products:creditManagement:section-2.text2')}  />
            <Auxiliary title={props.t('products:creditManagement:section-2.subtitle3')} text={props.t('products:creditManagement:section-2.text3')}  />
            <Auxiliary title={props.t('products:creditManagement:section-2.subtitle4')} text={props.t('products:creditManagement:section-2.text4')}  />
            <Auxiliary title={props.t('products:creditManagement:section-2.subtitle5')} text={props.t('products:creditManagement:section-2.text5')}  />
        </AccordionUI>
        </Container>
    </section>
);

export default CreditManagement2;