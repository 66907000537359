import React, { Component } from 'react';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Head from '../../components/Head/Head'
import { withTranslation } from 'react-i18next';
import Page404 from '../Errors/Page404/Page404';
import Banner from '../../components/Blog/NewBanner/NewBanner';
import { formatLink, updateObject } from '../../shared/utility';
import { Redirect } from 'react-router';
class New extends Component {

    state = {
        id: null,
        language: null,
        news: null,
        redirect: null
    }

    componentDidUpdate() {
        const language = this.props.i18n.language;
        if (language !== this.state.language) {
            const news = this.props.i18n.getResource(language, 'blog');
            this.setState(
                updateObject(this.state, {
                    language: language,
                    news
                }),
                () => this.get_id_new(news)
            );
        }
    }
    check_redirect() {
        if (this.state.id && this.state.news) {
            const uri = formatLink(this.state.news.news[this.state.id].content.url);
            const redirect = <Redirect to={uri} />;
            this.setState(updateObject(this.state, { redirect }));
        }
    }

    get_id_new(news) {
        const keys = Object.keys(news.news);
        const uri = this.props.match.params.id;
        let id = -1;
        keys.forEach(key => {
            const title = formatLink(news.news[key].content.url);
            if (title === uri) {
                id = key;
            }
        })
        if (id !== -1) {
            this.setState(
                updateObject(this.state, {
                    id
                })
            );
        } else {
            this.check_redirect();
        }
    }

    render() {
        let headId = '';
        let renderNew = <Page404 />

        if (this.state.news && this.state.id && typeof this.state.news.news[this.state.id] !== 'undefined' && !this.state.news.news[this.state.id].link) {
            headId = 'news.' + this.state.id;
            renderNew = <Banner {...this.props} id={this.state.id} new={this.state.news.news[this.state.id].content} news={this.state.news.news} alt='' />;
        }
        return (
            <Auxiliary>
                {this.state.redirect}
                <Head id={headId} />
                {renderNew}
            </Auxiliary>
        );
    }
}

export default withTranslation()(New);