import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';

const IconTitleButtonItem = props => (
    <Row className="IconTitleButtonItem align-items-center py-3">
        <Col xs={11} md={12} xl={11} className="d-flex align-items-center justify-content-lg-between">
            <div className="d-flex align-items-center">
                <div className="diamond-botton-icon d-flex">
                    <div className="icon">
                        <Image src={props.image} alt={props.alt} fluid />
                    </div>
                </div>
                <h3 className="p ml-3 mb-0 fw-700 text-uppercase">{props.title}</h3>
            </div>
            <Link to={props.link + "#top"}>
                <Button btnClasses={'wh-35 h5 fw-600 text-uppercase float-right mr-0 ml-3 mt-0 black'}>
                    +
                </Button>
            </Link>
        </Col>
    </Row>
);

export default IconTitleButtonItem;