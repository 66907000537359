import React from 'react';
import Jobs from '../../UI/Jobs/Jobs';

const homeJobs = props => (
    <Jobs
        title={props.t('homepage:section-6.title')}
        button={props.t('homepage:section-6.btn')}
        link={props.t('routes:jobs.path')}
        nJobs={4}
        msgError={props.t('homepage:section-6.error')}
        t={props.t} />
);

export default homeJobs;