import {put} from 'redux-saga/effects';
import * as actions from "../actions/index";
import { getRouteKey } from '../../shared/utility';
import { instanceIP as axios } from '../../axios';

export function* setLocaleSaga(action){
    if (action.locale) {
        let routeArr = getRouteKey( action.i18n , action.path , action.locale );
        if( !routeArr )
            routeArr = getRouteKey( action.i18n , '/' );
        let key = yield routeArr;

        if ( key ){
            yield action.i18n.changeLanguage( action.locale );
            yield localStorage.setItem( 'locale' , action.locale );
            yield put( actions.sucessSetLocale( action.locale ) );
            yield put( actions.setCookie( action.cookies , 'locale' , action.locale ) ); //! adicionar verificação de cookies aceites
        }
    }else
       yield put( actions.failSetLocale() );
}

export function* tryRedirectToSaga( action ){
    let routeArr = yield getRouteKey( action.i18n , action.path , action.locale );
    if( !routeArr )
        routeArr = yield getRouteKey( action.i18n , '/' );
   
    let redirectTo = false;
    if( routeArr ){
        let [key,localeRoute] = yield routeArr;
        
        let locale = yield action.locale ? action.locale : localeRoute;

        if ( locale )
            yield put( actions.trySetLocale( locale , action.i18n , action.cookies ) );
        
        redirectTo = yield action.i18n.getResource( locale , 'routes' , key  ).path;
        if ( redirectTo && redirectTo.split('/').length > 2 ){ 
            const id = window.location.pathname.split('/')[2];
            redirectTo = redirectTo.replace(':id',id);
        }
        if (redirectTo && action.redirect)
            yield put( actions.sucessRedirectTo( redirectTo ));
    }
   
    if ( !routeArr || !redirectTo )
        yield put( actions.failRedirectTo());   
}

export function* localeCheckStateSaga(action) {
    let locale = action.locale;
    
    if( !locale ){
        try{
            const response = yield axios.get('cdn-cgi/trace');
            const loc = yield response.data.match(/loc=([\w]{2})/);
            if ( loc && typeof loc[1]  !== 'undefined' ){
                const localeIP = yield loc[1].toLowerCase();
                locale = action.i18n.language.includes(localeIP) ? localeIP : false;
                if( !locale )
                    locale = 'en';
            }
        }catch(error){}
    }

    if(locale)
        yield put( actions.tryRedirectTo( action.i18n , locale , action.cookies , action.isHome ) );
}