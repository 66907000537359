import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { instanceaAWS } from '../../axios';
import axios from 'axios';

export function* tryGetLinkbucketSaga(action) {
    try {

        const fileName = action.file.name;
        // try get link to upload file
        const responseAws = yield instanceaAWS.post('/upload', {
            fileName: fileName
        });
        const fields = yield responseAws.data.fields;
        const url = yield responseAws.data.url;

        // formating data in formData
        const formData = yield new FormData();

        yield Object.keys(fields).forEach(key => {
            formData.append(key, fields[key]);
        });

        yield formData.append("file", action.file);

        // try upload file
        const bucket = yield axios.post(url, formData);
        if (bucket.status === 204) {
            //try get link to download file
            //const get_link = yield instanceaAWS.get('/upload?fileName=' + fields.key );
            yield put(actions.sucessGetLinkbucket(fields.key));
        } else {
            yield put(actions.failGetLinkbucket());
        }

    } catch (error) {
        //yield console.log(error , 'put error');
        yield put(actions.failGetLinkbucket());
    }
}

export function* tryPostEmailSaga(action) {
    try {
        const data = yield {
            "channel": "Sysmatch",
            "toAddress": "joao.branco@sysmatch.com",
            [action.typeForm]: [action.form][0]
        };
        yield instanceaAWS.post('/send-email', data);
        yield put(actions.sucessPostEmail());
    } catch (error) {
        yield put(actions.failPostEmail());
    }
}