import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container';
import {Link} from 'react-router-dom';
import * as actions from '../../../store/actions/index';

const callToAction = props => {
    
    let classes =  props.subTitleSmall ?  "black-bg py-5 cta big" : "black-bg py-5 cta";
    return (
        <section id={props.id} className={classes}>
            <Container>
                <Row>
                    <Col md={8}>
                        <Col lg="12" className="title">
                            {props.title && (<h2 className="white h4 fw-600">{props.title}</h2>)}
                            {props.subTitleSmall && (<h2 className={[props.color, 'font-weight-bold', 'text-uppercase','h4','fw-600'].join(" ")}>{props.subTitleSmall}</h2>)}
                        </Col>
                        <Col lg="12" className="justify-space-between pr-0 content">
                            {props.subTitle && <h3 className={[props.color, 'fw-700', 'pt-4', 'h2'].join(" ")}>{props.subTitle}</h3>}
                            {props.text && <p className={['white', 'py-3'].join(" ")}>{props.text}</p>}
                        </Col>
                        <Col className="d-md-none d-flex justify-content-center">
                            {props.link && props.btn &&
                                <Link to={props.link + "#top"}>
                                    <Button btnClasses={' p-l ml-0 mr-0 mt-3 h5 fw-600 text-uppercase'}>{props.btn}</Button>
                                </Link>}
                        </Col>
                    </Col>
                    <Col md={4} className="position-relative d-none d-md-block">
                        <div className={[props.color + '-bg', 'polygon'].join(" ")}>
                            {props.link && props.btn &&
                            <Link to={props.link +  (props.product ? '?product='+props.product  : '' ) + "#top"}>
                                <Button clicked={props.product ? () => props.onSetProduct(props.product) : null } btnClasses={'p-l h5 fw-600 text-uppercase'}>{props.btn}</Button>
                            </Link>}
                        </div>
                    </Col>
                </Row>  
            </Container>    
        </section>
    )
}

const mapDispatchProps = dispatch => {
    return {
        onSetProduct: (product) => dispatch ( actions.setProduct( product ) )
    }
  }

export default connect(null,mapDispatchProps)(callToAction);