import React from 'react';
import Jobs from '../../UI/Jobs/Jobs';

const listJobs = props => {
    const setTitle = (jobTitle)=>{
        if ( props.setJobTitle ){
            props.setJobTitle(jobTitle);
        }
    }
    return <Jobs
        className="black-bg" 
        title={props.t('jobs:section-4.title')}
        txt={props.t('homepage:section-6.txt')}
        button={props.t('homepage:section-6.btn')}
        link={props.t('routes:jobs.path')}
        msgError={props.t('homepage:section-6.error')}
        t={props.t}
        setJobTitle={(jobTitle) => setTitle(jobTitle)}
        search />
}

export default listJobs;