import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/services-banner/RGPC.png";
//import bannerImageWebpp from "../../../../assets/images/services-banner/Consultoria-sistemas-gestao.webp";

const RgpcBanner = (props) => (
    <Banner
        id="quality-banner"
        className="banner black pb-5 services-banner"
        title="services:rgpc:section-1.title"
        text="services:rgpc:section-1.txt"
        t={props.t}
        imageBannerRight={bannerImage}
        //imageBannerRightWebp={bannerImageWebpp} 
        timeDelay={1.55}
    />
);

export default RgpcBanner;