import React from 'react';
import { Container, Row } from 'react-bootstrap';
import IconTitleItem from './IconTitleItem';

const IconTitle = props => {
    const items =  props.children.map( (child ,i) => (
        <Row key={i}>
            <IconTitleItem
                {...child.props}
                />
        </Row>
    )); 
    return (
        <section 
            id={props.id}
            className="IconTitle my">
                <Container>
                    <h2 className="h4 black fw-600">
                        <div className="title-triangle top-md"></div>
                            {props.title}
                    </h2>
                    {items}
                </Container>
            </section>
    );
}

export default IconTitle;