import React from 'react';
import { Container } from 'react-bootstrap';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import AccordionUI from '../../../UI/AccordionUI/AccordionUI';


const nearshoring2 = (props) =>(
    <section id="nearshoring-section2" className="my pt-5 services">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('services:nearshoring:section-2.title')}</h2>
            <AccordionUI className="bg p-4 p-sm-5" {...props}>
                <Auxiliary title={props.t('services:nearshoring:section-2.subtitle1')} text={props.t('services:nearshoring:section-2.text1')}  />
                <Auxiliary title={props.t('services:nearshoring:section-2.subtitle2')} text={props.t('services:nearshoring:section-2.text2')}  />
                <Auxiliary title={props.t('services:nearshoring:section-2.subtitle3')} text={props.t('services:nearshoring:section-2.text3')}  />
                <Auxiliary title={props.t('services:nearshoring:section-2.subtitle4')} text={props.t('services:nearshoring:section-2.text4')}  />
                <Auxiliary title={props.t('services:nearshoring:section-2.subtitle5')} text={props.t('services:nearshoring:section-2.text5')}  />
            </AccordionUI>
        </Container>
    </section>
);


export default nearshoring2;