import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { ReactComponent as BgImage } from "../../../../assets/images/oval-seccao-17.svg";
import TitleDegrade from "../../../UI/TitleDegrade/TitleDegrade"

const outsourcing3 = props =>(
    <section 
        id="outsourcing-section3"
        className="outsourcing-section3 bg-oval-list my-4 py-4 my-md-5 py-md-5">
        <BgImage className="backgroundImg"/>
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 position-relative black fw-600">{props.t('services:outsourcing.section-3.title')}</h2>
            <Row className="pt-5">
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic1')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic2')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic3')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic4')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic5')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic6')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic7')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic8')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic9')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic10')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:outsourcing:section-3.topic11')} />
                </Col>
            </Row>
        </Container>
    </section>
);

export default outsourcing3;