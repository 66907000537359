// eslint-disable-next-line no-unused-vars
import react from 'react';

const TitleDegradeButton = props => {

    let classes= "h5 fw-600 ";
    if(props.active){
        classes += 'active'; 
    }
    return (
        <div id={props.id} className="grey-degrade my-4 my-md-5 click" onMouseEnter={props.clicked}>
            <h3 className={classes}>{props.title}</h3>
        </div>
    )
};

export default TitleDegradeButton;