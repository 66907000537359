import * as actionsTypes from "./actionsTypes";

export const tryGetLinkbucket = (file) => {
    return{
        type: actionsTypes.GET_LINK,
        file
    }
}


export const sucessGetLinkbucket = link => {
    return{
        type: actionsTypes.GET_LINK_SUCESS,
        link_bucket: link
    }
}

export const failGetLinkbucket = () => {
    return{
        type: actionsTypes.GET_LINK_FAIL,
    }
}
export const tryPostEmail = (typeForm,form) => {
    return{
        type: actionsTypes.POST_EMAIL,
        typeForm,
        form
    }
}


export const sucessPostEmail = () => {
    return{
        type: actionsTypes.POST_EMAIL_SUCESS,
    }
}

export const failPostEmail = () => {
    return{
        type: actionsTypes.POST_EMAIL_FAIL,
    }
}

export const emailInit = () => {
    return{
        type: actionsTypes.EMAIL_INIT,
    }
}

export const setProduct = (product) => {
    return{
        type: actionsTypes.SET_PRODUCT,
        product
    }
}
export const initProduct = () => {
    return{
        type: actionsTypes.PRODUCT_INIT,
    }
}