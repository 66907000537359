import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/products-banner/Software-Gestao-Projetos.png";
import bannerImageWebpp from "../../../../assets/images/products-banner/Software-Gestao-Projetos.webp";

const ProjectManagementBanner = props => (
        <Banner 
            id="projectManagement-banner" 
            className="banner black pb-5 products-banner" 
            title="products:projectManagement:section-1.title"
            text="products:projectManagement:section-1.txt"
            t={props.t} 
            imageBannerRight={bannerImage} 
            imageBannerRightWebp={bannerImageWebpp} 
            timeDelay={1.55} 
        />
);

export default ProjectManagementBanner;