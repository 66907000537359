import React from 'react';
import { Container } from 'react-bootstrap';
import AccordionUI from '../../../UI/AccordionUI/AccordionUI';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

const development3 = props => (
    <section id="development-section3" className="my pt-5">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('services:development:section-3.title')}</h2>
            <AccordionUI className="bg p-4 p-sm-5" {...props}>
                <Auxiliary title={props.t('services:development:section-3.1.title')} text={props.t('services:development:section-3.1.text')}  />
                <Auxiliary title={props.t('services:development:section-3.2.title')} text={props.t('services:development:section-3.2.text')}  />
                <Auxiliary title={props.t('services:development:section-3.3.title')} text={props.t('services:development:section-3.3.text')}  />
                <Auxiliary title={props.t('services:development:section-3.4.title')} text={props.t('services:development:section-3.4.text')}  />
            </AccordionUI>
        </Container>
    </section>
);


export default development3;