import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Banner from '../../../components/Products/ShareholderMeeting/ShareholderMeetingBanner/ShareholderMeetingBanner';
import { withTranslation } from 'react-i18next';
import Head from '../../../components/Head/Head';
class ShareholderMeeting extends Component {
    render(){
        return(
            <Auxiliary>
                <Head id="shareholder-meeting" /> 
                <Banner {...this.props} />
            </Auxiliary>
        );
    }
}

export default withTranslation()(ShareholderMeeting);