import React from 'react';
import { Container } from 'react-bootstrap';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import AccordionUI from '../../../UI/AccordionUI/AccordionUI';

const quality4 = props =>(
    <section 
        id="quality-section4"
        className="py-5 services">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('services:quality:section-4.title')}</h2>
            <AccordionUI className="bg  p-4 p-sm-5" {...props}>
                <Aux title={props.t('services:quality:section-4.subtitle1')} text={props.t('services:quality:section-4.text1')}  />
                <Aux title={props.t('services:quality:section-4.subtitle2')} text={props.t('services:quality:section-4.text2')}  />
                <Aux title={props.t('services:quality:section-4.subtitle3')} text={props.t('services:quality:section-4.text3')}  />
                <Aux title={props.t('services:quality:section-4.subtitle4')} text={props.t('services:quality:section-4.text4')}  />
                <Aux title={props.t('services:quality:section-4.subtitle5')} text={props.t('services:quality:section-4.text5')}  />
            </AccordionUI>
        </Container>
    </section>
);

export default quality4;