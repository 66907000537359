import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import IconTitleText from '../../UI/IconTitleText/IconTitleText';
import email from "../../../assets/images/contacts/email.svg";
import lisboa from "../../../assets/images/contacts/lisboa.svg";
import aveiro from "../../../assets/images/contacts/aveiro.svg";


const Contacts2 = props => (
    <section
        id="contacts-section2"
        className="my py-5">
        <Container>
            <h2 className="h4 black fw-600">
                <div className="title-triangle top-md"></div>
                {props.t('contacts:section-2.title')}
            </h2>
            <Row>
                <Col md={6}>
                    <IconTitleText
                        title={props.t('contacts:section-2.email')}
                        mail="comercial@sysmatch.com"
                        img={email} />
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <IconTitleText
                        title={["Lisboa",
                            <a href="tel:+351213304403" className="h6 fw-700 mb-0" target="blank">
                                <span className="ml-2 ml-lg-3 text-nowrap ">(+351) 213 304 403</span>
                                <p className="ml-2 ml-lg-3 mt-0 call"><span>{props.t('contacts:section-2.info-fix')}</span></p>
                            </a>]}
                        text="Av. D. João II, Ed. Infante, Nº35, 7ºH Parque das Nações, 1990-083 Lisboa"
                        img={lisboa}>{/* <iframe title="Lisboa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99565.28450002546!2d-9.17271832093369!3d38.754181331317874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4910f0c274fb7800!2sSysmatch%20-%20Consultores%20de%20Sistemas%20de%20Informa%C3%A7%C3%A3o%2C%20Lda.!5e0!3m2!1spt-PT!2spt!4v1619091635582!5m2!1spt-PT!2spt&v=3.53" width="100%" height="300px" style={{ border: 0, marginTop: 0 }} allowFullScreen="" loading="lazy"></iframe> */}
                    </IconTitleText>
                </Col>
                <Col md={6}>
                    <IconTitleText
                        title={["Santa Maria da Feira",
                            <a href={"tel:+351933300038"} className="h6 fw-700 mb-0" target="blank">
                                <span className="ml-2 ml-lg-3 text-nowrap">(+351) 933 300 038</span>
                                <p className="ml-2 ml-lg-3 mt-0 call"><span>{props.t('contacts:section-2.info-mobile')}</span></p>
                            </a>]}
                        text="3CE – Centro de Conhecimento, Competências e Empresas, Rua 25 de Abril Nº313, 4520-115 Santa Maria da Feira"
                        img={aveiro}>{/* <iframe title="Santa Maria da Feira" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1507.4457999781116!2d-8.572468641976174!3d40.918122621630225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23812b38ff62c7%3A0xe9988f89198a32db!2sR.%2025%20de%20Abril%2C%204520-115!5e0!3m2!1spt-PT!2spt!4v1619091865310!5m2!1spt-PT!2spt&v=3.53" width="100%" height="300px" style={{ border: 0, marginTop: 0 }} allowFullScreen="" loading="lazy"></iframe> */}</IconTitleText>
                </Col>
            </Row>
        </Container >
    </section >
);

export default Contacts2;