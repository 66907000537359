import "core-js/stable";
import 'regenerator-runtime/runtime';
// polyfill only stable ES features:
import "core-js/es";
import 'raf/polyfill';

import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { createStore , compose , applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import langReducer from './store/reducers/lang';
import cookiesReducer from './store/reducers/cookies';
import jobsReducer from './store/reducers/jobs';
import formReducer from './store/reducers/form';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { watchLang, watchCookies , watchJobs , watchForm } from './store/sagas/index';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';


const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : (null || compose);
const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  lang: langReducer,
  cookies: cookiesReducer,
  jobs: jobsReducer,
  form: formReducer
});
const store = createStore ( rootReducer , composeEnhancers(
  applyMiddleware( thunk , sagaMiddleware )
  ) );

sagaMiddleware.run( watchLang );
sagaMiddleware.run( watchCookies );
sagaMiddleware.run( watchJobs );
sagaMiddleware.run( watchForm );

library.add(fab);
library.add(fas);
library.add(far);

// ReactDOM.render(
//   <CookiesProvider>
//     <Provider store={store}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>
//   </CookiesProvider>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<CookiesProvider>
            <Provider store={store}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Provider>
          </CookiesProvider>, rootElement);
} else {
  render(<CookiesProvider>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </CookiesProvider>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
