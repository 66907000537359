import React from 'react';
import { Container } from 'react-bootstrap';
import BoxInfo from "../../../UI/BoxInfo/BoxInfo";

const ProjectManagement2 = props => (
    <section 
        id="projectManagement-section2" 
        className="my-4 py-4 my-md-5 py-md-5">
        <Container>
            <BoxInfo
                title={props.t("products:projectManagement.section-2.title")}
                text= {props.t("products:projectManagement.section-2.text")}
                titleColor = "green"
            />
        </Container>
    </section>
);

export default ProjectManagement2;