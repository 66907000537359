import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import routesEN from './assets/locales/en/routes.json';
import routesPT from './assets/locales/pt/routes.json';
import routesDE from './assets/locales/de/routes.json';
import metatagsPT from './assets/locales/pt/metatags.json';
import metatagsEN from './assets/locales/en/metatags.json';
import metatagsDE from './assets/locales/de/metatags.json';
import partialsEN from './assets/locales/en/partials.json';
import partialsPT from './assets/locales/pt/partials.json';
import partialsDE from './assets/locales/de/partials.json';
import homepagePT from './assets/locales/pt/homepage.json';
import homepageEN from './assets/locales/en/homepage.json';
import homepageDE from './assets/locales/de/homepage.json';
import servicesConsultingPT from './assets/locales/pt/services/consulting.json';
import servicesConsultingEN from './assets/locales/en/services/consulting.json';
import servicesConsultingDE from './assets/locales/de/services/consulting.json';
import servicesOutsourcingPT from './assets/locales/pt/services/outsourcing.json';
import servicesOutsourcingEN from './assets/locales/en/services/outsourcing.json';
import servicesOutsourcingDE from './assets/locales/de/services/outsourcing.json';
import servicesDevelopmentPT from './assets/locales/pt/services/development.json';
import servicesDevelopmentEN from './assets/locales/en/services/development.json';
import servicesDevelopmentDE from './assets/locales/de/services/development.json';
import servicesQualityEN from './assets/locales/en/services/quality.json';
import servicesQualityPT from './assets/locales/pt/services/quality.json';
import servicesQualityDE from './assets/locales/de/services/quality.json';
import servicesNearshoringPT from './assets/locales/pt/services/nearshoring.json';
import servicesNearshoringEN from './assets/locales/en/services/nearshoring.json';
import servicesNearshoringDE from './assets/locales/de/services/nearshoring.json';
import productsAttendenceManagementPT from './assets/locales/pt/products/attendenceManagement.json';
import productsAttendenceManagementEN from './assets/locales/en/products/attendenceManagement.json';
import productsAttendenceManagementDE from './assets/locales/de/products/attendenceManagement.json';
import productsProjectManagementPT from './assets/locales/pt/products/projectManagement.json';
import productsProjectManagementEN from './assets/locales/en/products/projectManagement.json';
import productsProjectManagementDE from './assets/locales/de/products/projectManagement.json';
import productsMeetingRoomPT from './assets/locales/pt/products/meetingRoom.json';
import productsMeetingRoomEN from './assets/locales/en/products/meetingRoom.json';
import productsMeetingRoomDE from './assets/locales/de/products/meetingRoom.json';
import productsShareholderMeetingPT from './assets/locales/pt/products/shareholderMeeting.json';
import productsShareholderMeetingEN from './assets/locales/en/products/shareholderMeeting.json';
import productsShareholderMeetingDE from './assets/locales/de/products/shareholderMeeting.json';
import creditManagementPT from './assets/locales/pt/products/creditManagement.json';
import creditManagementEN from './assets/locales/en/products/creditManagement.json';
import creditManagementDE from './assets/locales/de/products/creditManagement.json';
import billingSoftwarePT from './assets/locales/pt/products/billingSoftware.json';
import billingSoftwareEN from './assets/locales/en/products/billingSoftware.json';
import billingSoftwareDE from './assets/locales/de/products/billingSoftware.json';
import comercialPaperPT from './assets/locales/pt/products/comercialPaper.json';
import comercialPaperEN from './assets/locales/en/products/comercialPaper.json';
import comercialPaperDE from './assets/locales/de/products/comercialPaper.json';
import blogPT from './assets/locales/pt/blog.json';
import blogEN from './assets/locales/en/blog.json';
import blogDE from './assets/locales/de/blog.json';
import jobsPT from './assets/locales/pt/jobs.json';
import jobsEN from './assets/locales/en/jobs.json';
import jobsDE from './assets/locales/de/jobs.json';
import contactsEN from './assets/locales/en/contacts.json';
import contactsPT from './assets/locales/pt/contacts.json';
import contactsDE from './assets/locales/de/contacts.json';
import privacyPT from './assets/locales/pt/privacy.json';
import privacyEN from './assets/locales/en/privacy.json';
import privacyDE from './assets/locales/de/privacy.json';
import cookiesPT from './assets/locales/pt/cookies.json';
import cookiesEN from './assets/locales/en/cookies.json';
import cookiesDE from './assets/locales/de/cookies.json';
import personalDataPT from './assets/locales/pt/personal-data.json';
import personalDataEN from './assets/locales/en/personal-data.json';
import personalDataDE from './assets/locales/de/personal-data.json';
import modalsPT from './assets/locales/pt/modals.json';
import modalsEN from './assets/locales/en/modals.json';
import modalsDE from './assets/locales/de/modals.json';
import thankYouPT from './assets/locales/pt/thankYou.json';
import thankYouEN from './assets/locales/en/thankYou.json';
import thankYouDE from './assets/locales/de/thankYou.json';
import rgpcPT from './assets/locales/pt/services/rgpc.json';
import rgpcEN from './assets/locales/en/services/rgpc.json';
import rgpcDE from './assets/locales/de/services/rgpc.json';
// the translations
const resources = {
  en: {
    routes: routesEN,
    metatags: metatagsEN,
    partials: partialsEN,
    homepage: homepageEN,
    blog: blogEN,
    services: {
      consulting: servicesConsultingEN,
      development: servicesDevelopmentEN,
      outsourcing: servicesOutsourcingEN,
      quality: servicesQualityEN,
      nearshoring: servicesNearshoringEN,
      rgpc: rgpcEN,
    },
    products: {
      attendenceManagement: productsAttendenceManagementEN,
      meetingRoom: productsMeetingRoomEN,
      shareholderMeeting: productsShareholderMeetingEN,
      projectManagement: productsProjectManagementEN,
      creditManagement: creditManagementEN,
      billingSoftware: billingSoftwareEN,
      comercialPaper: comercialPaperEN
    },
    jobs: jobsEN,
    contacts: contactsEN,
    privacy: privacyEN,
    cookies: cookiesEN,
    personalData: personalDataEN,
    modals: modalsEN,
    thankYou: thankYouEN
  },
  pt: {
    routes: routesPT,
    metatags: metatagsPT,
    partials: partialsPT,
    homepage: homepagePT,
    blog: blogPT,
    services: {
      consulting: servicesConsultingPT,
      development: servicesDevelopmentPT,
      outsourcing: servicesOutsourcingPT,
      quality: servicesQualityPT,
      nearshoring: servicesNearshoringPT,
      rgpc: rgpcPT,
    },
    products: {
      attendenceManagement: productsAttendenceManagementPT,
      meetingRoom: productsMeetingRoomPT,
      shareholderMeeting: productsShareholderMeetingPT,
      projectManagement: productsProjectManagementPT,
      creditManagement: creditManagementPT,
      billingSoftware: billingSoftwarePT,
      comercialPaper: comercialPaperPT
    },
    jobs: jobsPT,
    contacts: contactsPT,
    privacy: privacyPT,
    cookies: cookiesPT,
    personalData: personalDataPT,
    modals: modalsPT,
    thankYou: thankYouPT
  },
  de: {
    routes: routesDE,
    metatags: metatagsDE,
    partials: partialsDE,
    homepage: homepageDE,
    blog: blogDE,
    services: {
      consulting: servicesConsultingDE,
      development: servicesDevelopmentDE,
      outsourcing: servicesOutsourcingDE,
      quality: servicesQualityDE,
      nearshoring: servicesNearshoringDE,
      rgpc: rgpcDE,
    },
    products: {
      attendenceManagement: productsAttendenceManagementDE,
      meetingRoom: productsMeetingRoomDE,
      shareholderMeeting: productsShareholderMeetingDE,
      projectManagement: productsProjectManagementDE,
      creditManagement: creditManagementDE,
      billingSoftware: billingSoftwareDE,
      comercialPaper: comercialPaperDE
    },
    jobs: jobsDE,
    contacts: contactsDE,
    privacy: privacyDE,
    cookies: cookiesDE,
    personalData: personalDataDE,
    modals: modalsDE,
    thankYou: thankYouDE
  }
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    lng: "pt",
    fallbackLng: ["pt", "en", "de"],

    interpolation: {
      escapeValue: false
    },
    defaultTransParent: 'div', // a valid react element - required before react 16

    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'] // don't convert to <1></1> if simple react elements
  });

export default i18n;