import React from 'react';
import { Container } from 'react-bootstrap';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import AccordionUI from '../../../UI/AccordionUI/AccordionUI';

const ProjectManagement3 = props => (
    <section id="projectManagement-section3" className="my pt-5">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('products:projectManagement:section-3.title')}</h2>
            <AccordionUI className="bg p-4 p-sm-5" {...props}>
                <Auxiliary title={props.t('products:projectManagement:section-3.subtitle1')} text={props.t('products:projectManagement:section-3.text1')}  />
                <Auxiliary title={props.t('products:projectManagement:section-3.subtitle2')} text={props.t('products:projectManagement:section-3.text2')}  />
                <Auxiliary title={props.t('products:projectManagement:section-3.subtitle3')} text={props.t('products:projectManagement:section-3.text3')}  />
                <Auxiliary title={props.t('products:projectManagement:section-3.subtitle4')} text={props.t('products:projectManagement:section-3.text4')}  />
                <Auxiliary title={props.t('products:projectManagement:section-3.subtitle5')} text={props.t('products:projectManagement:section-3.text5')}  />
            </AccordionUI>
        </Container>
    </section>
);

export default ProjectManagement3;