import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

const IconTitleText = props => (
    <Col xs={12} className="IconTitleText my-4">
        <Row className="flex-nowrap">
            <div className="diamond-botton-icon">
                <div className="icon">
                    <Image src={props.img} alt="" />
                </div>
            </div>
            <div className="content">
                <h3 className="h6 black fw-700 mb-0 d-flex">{props.title}</h3>
                {props.text && <p>{props.text}</p>}
                {props.mail && <p><a href={'mailto:' + props.mail} target="new">{props.mail}</a></p>}
            </div>
        </Row>
        <Row>
            <Col xs={12}>
                {props.children &&
                    <div className="my-3">{props.children}</div>}
            </Col>
        </Row>
    </Col>
);

export default IconTitleText;