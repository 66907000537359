import * as actionTypes from './actionsTypes';

//TODO: set time
export const setCookie = (cookies, name, value, localeOnState, expiresIn = 31536000) => {
    return {
        type: actionTypes.SET_COOKIE,
        cookies,
        name,
        value,
        expiresIn,
        localeOnState,
    }
}

export const removeCookie = (cookies, name) => {
   
    return {
        type: actionTypes.REMOVE_COOKIE,
        cookies: cookies,
        name: name
    }
}

export const removeAllCookies = (cookies) => {
    return {
        type: actionTypes.REMOVE_ALL_COOKIES,
        cookies: cookies,
    }
}

export const deleteCookie = (name) => {
    return {
        type: actionTypes.DELETE_COOKIE,
        name: name
    }
}

export const saveCookie = (name, value) => {
    return {
        type: actionTypes.SAVE_COOKIE,
        name: name,
        value: value,
    }
}

export const initStateCookies = (cookies, localeInState) => {
    return {
        type: actionTypes.INIT_STATE_COOKIES,
        cookies: cookies,
        localeInState: localeInState
    }
}
