import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Col } from 'react-bootstrap';
import ItemIconButton from '../../../UI/ItemIconButton/ItemIconButton';
import web from "../../../../assets/images/development/section-2/aplicacoes-web.svg";
import mobile from "../../../../assets/images/development/section-2/aplicacoes-mobile.svg";

const development2 = props => (
    <section 
        id="development-section2"
        className="my pt-5">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-4">{props.t('services:development.section-2.title')}</h2>
            <Row>
                <Col lg={2}>
                    <ItemIconButton 
                        src={web}
                        title={props.t('services:development.section-2.1')}
                        alt={props.t('services:development.section-2.1_alt')}
                    />
                </Col>
                <Col lg={2}>
                    <ItemIconButton 
                        src={mobile}
                        title={props.t('services:development.section-2.2')}
                        alt={props.t('services:development.section-2.2_alt')}
                    />
                </Col>
            </Row>
        </Container>
    </section>

);

export default development2;
