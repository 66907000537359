import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Head from '../../../components/Head/Head'
import Consulting2 from '../../../components/Services/Consulting/Consulting2/Consulting2';
import Consulting3 from '../../../components/Services/Consulting/Consulting3/Consulting3';
import Consulting4 from '../../../components/Services/Consulting/Consulting4/Consulting4';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';
import Banner from '../../../components/Services/Consulting/ConsultingBanner/ConsultingBaner';
import { withTranslation } from 'react-i18next';


class Consulting extends Component {
    render(){
        return (
            <Auxiliary>
                <Head id="consulting"/>
                <Banner {...this.props} />
                <Consulting2 {...this.props} />
                <Consulting3 {...this.props} />
                <Consulting4 {...this.props} />
                <CallToAction
                    color="light-orange"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnText')}
                    />
            </Auxiliary>
        );
    }
}

export default withTranslation()(Consulting);