import React, { Component } from 'react';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Head from '../../components/Head/Head'
import { withTranslation } from 'react-i18next';
import Banner from '../../components/Blog/BlogBanner/BlogBanner';
import Blog2 from '../../components/Blog/Blog2/Blog2';

class Blog extends Component {
    render(){
        return (
            <Auxiliary>
                <Head id="blog"/>
                <Banner {...this.props} />
                <Blog2 {...this.props} />
            </Auxiliary>
        );
    }
}

export default withTranslation()(Blog);