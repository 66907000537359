import React, { useState, useRef } from 'react';
import Banner from '../../UI/Banner/Banner';
import Span from './HomeBannerSpan/HomeBannerSpan';

const HomeBanner = (props) => {
    const [ position, setPosition ] = useState({
        XAngle: 0,
		YAngle: 0,
    });

    const objRef = useRef(null);
    let timeout;
    const folowMouseHandler = (e) => {            
            const parent = objRef.current.offsetParent;
           
            const XRel = e.pageX - parent.getBoundingClientRect().left;
            const YRel = e.pageY - parent.getBoundingClientRect().top;
            const width = parent.getBoundingClientRect().width;
            const YAngle = -(0.5 - (XRel / width)) * 40;                
            const XAngle = (0.5 - (YRel / width)) * 40;
       
            setPosition({ XAngle  , YAngle  });        
    }

    
    let whenMouseMoves = (e) => {
        if( !objRef )
                return;
        clearTimeout(timeout);
        timeout = setTimeout(folowMouseHandler(e), 150);
      }

    const cleanPosition = () => {
        if( !objRef )
                return;
        clearTimeout(timeout);

        setPosition({
            XAngle: 0,
            YAngle: 0
        });
       
    }
    return (
        <Banner 
            id="homepage-banner" 
            imageBackground={<Span />}
            className="banner black pb-5" 
            title="homepage:section-1.title"
            text="homepage:section-1.txt"
            button={{ to: "routes:contacts.path" , text: "homepage:section-1.btn" }}
            mouseLeave={cleanPosition}
            timeDelay={2.4}
            t={props.t}>
            <div ref={objRef} className="title text-center" onMouseMove={(e) => whenMouseMoves(e)}  
               style={{ 
                transform: "perspective(300px) translateZ( 30px) rotateX(" + position.XAngle + "deg) rotateY(" + position.YAngle + "deg)",
                transition: "transform 0.3s" 
                
             }}>
                <span className="span-top"><i></i> 
                    <p className="h3 path fw-700">
                        <strong className="title-triangle top-md"></strong>
                            PROGRAM
                    </p>
                </span>
                <p className="h3 fw-700">YOUR BUSINESS</p>
                <span className="span-button"><i></i> <p className="h3 path fw-700">FOR BETTER</p></span>
                
            </div>
        </Banner>
    );
};

export default HomeBanner;