import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Head from '../../../components/Head/Head';
import Banner from '../../../components/Services/Rgpc/RgpcBanner/RgpcBanner';
import CallToAction from "../../../components/UI/CallToAction/CallToAction";
import { withTranslation } from 'react-i18next';
import Rgpc2 from '../../../components/Services/Rgpc/Rgpc2/Rgpc2';
import Rgpc3 from '../../../components/Services/Rgpc/Rgpc3/Rgpc3';
import Rgpc4 from '../../../components/Services/Rgpc/Rgpc4/Rgpc4';
import Rgpc5 from '../../../components/Services/Rgpc/Rgpc5/Rgpc5';
import Rgpc6 from '../../../components/Services/Rgpc/Rgpc6/Rgpc6';
import Rgpc7 from '../../../components/Services/Rgpc/Rgpc7/Rgpc7';
import Rgpc8 from '../../../components/Services/Rgpc/Rgpc8/Rgpc8';
class Rgpc extends Component {
    render() {
        return (
            <Auxiliary>
                <Head id="rgpc" />
                <Banner {...this.props} />
                <Rgpc2 {...this.props} />
                <Rgpc3 {...this.props} />
                <Rgpc4 {...this.props} />
                <Rgpc5 {...this.props} />
                <Rgpc6 {...this.props} />
                <Rgpc7 {...this.props} />
                <Rgpc8 {...this.props} />
                <div className='custom-end'></div>
                <CallToAction
                    color="light-orange"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnText')}
                />
            </Auxiliary>
        );
    }
}

export default withTranslation()(Rgpc);