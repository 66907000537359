import React, { Component } from 'react';
import Banner from '../../../components/UI/PolicyPage/PolicyPage';
import {withTranslation } from 'react-i18next';
import Head from '../../../components/Head/Head';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
class Privacy extends Component {
    render(){
        return(
            <Auxiliary>
                <Head id="privacy"/>
                <Banner 
                    {...this.props}
                    page="privacy"
                />
            </Auxiliary>
        );
    }
}

export default withTranslation()(Privacy);