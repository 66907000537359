import React from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Banner from '../../UI/Banner/Banner';
import New from '../../UI/NewUI/NewUI';

const BlogBanner = props => (
    <Auxiliary>
        <Banner
            id=""
            className="banner blogBanner black pb-5"
            t={props.t}
            timeDelay={1.55}>
            <New {...props} />
        </Banner>

    </Auxiliary>
);

export default BlogBanner;