import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Rgpc8 = props => (
    <section id="rgpc-section8" className="my white-bg pt-5">
        <Container>
            <p className='m-0'>{props.t('services:rgpc:section-8.text')}</p>
        </Container>
    </section>
);


export default Rgpc8;