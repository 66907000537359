import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Head from '../../../components/Head/Head';
import Banner from '../../../components/Services/Development/DevelopmentBanner/DevelopmentBanner'; 
import Development2 from '../../../components/Services/Development/Development2/Development2';
import Development3 from '../../../components/Services/Development/Development3/Development3';
import Development4 from '../../../components/Services/Development/Development4/Development4';
import Development5 from '../../../components/Services/Development/Development5/Development5';
import Development6 from '../../../components/Services/Development/Development6/Development6';
import { withTranslation } from 'react-i18next';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';
class Development extends Component {
    render(){
        return (
            <Auxiliary>
                <Head id="development"/>
                <Banner {...this.props} />
                <Development2 {...this.props } />
                <Development3 {...this.props } />
                <Development4 {...this.props } />
                <Development5 {...this.props } />
                <Development6 {...this.props } />
                <CallToAction
                    color="light-orange"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnText')}
                    />
            </Auxiliary>
            
        );
    }
}

export default withTranslation()( Development );