
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import ThankYouPage from '../../components/ThankYouPage/ThankYouPage';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Head from '../../components/Head/Head';
import Page404 from '../Errors/Page404/Page404';

class ThankYou extends Component {
    render() {
        let headId = '';
        let renderNew = <Page404 />
        if (window.location.pathname.split('/')[2] === this.props.t("routes:thank_you.name")) {
            headId = 'thank-you';
            renderNew = <ThankYouPage {...this.props} />;
        }
        return (
            <Auxiliary>
                <Head id={headId} />
                {renderNew}
            </Auxiliary>
        )
    }
}

export default withTranslation()(ThankYou)