import React, { Component } from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import bg_image from "../../../assets/images/error/404-imagem.png";
import Button from "../../../components/UI/Button/Button";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class Page404 extends Component {
    render(){
        return (
            <section id="error-404" className="green-bg error-page">
                    <Container className="py-5">
                        <Row>
                            <Col xs={12} lg={{span:10, offset:1}}>
                                <Image src={bg_image}/>
                                <div className="error-info d-flex d-md-block">
                                    <h2 className="d-none d-md-block">OOPPS!</h2>
                                    <p className="fw-600 d-none d-md-block">{this.props.t('partials:errors.404.title')}</p>
                                    <Link to={this.props.t('routes:homepage.path')} className="ml-auto mr-auto ml-md-0 mr-md-0 mt-3"> 
                                        <Button btnClasses="p-l h5 fw-600 text-uppercase mx-0" >{this.props.t('partials:errors.404.btn')}</Button>
                                    </Link>  
                                </div>
                            </Col>
                        </Row>
                    </Container>
            </section>
        );
    }
}

export default withTranslation()(Page404);