import React, { useState, useEffect } from 'react';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import './_HomeBannerSpan.scss';

const HomeBannerSpan = props => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        return () => {
            setShow(false);
        };

    }, []);

    return (
        <Aux>
            {show &&
                <div className="banner-span">
                    <span className="ligthBlue-mountain animation shadow"></span>
                    <span className="blue-mountain animation"></span>
                    <span className="yellow-mountain animation"></span>
                    <span className="black-mountain animation"></span>
                    <span className="orange-mountain animation"></span>
                    <span className="sliver-mountain animation"></span>
                </div>
            }
        </Aux>
    )
}

export default HomeBannerSpan;