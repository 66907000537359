import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Head from '../../../components/Head/Head';
import NearshoringBanner from '../../../components/Services/Nearshoring/NearshoringBanner/NearshoringBanner';
import Nearshoring2 from '../../../components/Services/Nearshoring/Nearshoring2/Nearshoring2';
import Nearshoring3 from '../../../components/Services/Nearshoring/Nearshoring3/Nearshoring3';
import { withTranslation } from 'react-i18next';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';

class Nearshoring extends Component {
    render(){
        return (
            <Auxiliary>
                <Head id="nearshoring"/>
                <NearshoringBanner {...this.props}/>
                <Nearshoring2 {...this.props}/>
                <Nearshoring3 {...this.props}/>
                <CallToAction
                    color="light-orange"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnText')}
                    />
            </Auxiliary>
            
        );
    }
}

export default withTranslation()(Nearshoring);