import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Container, Row, Image } from 'react-bootstrap';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import * as actions from '../../../store/actions/index';

const Banner = props => {
    const [paddingTop, setPaddingTop] = useState(null);
    const [minHeight, SetMinHeight] = useState(null);
    const [show, setShow] = useState(true);
    const [inView, setInview] = useState('');
    const [animations, setAnimations] = useState({ class: '', title: null, content: null });
    const BannerRef = useRef(null);

    const setPaddingTop_ = () => {
        setPaddingTop(document.getElementsByTagName('header')[0].clientHeight + 'px');
    }

    useEffect(() => {
        if (props.timeDelay)
            setAnimations({ ...animations, class: 'animation' });
        setPaddingTop_();
        window.addEventListener('resize', setPaddingTop_);
        setShow(true);
        return () => {
            window.removeEventListener('resize', setPaddingTop_);
            setShow(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (BannerRef.current && BannerRef.current.clientWidth < 1200)
            SetMinHeight(null);
        else
            SetMinHeight('calc( 100vh - ' + paddingTop + ' )');
    }, [paddingTop]);

    useEffect(() => {
        if (animations.title !== null)
            return;
        if (navigator.userAgent !== 'ReactSnap') {
            setTimeout(() => {
                const _class = props.timeDelay ? animations.class + ' animation start' : '';
                const title = { transition: 'all .6s ' + props.timeDelay + 's cubic-bezier(0.07, 0.06, 0.26, 0.83)' };
                const content = { transition: 'all .6s ' + (props.timeDelay + 0.4) + 's cubic-bezier(0.07, 0.06, 0.26, 0.83)' };
                setAnimations({ class: _class, title, content });
                setInview(' inView');

            }, 500);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
        setTimeout(() => {
            if (BannerRef.current) {
                var children = [].slice.call(BannerRef.current.firstChild.children)
                children.forEach(element => element.classList.add('shadow'))
            }
        }, props.timeDelay * 1700)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [BannerRef]);

    //Mountains services || products || blog
    const mountainsBanner = props.className.includes("services-banner") || props.className.includes("products-banner") ?
        (<div className={animations.class}>
            <span className="white-mountain animation"></span>
            <span className="black-mountain-shadow animation"><span className="black-mountain"></span></span>
            {props.imageBannerRight &&
                (<div className="image-banner-right animation">
                    <picture>
                        <source srcSet={props.imageBannerRightWebp} type="image/webp" />
                        <source srcSet={props.imageBannerRight} type="image/png" />
                        <Image src={props.imageBannerRight} fluid />
                    </picture>
                    <span className="grey-mountain"></span>
                </div>)
            }
            <span className="bottom-mountain animation"></span>
        </div>) : props.className.includes('blogBanner') ?

            (<div className={animations.class}>
                <span className="white-mountain animation"></span>
                <span className="light-blue-mountain-shadow animation"><span className="light-blue-mountain"></span></span>
            </div>)

            : null;

    //left Text || Homepage
    let textLeft = <Col className="pl-0">
        <p className="p">{props.t(props.text)}</p>
    </Col>;

    if (props.text && (props.id.includes("homepage-banner") || props.id.includes("jobs-banner")))
        textLeft = (<Col className="pl-0 d-none d-sm-block" md={11}>
            <p className="h5 text-white">{props.t(props.text)}</p>
        </Col>);
    return (
        <Auxiliary>
            {show &&
                <section
                    id={props.id}
                    className={props.className + inView}
                    ref={BannerRef}
                    style={{
                        paddingTop,
                        minHeight
                    }}>
                    {props.imageBackground}
                    {mountainsBanner}

                    <Container>
                        <Row>
                            {props.id.includes("homepage-banner") ?
                                (<Col className={"title " + animations.class} xs={{ span: 7 }} lg={{ span: 6 }} style={animations.title}>
                                    <h1 className="text-uppercase h2 fw-600 text-white"><Trans t={props.t} i18nKey={props.title} components={
                                        {
                                            spanOrange: <span className='spanOrange'></span >
                                        }
                                    } /></h1>
                                    {textLeft}

                                    {props.button && <Link to={props.t(props.button.to) + '#top'}>
                                        <Button btnClasses={'p-l p fw-600 text-uppercase m-0 mt-3'}>
                                            {props.t(props.button.text)}
                                        </Button>
                                    </Link>
                                    }
                                </Col>) : props.id.includes("jobs-banner") ?
                                    (<Col className={"title " + animations.class} xs={{ span: 12 }} md={{ span: 7 }} style={animations.title}>
                                        <h1 className="text-uppercase h2 fw-600"><Trans t={props.t} i18nKey={props.title} /></h1>
                                        {textLeft}
                                    </Col>) :
                                    !props.id.includes("contacts-banner") && props.title &&
                                    (<Col className={"title " + animations.class} lg={{ span: 6 }} style={animations.title}>
                                        <h1 className="text-uppercase h2 fw-600"><Trans t={props.t} i18nKey={props.title} /></h1>

                                        {textLeft}

                                        {props.button && <Link to={props.t(props.button.to) + (props.product ? '?product=' + props.product : '') + '#top'}>
                                            <Button clicked={props.product ? () => props.onSetProduct(props.product) : null} btnClasses={'p-l p fw-600 text-uppercase m-0 mt-3'}>
                                                {props.t(props.button.text)}
                                            </Button>
                                        </Link>
                                        }
                                    </Col>)
                            }
                            {props.id.includes("homepage-banner") ?
                                (<Col className={"content pr-4 py-4 p-md-5 mb-md-4 mb-lg-0 " + animations.class} xs={{ span: 5 }} lg={{ span: 5, offset: 1 }} onMouseLeave={props.mouseLeave} style={animations.content}>
                                    {props.children}
                                </Col>) : props.id.includes("jobs-banner") ?
                                    (<Col className={"content pr-4 py-4 p-md-5 mb-md-4 mb-lg-0 " + animations.class} xs={{ span: 5, offset: 6 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} onMouseLeave={props.mouseLeave} style={animations.content}>
                                        {props.children}
                                    </Col>) :
                                    props.id.includes("contacts-banner") ?
                                        <Col md={12} id="form" className={animations.class} style={animations.title}>
                                            {props.children}
                                        </Col>
                                        : props.className.includes('blogBanner') ?
                                            <Col className={animations.class} xs={12} style={animations.title}>
                                                {props.children}
                                            </Col>
                                            : (<Col className={"content align-self-center " + animations.class} style={animations.title} lg={{ span: 6 }}>
                                                {props.children}
                                            </Col>)}
                        </Row>
                    </Container>


                </section>
            }
        </Auxiliary>
    );
};

Banner.propType = {
    id: PropTypes.string,
    imageBackground: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    button: {
        to: PropTypes.string,
        text: PropTypes.string,
    },
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

const mapDispatchProps = dispatch => {
    return {
        onSetProduct: (product) => dispatch(actions.setProduct(product))
    }
}

export default connect(null, mapDispatchProps)(Banner);