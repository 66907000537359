import React from 'react';
import {Container } from 'react-bootstrap';
import BoxInfo from "../../../UI/BoxInfo/BoxInfo"

const quality2 = props =>(
    <section 
        id="quality-section2"
        className="py-5">
        <Container>
            <BoxInfo
                title={props.t('services:quality:section-2.title')}
                text={props.t('services:quality:section-2.text')}
                titleColor="orange"
            />
        </Container>
    </section>
  

);

export default quality2;