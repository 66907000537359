import React from 'react';
import { Container } from 'react-bootstrap';
import AccordionUI from '../../../UI/AccordionUI/AccordionUI';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

const consulting4 = props => (
    <section
        id="consulting-section4"
        className="my">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('services:consulting:section-4.title')}</h2>
            <AccordionUI className="bg p-4 p-sm-5" {...props}>
                <Auxiliary title={props.t('services:consulting:section-4.1.title')} text={props.t('services:consulting:section-4.1.text')}  />
                <Auxiliary title={props.t('services:consulting:section-4.2.title')} text={props.t('services:consulting:section-4.2.text')}  />
                <Auxiliary title={props.t('services:consulting:section-4.3.title')} text={props.t('services:consulting:section-4.3.text')}  />
                <Auxiliary title={props.t('services:consulting:section-4.4.title')} text={props.t('services:consulting:section-4.4.text')}  />
            </AccordionUI>
            </Container>
    </section>
);


export default consulting4;