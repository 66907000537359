import React from 'react';
import { Col } from 'react-bootstrap';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { formatLink } from '../../../shared/utility';
import Banner from '../../UI/Banner/Banner';
import BlogUI from '../../UI/BlogUI/BlogUI';

// Banner from Blog page
const BlogBanner = props => {

    const blogContent = props.i18n.getResource(props.i18n.language, 'blog')['news'];

    let blogArray = Object.keys(blogContent).reduce((array, key) => {
        const content = { ...blogContent[key], key: key };
        return [...array, content];
    }, []);

    let last4 = blogArray.slice(blogArray.length - 4, blogArray.length);
    let articles = last4.map((article, key) => (
        <Auxiliary
            key={key}
            title={article["content"]["title"]}
            text={article["summary"]}
            img={require("../../../assets/images/blog/" + article["content"]["image"].name + ".jpg")}
            imgWebp={require("../../../assets/images/blog/" + article["content"]["image"].name + ".webp")}
            link={article['link'] ? article['link'] : (props.t('routes:blog.path') + '/' + formatLink(article['content']['url']))}
            alt={article["content"]["image"].alt}
            date={article["content"]["fullDate"]}
        />)
    );

    return (
        <Banner
            id="blog-banner"
            className="banner blogBanner black pb-5"
            t={props.t}
            timeDelay={1.55}>
            <Col xs={12}>
                <div className="title-triangle top-md"></div>
                <h2 className="h2 black fw-600 text-uppercase">{props.t('blog:title-aside')}</h2>
                <BlogUI id="blogBanner">
                    {articles}
                </BlogUI>
            </Col>
        </Banner>
    )
}

export default BlogBanner;