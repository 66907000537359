import React from 'react';
import { Link } from 'react-router-dom';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Button from '../../UI/Button/Button';

const jobsItem = props => (
    <div className={"jobItem" + (props.jobsIdsVisited && props.jobsIdsVisited.includes(props.id) ? ' visited' : '')}>
        <p className="fw-800">{props.title}</p>
        {props.home ?
            <Link to={props.link}>
                <Button btnClasses={'m-0  wh-35 h5 fw-600 text-uppercase'}>+</Button>
            </Link>
            :
            <Auxiliary>
                <Button clicked={props.clicked} btnClasses={'m-0  wh-35 h5 fw-600 text-uppercase'}>+</Button>
                {/* Uncomment on prod o crawl all opportunities */}
                <Link to={props.link} className="d-none"></Link>
            </Auxiliary>
        }
    </div>
);

export default jobsItem;