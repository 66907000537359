import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../UI/Button/Button';
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

const IconsNewUI = props => {
    const linkServer = 'http://sysmatch.com';
    const url = navigator.userAgent !== 'ReactSnap' ? window.location.href : (linkServer + window.location.pathname);
    let mailTo = props.t('blog:mail-to').replace(';title', encodeURIComponent(props.title));
    mailTo = mailTo.replace(';url', url);
    return (
        <div className="IconsNew pt-4">
            {props.thirdPerson ? <p>{props.t('blog:share-article-3rd')}</p> : <p>{props.t('blog:share-article')}</p>}
            <a href={mailTo} className="iconNew" target="blank">
                <Button btnClasses={'green'} >
                    <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
            </a>
            <a href={`//www.linkedin.com/shareArticle?mini=true&url=${url}`} className="iconNew" target="blank">
                <Button btnClasses={'green'} >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </Button>
            </a>
            <a href={`//www.facebook.com/sharer/sharer.php?u=${url}`} className="iconNew" target="blank">
                <Button btnClasses={'green'} >
                    <FontAwesomeIcon icon={faFacebookF} />
                </Button>
            </a>
        </div>
    );
};

export default IconsNewUI;