import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/services-banner/Consultoria-informatica.png";
import bannerImageWebpp from "../../../../assets/images/services-banner/Consultoria-informatica.webp";

const consultingBanner = (props) =>(
    <Banner 
        id="consulting-banner" 
        className="banner black pb-5 services-banner" 
        title="services:consulting:section-1.title"
        text="services:consulting:section-1.txt"
        t={props.t}
        imageBannerRight={bannerImage} 
        imageBannerRightWebp={bannerImageWebpp} 
        timeDelay={1.55}
    />
);

export default consultingBanner;