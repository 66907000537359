import React from 'react';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import './_JobsBannerSpan.scss';

const HomeBannerSpan = () => (
    <Aux>
        <div className="banner-span" >
            <span className="white-mountain animation"></span>
            <span className="black-mountain animation"></span>
            <span className="orange-mountain animation"></span>
            <span className="light-blue-mountain animation"></span>
        </div>
    </Aux>
);


export default HomeBannerSpan;