import { put, all } from 'redux-saga/effects'
import * as actions from '../actions/index';

//Adiciona nova cookie ao projeto e state
export function* trySetCookieSaga (action) {
    // quero aceitar as cookies //!verificar se estão aceites ou não
    if(action.name === 'accepted'){
        yield action.cookies.set(action.name, action.value, { path: '/' , maxAge: action.expiresIn}); 
        yield put(actions.saveCookie(action.name, action.value));
        //verifico se tenho o locacle em state e faço set
        if(action.localeOnState && action.value){
            yield action.cookies.set('locale', action.localeOnState, { path: '/' , maxAge: action.expiresIn});  //! definir logo o locale nas cookies
            yield put(actions.saveCookie('locale', action.localeOnState));
        }
    } else if (action.cookies.get('accepted')) { //quero fazer set de uma cookies nova (tenho de verificar se estão aceites)
        yield action.cookies.set(action.name, action.value, { path: '/' , maxAge: action.expiresIn}); 
        yield put(actions.saveCookie(action.name, action.value));
    }   
}

//remove uma cookies cujo nome é passado como parâmetro
export function* removeCookieSaga(action) {
    yield action.cookies.remove(action.name);
    yield put(actions.deleteCookie(action.name));
}

//remove todas as cookies do projeto e estado
export function* removeAllCookiesSaga(action){
    const allCookies = yield action.cookies.getAll();
    if(allCookies){
        let cookiesKeys = yield Object.keys(allCookies);
        yield all(cookiesKeys.map(key => put(actions.removeCookie(action.cookies, key))));
    }
}

//inicializa cookies no estado caso estejam definidas no projeto
export function* initStateCookiesSaga(action){
    //check se a cookie locale está definida em estado //!não era melhor a accepted
    if(!action.localeInState){
        //verifico se há cookies definidas
        const allCookies = yield action.cookies.getAll();
        if(allCookies){
            let cookiesKeys = Object.keys(allCookies);
            yield all(cookiesKeys.map(key => put(actions.saveCookie(key, allCookies[key]))));
        }  
    }
}