import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

import Aux from '../Auxiliary/Auxiliary';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header//Header';
import Footer from '../../components/Footer/Footer';
import { getRouteKey, isHomepage, isIE, updateObject } from '../../shared/utility';
import CookiesBanner from '../../components/CookiesBanner/CookiesBanner';
import Modal from '../../components/UI/Modal/Modal';
import * as ReactGA from "react-ga";
import TagManager from 'react-gtm-module'

class Layout extends Component {
    constructor(props) {
        super(props);
        this.main = React.createRef();
        this.state = {
            background: '',
            path: null,
            modal: null,
            modals: null,
            locale: null,
            cookies: null,
            isIe: ''
        }
    }
    initG = false;
    setBackground(location) {
        const path = location;
        const check = path.pathname ? path.pathname.split('/').length <= 3 : true;
        if (!this.state.path || (this.state.path.pathname !== path.pathname && check)) {
            const [key] = getRouteKey(this.props.i18n);
            const keyBg = this.props.t(key + '.bg_color');
            let background = keyBg ? keyBg : 'silver-bg';
            if (background === "false.bg_color") {
                background = "white-bg"
            }
            this.setState(updateObject(this.state, {
                path,
                background
            }));
        }

        const hash = window.location.hash;
        if (this.main.current !== null && hash) {
            let scroll = false;
            let each = new Promise((resolve) => {
                this.main.current.childNodes.forEach((node, i) => {
                    if (window.location.hash && node.id === hash.replace('#', '')) {
                        scroll = true;
                        node.scrollIntoView({ behavior: 'smooth' });
                        resolve();
                    }
                    if (i === this.main.current.childNodes.length - 1)
                        resolve();
                });
            })

            each.then(() => {
                if (!scroll && this.main.current)
                    this.main.current.scrollIntoView({ behavior: 'smooth' });

                let url = this.props.history.location.pathname;
                url = url.replace(hash, '');
                this.props.history.replace(url);
            })
        }
    }

    setModal(modalId) {
        this.setState(updateObject(this.state, { modal: modalId }));
    }

    setGA(cookies) {

        //!uncomment in prod
        if (cookies && !this.initG) {
            const tagManagerArgs = {
                gtmId: 'GTM-WVPF3Q4'
            }
            TagManager.initialize(tagManagerArgs);
            this.initG = true;
        }

        this.setState(
            updateObject(
                this.setState,
                {
                    cookies: cookies,
                }
            )
        );
    }

    setPageViewGA(location, force = false) {

        if (this.state.cookies && typeof location.hash !== 'undefined' && (force || this.state.path.pathname !== location.pathname)) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }


    }
    componentDidMount() {
        const _isIE = isIE() ? ' ie' : '';
        this.setState(
            updateObject(
                this.state,
                {
                    modals: this.props.i18n.getResource(this.props.i18n.language, 'modals'),
                    isIe: _isIE
                }
            ),
            () => {
                this.setBackground(window.location.pathname);
                this.props.history.listen((location, action) => {
                    this.setPageViewGA(location);
                    this.setBackground(location);
                });

                if (_isIE !== '') {
                    this.setModal('ie');
                }
            }
        );
        this.setPageViewGA(window.location, true);
    }

    componentDidUpdate() {
        const checkLocale = this.props.locale && this.props.locale !== this.state.locale;
        const checkCookies = this.props.cookies && this.state.cookies !== this.props.cookies.cookies.accepted;
        if (checkLocale) {
            this.setState(
                updateObject(
                    this.state,
                    {
                        modals: this.props.i18n.getResource(this.props.i18n.language, 'modals'),
                        locale: this.props.locale,
                    }
                ),
                () => {
                    if (checkCookies) {
                        this.setGA(this.props.cookies.cookies.accepted);
                    }
                }
            );
        }

        if (!checkLocale && checkCookies) {
            this.setGA(this.props.cookies.cookies.accepted);
        }

    }

    render() {
        const redirect = this.props.redirect ? <Redirect to={this.props.redirect} /> : null;
        const { locale, onRedirectTo, cookies } = this.props;
        const isHome = isHomepage(this.props.i18n) ? ' isHome' : '';
        return (
            <Aux>
                {redirect}
                <Header className={this.state.isIe} onRedirectTo={onRedirectTo} cookieLocale={cookies} />
                <main
                    ref={this.main}
                    className={this.state.background + isHome + ' ' + locale + this.state.isIe}>
                    {this.props.children}
                    <Footer modal={(modalId) => this.setModal(modalId)} onRedirectTo={onRedirectTo} />
                    <Modal t={this.props.t} closed={() => this.setModal(null)} modalId={this.state.modal} modals={this.state.modals} />
                </main>
                <CookiesBanner cookies={this.props.cookies} showBanner={this.props.showBanner} hideBanner={() => this.props.hideBanner()} />
            </Aux>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        redirect: state.lang.redirectTo,
        cookies: ownProps.cookies
    }
}
const mapDispatchProps = dispatch => {
    return {
        onRedirectTo: (i18n, locale, cookies) => dispatch(actions.tryRedirectTo(i18n, locale, cookies)),
    }
}
export default connect(mapStateToProps, mapDispatchProps)(withRouter(Layout));
