import React, { Component } from 'react';
import Banner from '../../../components/Products/BillingSoftware/BillingSoftwareBanner/BillingSoftwareBanner';
import BillingSoftware2 from '../../../components/Products/BillingSoftware/BillingSoftware2/BillingSoftware2';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { withTranslation } from 'react-i18next';
import Head from '../../../components/Head/Head';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';
class BillingSoftware extends Component {
    render(){
        return (
            <Auxiliary >
                <Head id="billing-software" />
                <Banner {...this.props} />
                <BillingSoftware2 {...this.props} />
                <CallToAction
                    color="light-green"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnTextProducts')}
                    product="billingSoftware"
                    />
            </Auxiliary>
        );
    }
}

export default withTranslation()(BillingSoftware);