import React from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionItemUI from './AccordionItemUI';

const AccordionUI = props => {
    
    const AccordionItens = props.children.map( (item,i) => <AccordionItemUI key={i+1} eventKey={i+1} {...item.props} /> )
    return(
        <Accordion ref={props._ref} className={"AccordionUI IconTitle " + (props.className ? props.className : '')}>
            {AccordionItens}
        </Accordion>
    );
}

export default AccordionUI;