import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import { Col, Container, Row } from 'react-bootstrap';

const VideoText = props => (
    <section
        id={props.id}
        className={"videoText " + props.className}>
        <Container>
            <h2 className="black h4 fw-600 mb-5">{props.title}</h2>
            <Row>
                <Col lg={6}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src={props.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </Col>
                <Col lg={6} className="content">
                    <h3 className="black fw-600">{props.subTitle}</h3>
                    <p>{props.text}</p>
                    <div className="icons my-4">
                        <Link className="fw-700" target="new" to="//www.linkedin.com/company/sysmatch/" >
                            <Button btnClasses={'wh-35 h5 fw-600 green ml-0'}>
                                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                            </Button>
                        </ Link>
                        <Link className="fw-700" target="new" to="//www.instagram.com/sysmatch/" >
                            <Button btnClasses={'wh-35 h5 fw-600 green '}>
                                <FontAwesomeIcon icon={['fab', 'instagram']} />
                            </Button>
                        </ Link>
                        <Link className="fw-700" target="new" to="//www.facebook.com/SysMatch/" >
                            <Button btnClasses={'wh-35 h5 fw-600 green '}>
                                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                            </Button>
                        </ Link>
                        <Link className="fw-700" target="new" to="//www.youtube.com/channel/UCNlEH19mH7DI6c6s22zwUWg/" >
                            <Button btnClasses={'wh-35 h5 fw-600 green '}>
                                <FontAwesomeIcon icon={['fab', 'youtube']} />
                            </Button>
                        </ Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
);

export default VideoText;