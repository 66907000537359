export const isIE = () => (navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode);

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const elementsToJson = elms => {
    const _elms = rmDuplicatesElements(elms);
    let json = {};
    Object.keys(_elms).forEach((key) => {
        json[key] = key === 'terms' ? 'sim' : _elms[key].value;
    });
    return json;
}

export const rmDuplicatesElements = elms => {
    let _elements = { ...elms };
    Object.keys(_elements).forEach((key) => {
        if (!isNaN(parseInt(key)))
            delete _elements[key];
    }
    );
    if (!Object.keys(_elements).length) {
        elms.forEach(e => {
            _elements[e.name] = e;
        });
    }

    return _elements;
}

export const maxChar = (text, max) => {
    return text.substr(0, max) + ' (...)';
}

export const formatLink = (title) => {
    //title = title.replace(/[^a-zA-Z0-9 ]/g, '');
    title = title.replace('?', '');
    title = title.replace(/\s+/g, '-').toLowerCase();
    title = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return title
}

export const getLocale = (cookieLocale) => { //!vai para actions
    //get cookies
    return cookieLocale ? cookieLocale : localStorage.getItem('locale');
}
/**
 * Check route is a homepage
 * @param {*} route 
 * @param {*} i18n 
 */
export const isHomepage = (i18n = null) => { //! actions rotas
    const arr = getRouteKey(i18n);
    if (!arr)
        return false;

    let [route] = arr;
    if (Array.isArray(route))
        route = route[0];
    return route === 'homepage';
}

/**
 * return all routes by locale //!retorna todas as rotas de um dado locale {objeto}
 * @param {*} i18n 
 * @param {*} locale 
 */
export const getRoutesByLocale = (i18n, locale = null) => {
    locale = locale ? locale : i18n.language;
    const routes = i18n.getResource(locale, 'routes');
    return routes;
}

/**
 * Try to get route in all locales //!pesquisa a presença de uma path em todos os locales
 * @param {*} path 
 * @param {*} i18n 
 * Return route path or false
 */
export const getRoute = (path = null, i18n = null) => {
    if (!i18n)
        return false;

    let locale = i18n.language;
    //tenta pesquisar rota no locacle atual
    let route = getRouteByLocale(locale, path, i18n);

    //caso não encontre tenta procurar nas rotas dos restantes locales
    if (!route) {
        const langs = i18n.languages.filter((lang) => {
            return lang !== locale;
        });
        langs.forEach(lang => {
            const checkRoute = getRouteByLocale(lang, path, i18n);
            if (checkRoute)
                route = checkRoute.path;
        });
    }

    return route;
}

/**
 * Try get route by locale
 * @param {*} lang 
 * @param {*} path 
 * @param {*} i18n 
 * Return route path or false
 */
export const getRouteByLocale = (lang = null, path = null, i18n = null) => {
    path = path ? path : window.location.pathname;
    if (!i18n)
        return false;

    const routes = getRoutesByLocale(i18n, lang);
    for (let key in routes)
        if (routes[key] === path)
            return routes[key];

    return false;
}

/**
 * Try to get current or equal to var path in all locales  //!retorna um array com ['route-name': 'locale']
 * @param {*} i18n 
 * @param {*} path 
 * @param {*} locale 
 * @returns array [key,lang] or false
 */
export const getRouteKey = (i18n, path = null, locale = null) => {
    if (!i18n)
        return false;
    let routeKey = [getRouteKeyByLocale(i18n, locale, path)];
    locale = locale ? locale : i18n.language;
    if (!routeKey[0]) {
        const langs = i18n.languages.filter((lang) => {
            return lang !== locale;
        });
        langs.forEach(lang => {
            const key = getRouteKeyByLocale(i18n, lang);
            if (key)
                routeKey = [key, lang];
        });
    } else
        routeKey = [routeKey, locale];

    return routeKey;
}

/**
 * Try to get current or equal to var path by locale //!retorna a key relativa à path atual
 * @param {*} i18n 
 * @param {*} path 
 * @param {*} locale 
 * @returns array [key,lang] or false
 */
export const getRouteKeyByLocale = (i18n, locale = null, path = null) => {
    path = path ? path : window.location.pathname;
    let routeKey = false;

    if (!i18n)
        return false;

    const routes = getRoutesByLocale(i18n, locale);
    for (let key in routes) {
        let pathTemp = path;
        if (pathTemp.substring(pathTemp.length - 1) === '/' && pathTemp.length > 1) {
            pathTemp = pathTemp.substring(0, pathTemp.length - 1);
        }
        let pathTempCurrent = routes[key].path;

        if (pathTempCurrent.substring(pathTempCurrent.length - 1) === '/' && pathTempCurrent.length > 1) {
            pathTempCurrent = pathTempCurrent.substring(0, pathTempCurrent.length - 1);
        }
        if (pathTemp.split('/').length > 2) {
            pathTemp = "/" + pathTemp.split('/')[1] + "/:id";
        }

        if (routes[key].path === pathTemp) {
            routeKey = key;
        }
    }

    return routeKey;
}