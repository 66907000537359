import React from 'react';
import { Image } from 'react-bootstrap';

const IconTitleItem = props =>(
    <div className="iconTitleItem d-inline-flex py-3" >
        <div className="icon square-icon mr-4">
            <Image className="" src={props.img} />
        </div>
        <div className="title ml-3">
            <h3 className="h5 fw-700 black">{props.title}</h3>
        </div>
    </div>
)

export default IconTitleItem;