import React, { Component } from 'react';
import Banner from '../../../components/Products/ProjectManagement/ProjectManagementBanner/ProjectManagementbanner';
import ProjectManagement2 from '../../../components/Products/ProjectManagement/ProjectManagement2/ProjectManagement2';
import ProjectManagement3 from '../../../components/Products/ProjectManagement/ProjectManagement3/ProjectManagement3';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { withTranslation } from 'react-i18next';
import Head from '../../../components/Head/Head';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';
class ProjectManagement extends Component {
    render(){
        return(
        <Auxiliary >
            <Head id="project-management"/>
            <Banner {...this.props} />
            <ProjectManagement2 {...this.props} />
            <ProjectManagement3 {...this.props} />
            <CallToAction
                    color="light-green"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnTextProducts')}
                    product='projectManegement'
                    />
        </Auxiliary>
        )
    }
}

export default withTranslation()(ProjectManagement);