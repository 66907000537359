import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Transition from 'react-transition-group/Transition';

import Button from '../../UI/Button/Button';
import MenuModal from '../../UI/MenuModal/MenuModal';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import { updateObject } from '../../../shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as IconG } from '../../../assets/images/icon-globo.svg';
import { ReactComponent as IconM } from '../../../assets/images/icon-menu.svg';
import AccordionUI from '../../UI/AccordionUI/AccordionUI';
import reactDom from 'react-dom';
import { Image } from 'react-bootstrap';

import Instagram from "../../../assets/images/icon-Instagram.svg";
import InstagramOrange from "../../../assets/images/icon-Instagram-orange.svg";
import Facebook from "../../../assets/images/icon-Facebook.svg";
import FacebookOrange from "../../../assets/images/icon-Facebook-orange.svg";
import Linkedin from "../../../assets/images/icon-Linkedin.svg";
import LinkedinOrange from "../../../assets/images/icon-Linkedin-orange.svg";
import Youtube from "../../../assets/images/icon-youtube.svg";
import YoutubeOrange from "../../../assets/images/icon-youtube-orange.svg";
import Like from "../../../assets/images/icon-like.svg";
import LikeOrange from "../../../assets/images/icon-like-orange.svg";
const Menu = props => {
    const [instagramImage, setInstagramImage] = useState(Instagram);
    const [facebookImage, setFacebookImage] = useState(Facebook);
    const [linkedinImage, setLinkedinImage] = useState(Linkedin);
    const [youtubeImage, setYoutubeImage] = useState(Youtube);
    const [menu, setMenu] = useState({
        open: false,
        type: null,
        left: null,
        top: null,
        num: null
    });
    let stateAnimation = null;
    const nav = useRef();
    const acc1 = useRef();

    useEffect(() => {
        window.addEventListener('resize', () => closeMenuHandler());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const openMenuHandler = (type, num, menuTemp = null) => {
        const name = type ? type : menu.type;
        //const i = name === 'plus' ? 1 : name === 'locale' ? 3 : 0; 
        const i = name === 'plus' ? 0 : 1;
        let $menu = menuTemp !== null ? menuTemp : menu;
        if (num && num !== $menu.num && $menu.open) {
            $menu = updateObject($menu, { num });
            closeMenuHandler();
            setTimeout(function () {
                openMenuHandler(type, num, $menu);
            }, 500);
            return;
        } else if (num === 0 && $menu.open) {
            closeMenuHandler();
            return;
        }
        if (name) {
            setMenu(updateObject(menu, { open: true, type: name, left: nav.current.children[i].offsetLeft, top: nav.current.children[i].offsetTop, num }));
        }
    }
    const closeMenuHandler = () => {
        if (stateAnimation === 'entered')
            setMenu(updateObject(menu, { open: false }))
    }
    const localeButtons = props.i18n.languages.sort().reverse().map((language, i) => {
        return <li key={i}><button className={'p text-uppercase ' + (language === props.i18n.language ? 'active' : '')} onClick={() => {
            if (language !== props.i18n.language)
                window.scrollTo(0, 0);
            props.onRedirectTo(props.i18n, language, props.cookieLocale);
        }} >{language}</button></li>
    });

    useEffect(() => {
        if (menu.open && acc1.current) {
            var children = [].slice.call(reactDom.findDOMNode(acc1.current).getElementsByClassName('AccordionItemUI'))
            children.forEach(element => {
                if (reactDom.findDOMNode(element).getElementsByClassName('active').length)
                    element.classList.add('active');
                else
                    element.classList.remove('active');
            });
        }
    }, [menu]);

    const buttons = <Aux >
        <Button btnClasses={'p-s ml-5 h5 fw-600 ' + (menu.open && menu.type === 'plus' ? 'active' : '')} clicked={() => openMenuHandler('plus', 0)}>
            <IconM className="iconM" /></Button>
        <Button btnClasses={'p-s h5 fw-600 ' + (menu.open && menu.type === 'I' ? 'active' : '')} clicked={() => openMenuHandler('locale', 1)}><IconG className="iconG" /></Button>
    </Aux>;

    const [showLocaleMenu, setShowLocaleMenu] = useState(false)
    const [showSocialsMenu, setShowSocialsMenu] = useState(false)
    const [BtnPos, setBtnPos] = useState(false)
    const closeLocale = () => { setShowLocaleMenu(false) }

    useEffect(() => {
        let rect = document.getElementById("menu")
        let pos = rect.getBoundingClientRect()
        setBtnPos(pos);
    }, [document.getElementById("menu")])

    return (
        <nav ref={nav} className="d-flex justify-content-end align-items-center h-100">
            {/* new menu */}
            <div id='menu' className='menu  d-flex' onMouseLeave={() => { closeLocale() }}>
                <div className='socials d-none d-md-flex'>
                    <a className='button' href="https://www.linkedin.com/company/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setLinkedinImage(LinkedinOrange) }} onMouseOut={() => { setLinkedinImage(Linkedin) }}>
                        <Image fluid src={linkedinImage} />
                    </a>
                    <a className='button' href="https://www.instagram.com/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setInstagramImage(InstagramOrange) }} onMouseOut={() => { setInstagramImage(Instagram) }}>
                        <Image fluid src={instagramImage} />
                    </a>
                    <a className='button' href="https://www.facebook.com/SysMatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setFacebookImage(FacebookOrange) }} onMouseOut={() => { setFacebookImage(Facebook) }}>
                        <Image fluid src={facebookImage} />
                    </a>
                    <a className='button' href="https://www.youtube.com/channel/UCusRuLSE5ZvwiXjDvK7YuWg/" target="_blank" rel="noreferrer" onMouseOver={() => { setYoutubeImage(YoutubeOrange) }} onMouseOut={() => { setYoutubeImage(Youtube) }}>
                        <Image fluid src={youtubeImage} />
                    </a>
                </div>
                <div className='socials d-flex d-md-none'>
                    <div className={showSocialsMenu ? 'button like-icon active' : 'button like-icon '} onClick={() => { setShowSocialsMenu(!showSocialsMenu) }}>
                        {showSocialsMenu ? <Image fluid src={LikeOrange} /> : <Image fluid src={Like} />}

                    </div>
                    <div className={showSocialsMenu ? 'socials-menu show' : 'socials-menu'}>
                        <a className='button' href="https://www.linkedin.com/company/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setLinkedinImage(LinkedinOrange) }} onMouseOut={() => { setLinkedinImage(Linkedin) }}>
                            <Image fluid src={linkedinImage} />
                        </a>
                        <a className='button' href="https://www.instagram.com/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setInstagramImage(InstagramOrange) }} onMouseOut={() => { setInstagramImage(Instagram) }}>
                            <Image fluid src={instagramImage} />
                        </a>
                        <a className='button' href="https://www.facebook.com/SysMatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setFacebookImage(FacebookOrange) }} onMouseOut={() => { setFacebookImage(Facebook) }}>
                            <Image fluid src={facebookImage} />
                        </a>
                        <a className='button' href="https://www.youtube.com/channel/UCusRuLSE5ZvwiXjDvK7YuWg/" target="_blank" rel="noreferrer" onMouseOver={() => { setYoutubeImage(YoutubeOrange) }} onMouseOut={() => { setYoutubeImage(Youtube) }}>
                            <Image fluid src={youtubeImage} />
                        </a>
                    </div>
                </div>
                <Button btnClasses={'p-s h5 fw-600 ' + (menu.open && menu.type === 'plus' ? 'active' : '')} clicked={() => openMenuHandler('plus', 0)} >
                    <IconM className="iconM" />
                </Button>
                <div className='locale-container'>
                    <Button btnClasses={showLocaleMenu ? 'p-s m-0 h5 fw-600 active globe' : 'p-s m-0 h5 fw-600 globe'} clicked={() => setShowLocaleMenu(!showLocaleMenu)}>
                        <IconG className="iconG" />
                    </Button>
                    <div className={showLocaleMenu ? 'locale-menu show' : 'locale-menu'}>
                        <ul >
                            {localeButtons}
                        </ul>
                    </div>
                </div>
            </div>
            {/* {buttons} */}
            <Transition
                in={menu.open}
                timeout={500}
                mountOnEnter
                unmountOnExit>
                {state => {
                    const showModal = state === 'entered' ? 'show' : '';
                    const timeSetStateAnimation = state === 'entered' ? 500 : 0;
                    setTimeout(function () {
                        stateAnimation = state;
                    }, timeSetStateAnimation)
                    switch (menu.type) {
                        case 'plus': return (
                            <Aux>
                                <Backdrop className='animation-right ' show={showModal} clicked={closeMenuHandler} />
                                <MenuModal show={showModal} closed={closeMenuHandler}>
                                    <div className={showModal ? "buttons show" : "buttons"} >
                                        <div className='menu-modal d-flex' style={{ top: BtnPos.top + "px", right: "5%" }} onMouseLeave={() => { closeLocale() }}>
                                            <Button btnClasses={'p-s h5 fw-600 ' + (menu.open && menu.type === 'plus' ? 'active' : '')} clicked={() => openMenuHandler('plus', 0)} >
                                                <IconM className="iconM" />
                                            </Button>
                                            <div className='locale-container'>
                                                <Button btnClasses={showLocaleMenu ? 'p-s m-0 h5 fw-600 active globe' : 'p-s m-0 h5 fw-600 globe'} clicked={() => setShowLocaleMenu(!showLocaleMenu)}>
                                                    <IconG className="iconG" />
                                                </Button>
                                                <div className={showLocaleMenu ? 'locale-menu show' : 'locale-menu'}>
                                                    <ul >
                                                        {localeButtons}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul>
                                        <AccordionUI _ref={acc1}>
                                            <Aux title={props.t('partials:link-menu.services')}>
                                                <Aux>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:consulting.path') + '#top'} >{props.t('partials:link-labels.consulting')}</ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:development.path') + '#top'} >{props.t('partials:link-labels.development')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:outsourcing.path') + '#top'} >{props.t('partials:link-labels.outsourcing')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:quality.path') + '#top'} >{props.t('partials:link-labels.quality')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:rgpc.path') + '#top'} >{props.t('partials:link-labels.rgpc')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:nearshoring.path') + '#top'} >{props.t('partials:link-labels.nearshoring')} </ NavLink>
                                                    </li>
                                                </Aux>
                                            </Aux>
                                            <Aux title={props.t('partials:link-menu.produts')}>
                                                <Aux>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:attendenceManagement.path') + '#top'} >{props.t('partials:link-labels.attendence-management')}</ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:projectManagement.path') + '#top'} >{props.t('partials:link-labels.project-management')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:meetingRoom.path') + '#top'} >{props.t('partials:link-labels.meeting-room')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:shareholderMeeting.path') + '#top'} >{props.t('partials:link-labels.shareholder-meeting')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:creditManagement.path') + '#top'} >{props.t('partials:link-labels.credit-management')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:comercialPaper.path') + '#top'} >{props.t('partials:link-labels.comercial-paper')} </ NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink className="p fw-600 text-uppercase" onClick={() => closeMenuHandler()} to={props.t('routes:billingSoftware.path') + '#top'} >{props.t('partials:link-labels.billing-software')} </ NavLink>
                                                    </li>
                                                </Aux>
                                            </Aux>
                                        </AccordionUI>
                                        <li>
                                            <NavLink className="h5 fw-600 text-uppercase d-flex align-items-center flex-row" onClick={() => closeMenuHandler()} to={props.t('routes:jobs.path') + '#top'} >
                                                <Button btnClasses="wh-35 h5 fw-600 ml-0 arrow black">
                                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                                </Button>
                                                <h3 className="h5 fw-600 m-0">{props.t('partials:link-labels.jobs')}</h3>
                                            </ NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="h5 fw-600 text-uppercase d-flex align-items-center flex-row" onClick={() => closeMenuHandler()} to={props.t('routes:blog.path') + '#top'} >
                                                <Button btnClasses="wh-35 h5 fw-600 ml-0 arrow black">
                                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                                </Button>
                                                <h3 className="h5 fw-600 m-0">{props.t('partials:link-labels.blog')}</h3>
                                            </ NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="h5 fw-600 text-uppercase d-flex align-items-center flex-row" onClick={() => closeMenuHandler()} to={props.t('routes:contacts.path') + '#top'} >
                                                <Button btnClasses="wh-35 h5 fw-600 ml-0 arrow black">
                                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                                </Button>
                                                <h3 className="h5 fw-600 m-0">{props.t('partials:link-labels.contacts')}</h3>
                                            </ NavLink>
                                        </li>
                                        {/* <li className="icons my-4 py-5">
                                            <NavLink className="fw-700" target="new" to="//www.linkedin.com/company/sysmatch/" >
                                                <Button btnClasses={'p-s h5 fw-600 ml-0'}>
                                                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                                                </Button>
                                            </ NavLink>
                                            <NavLink className="fw-700" target="new" to="//www.instagram.com/sysmatch/" >
                                                <Button btnClasses={'p-s h5 fw-600 '}>
                                                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                                                </Button>
                                            </ NavLink>
                                            <NavLink className="fw-700" target="new" to="//www.facebook.com/SysMatch/" >
                                                <Button btnClasses={'p-s h5 fw-600 '}>
                                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                                </Button>
                                            </ NavLink>
                                            <NavLink className="fw-700" target="new" to="//www.youtube.com/channel/UCusRuLSE5ZvwiXjDvK7YuWg/" >
                                                <Button btnClasses={'p-s h5 fw-600 '}>
                                                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                                                </Button>
                                            </ NavLink>
                                        </li> */}
                                    </ul>
                                </MenuModal>
                            </ Aux>
                        );
                        default: return null;
                    }

                }}
            </Transition>


        </nav >
    );
};

export default React.memo(Menu);