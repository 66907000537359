import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { getLocale, getRouteKey, getRouteKeyByLocale, isHomepage } from '../../shared/utility';
import Aux from '../Auxiliary/Auxiliary';

//Midleware to check locale
const langRouter = (WrappedComponent) => {  
    return withTranslation('routes')(
        
        class extends Component{
            
            constructor(props){
                super(props);
                this.checkChangeLocale();
            }

            componentDidMount(){
                this.props.onTrySetJobs();
            }

            //!verifica se necessita de alterar a linguagem através da rota introduzida
            checkChangeLocale = () => {
                const i18n = this.props.i18n;
                //wait for cookies
                new Promise((resolve) =>{
                    resolve( this.props.oninitStateCookies( this.props.cookies , this.props.locale ) );
                }).then(()=>{
                    let locale = getLocale(this.props.cookieLocale);

                    let route = getRouteKeyByLocale( i18n , locale  ); //!retorna a key relativa à path atual
                    let isHome = isHomepage( i18n ) && navigator.userAgent !== 'ReactSnap';

                    this.props.onTryAutoSetLocale( this.props.i18n , locale , this.props.cookies , isHome );
                    
                    if ( !isHome && !route ){
                        locale = getRouteKey(i18n)[1];
    
                        if( locale )
                            this.props.onTrySetLocale( locale , i18n , this.props.cookies );
                    }

                    });
            }
                       
            render(){
                return (
                    <Aux>
                        <WrappedComponent {...this.props}  />
                    </Aux>
                )
            }
        }
    )
}

export default langRouter;