import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from '../Carousel/Carousel';
import BlogItemUI from './BlogItemUI';

//build de 2 sections of blog page (banner and section 2)
const BlogUI = props => {
    const blogItens = props.children.map((item,i) =>
        props.id.includes('blogBanner') ? <BlogItemUI key={i} {...item.props}/> 
        : <Col key={i} md={6} lg={4} ><BlogItemUI className="bg" {...item.props} /></Col>);
    return (
        <div className={'blogUI' + (props.className ? props.className : '')}>
            {props.id.includes('blogBanner') ?
                <Carousel>
                    {blogItens}
                </Carousel>
                :   <Container>
                        <Row className="align-items-stretch pb-5"> 
                            {blogItens} 
                        </Row>
                    </Container> 
            }
        </div>
    );
}

export default BlogUI;