import React from 'react';
import {Container, Col} from 'react-bootstrap';
import Button  from '../Button/Button';
import { Link } from 'react-router-dom';
import {Trans} from 'react-i18next';

const PolicyPage = (props) => {
    const text = props.i18n.getResource(props.i18n.language, props.page);
    const keys = Object.keys(text);
    const content = keys.map( (key) =>{
        const k = key.split('-');
        switch(k[0]){
            case 'text':
                return <p> <Trans  
                t={props.t}  
                i18nKey={text[key]} 
                components={{ 
                    homeLink: <Link to={props.t('routes:homepage.path')+'#top'} className="fw-600 black"/>,
                    email: <Link to="#" onClick={(e)=> {
                                e.preventDefault();
                                window.location = 'mailto:dpo@sysmatch.com';
                                }
                            } className="fw-600 black"/>,  
                }}/></p>
            case 'subtitle': 
                return <h2 className="black p fw-700 text-uppercase mt-5" key={props.key}>{text[key]}</h2>;
            case 'list': 
                const itens = Object.values(text[key]);
                return <ul className="p list-unstyled">{itens.map((item) => <li>
                        <Trans 
                            t={props.t}  
                            i18nKey={item} 
                            components={{ 
                                email: <Link to="#" onClick={(e)=> {
                                    e.preventDefault();
                                    window.location = 'mailto:dpo@sysmatch.com';
                                    }
                                }  className="fw-600 black"/>,  
                                emailJoana: <Link to="#" onClick={(e)=> {
                                    e.preventDefault();
                                    window.location = 'mailto:joana.agria@sysmatch.com';
                                    }
                                }  className="fw-600 black"/>,  
                            }}
                        /></li> )}</ul>;
            case 'btn': 
                return <Button clicked ={() => props.changeConsent()} key={props.key} btnClasses="mx-0 mt-4 p-l h4 fw-600 text-uppercase">{text[key]}</Button>;
            default: return null;
        }
    });
    return(
        <section id= {""+props.page+"-banner"} className="my py-5 policy-page">
            <Container>
                    <Col xs={12}>
                        <div className="title-triangle top-md"></div>
                        <h1 className="h4 fw-700 text-uppercase mb-5">{text["title"]}</h1>
                    </Col>
            </Container>
            <div className="light-silver-bg">
                <Container className="mt-0">
                    {content}
                </Container>
            </div>
        </section>
    )
};

export default PolicyPage;