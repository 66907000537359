import React from 'react';
import {Container} from 'react-bootstrap';
import AccordionUI from '../../UI/AccordionUI/AccordionUI';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

const Jobs7 = props =>(
    <section
        id="jobs-section7"
        className="my py-5">
        <Container className="position-relative">
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('jobs:section-7.title')}</h2>
            <AccordionUI className="bg p-4 p-sm-5" {...props}>
                <Auxiliary title={props.t('jobs:section-7.subtitle1')} text={<Trans t={props.t} i18nKey={'jobs:section-7.text1'} components={{ 
                                    linkForm: <Link to="#jobs-form" />,
                                    linkJobs: <Link to='#section-jobs' />  
                                } }/>}/>
                <Auxiliary title={props.t('jobs:section-7.subtitle2')} text={<Trans t={props.t} i18nKey={'jobs:section-7.text2'} /> }  />
                <Auxiliary title={props.t('jobs:section-7.subtitle3')} text={<Trans t={props.t} i18nKey={'jobs:section-7.text3'} /> }  />
                <Auxiliary title={props.t('jobs:section-7.subtitle4')} text={<Trans t={props.t} i18nKey={'jobs:section-7.text4'}  /> }  />
            </AccordionUI>
            </Container>
    </section>
);

export default Jobs7;