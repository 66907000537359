import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import IconTitleItem from '../IconTitle/IconTitleItem';
import Button from '../Button/Button';

const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;
    return (
        <div
            className={isCurrentEventKey ? 'active d-inline' : ' d-inline'}
            onClick={decoratedOnClick}
        >
            {children}
        </div>
    );
}

const AccordionItemUI = props => (
    <div className="AccordionItemUI">
        <ContextAwareToggle eventKey={props.eventKey}>
            {props.img ?
                <IconTitleItem {...props} />
                : <div className="click d-inline-flex align-items-center flex-row py-3 py-md-4">
                    <Button btnClasses="wh-35 h5 fw-600 ml-0 arrow black">
                        <FontAwesomeIcon icon={['fas', 'arrow-down']} />
                    </Button>
                    <h3 className="h5 fw-600 m-0">{props.title}</h3>
                </div>}
        </ContextAwareToggle>
        <Accordion.Collapse eventKey={props.eventKey}>
            <Auxiliary>
                {props.text && <p className="m-0 pb-3 pb-md-4">{props.text} {props.link && <Link to={props.link}>{props.linkText}.</Link>}</p>}

                {props.children && props.children}
            </Auxiliary>
        </Accordion.Collapse>

    </div>
);

export default AccordionItemUI;