import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/products-banner/Software-Gestao-Assiduidade.png"; 
import bannerImageWebp from "../../../../assets/images/products-banner/Software-Gestao-Assiduidade.webp";

const AttendenceManagementBanner = props => (
        <Banner 
            id="attendenceManagement-banner" 
            className="banner black pb-5 products-banner" 
            title="products:attendenceManagement:section-1.title"
            text="products:attendenceManagement:section-1.txt"
            t={props.t} 
            imageBannerRight={bannerImage}
            imageBannerRightWebp={bannerImageWebp}
            timeDelay={1.55}  
        />
);

export default AttendenceManagementBanner;