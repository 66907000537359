import React from 'react';

const button = (props) => {
    const classesButton = props.btnClasses ? 'button ' + props.btnClasses : 'button';
    return (
        <button
            id={props.id}
            ref={props._ref}
            className={classesButton}
            onClick={props.clicked}
            onMouseEnter={props.hover}
            onMouseLeave={props.leave}
            disabled={props.disabled}
            data-tip={props.tip}
            data-place={props.tip && "top"}
            data-for={props.for}
            type={props.type}>
            {props.children}
        </button>
    )
};

export default button;