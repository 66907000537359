import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/services-banner/Nearshoring_.png";
import bannerImageWebpp from "../../../../assets/images/services-banner/Nearshoring_.webp";

const nearshoringBanner = (props) =>(
    <Banner 
        id="nearshoring-banner" 
        className="banner black pb-5 services-banner" 
        title="services:nearshoring:section-1.title"
        text="services:nearshoring:section-1.txt"
        t={props.t}
        imageBannerRight={bannerImage} 
        imageBannerRightWebp={bannerImageWebpp} 
        timeDelay={1.55} 
    />
);

export default nearshoringBanner;