import React, { Component } from 'react';
//import { withCookies } from 'react-cookie';
import * as actions from '../../../store/actions/index';
import {connect}  from 'react-redux';
import { withTranslation } from 'react-i18next';
import Banner from '../../../components/UI/PolicyPage/PolicyPage';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Head from '../../../components/Head/Head';

class Cookies extends Component {

    render(){
        return (
            <Auxiliary>
                <Head id="cookies" />
                <Banner 
                {...this.props}
                page="cookies"
                changeConsent = {() => this.props.showBanner()}/>
            </Auxiliary>
        );
    }
}

const mapStateProps = (state, ownProps) => {
    return({
        cookies: ownProps.cookies,
        // localeCookie: state.cookies.locale,
        // acceptedCookie: state.cookies.accepted,
        // localeOnState: state.lang.locale
      });
}

const mapDispacthToProps = dispatch => {
   
    return{
        // onHandleSetCookie: (cookies, name, value, localeOnState) => dispatch(actions.setCookie(cookies, name, value, localeOnState)),
        // onHandleRemoveCookie: (cookies, name) => dispatch(actions.removeCookie(cookies, name)),
        onHandleRemoveAllCookies: (cookies) => dispatch(actions.removeAllCookies(cookies)),
    }
}

export default connect(mapStateProps, mapDispacthToProps)(withTranslation()(Cookies));