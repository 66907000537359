import React from 'react';
import { Trans } from 'react-i18next';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';
import Button from '../../UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

const Modal = props => {
    const { closed, modalId, modals, t } = props;
    const keys = modals && modals[modalId] && Object.keys(modals[modalId]);

    let content = keys && modalId && keys.map((key) => {
        const k = key.split('-');
        switch (k[0]) {
            case 'title':
                return <h2 className="black h4 fw-700 text-uppercase mb-5" key={key}>{modals[modalId][key]}</h2>;
            case 'txt':
                return (
                    <p key={key}>
                        <Trans
                            key={key}
                            t={t}
                            i18nKey={'modals:' + modalId + '.' + key}
                            components={{
                                LinkClose: <Link to="#" className="green" onClick={() => closed()} />,
                            }}
                        />
                    </p>
                );
            case 'subTitle': return <h3 className="black h5 fw-700 text-uppercase mt-5" key={key}>{modals[modalId][key]}</h3>;
            case 'img':
                const img = require('../../../assets/images/modals/' + modals[modalId][key]);
                return <img className="img-fluid mb-5" key={key} src={img} alt="" />;
            case 'ul':
                const itens = Object.values(modals[modalId][key]);
                return <ul key={key} className="p">{itens.map((item, key) => <li key={key}>{item}</li>)}</ul>;
            default: return null;
        }
    });

    return (
        <Auxiliary>
            <Backdrop className={modalId ? ' modalBackdrop' : 'd-none'} clicked={closed} />
            {modalId &&
                <div className="modal">
                    <Button btnClasses="fw-600" clicked={props.closed}><FontAwesomeIcon icon={['fas', 'times']} /></Button>
                    {content}
                </div>
            }
        </Auxiliary>
    );
}

export default Modal;