import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Jobs2 from '../../components/Jobs/Jobs2/Jobs2';
import Jobs3 from '../../components/Jobs/Jobs3/Jobs3';
import ListJobs from '../../components/Jobs/listJobs/listJobs';
import JobsBanner from '../../components/Jobs/JobsBanner/JobsBanner';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Jobs5 from '../../components/Jobs/Jobs5/Jobs5';
import JobsForm from '../../components/Jobs/JobsForm/JobsForm';
import Jobs7 from '../../components/Jobs/Jobs7/Jobs7';
import Jobs8 from '../../components/Jobs/Jobs8/Jobs8';
import Jobs9 from '../../components/Jobs/Jobs9/Jobs9';
import Head from '../../components/Head/Head';

class Jobs extends Component {
    state = {
        jobTitle: null
    }

    render() {
        return (
            <Auxiliary>
                <Head id="jobs" />
                <JobsBanner {...this.props} />
                <Jobs2 {...this.props} />
                <Jobs3 {...this.props} />
                <ListJobs setJobTitle={(jobTitle) => this.setState({ jobTitle })} {...this.props} />
                {<Jobs5 {...this.props} />}
                {/* <JobsForm jobTitle={this.state.jobTitle} rmJobTitle={() => this.setState({ jobTitle: null })} {...this.props}  /> */}
                <Jobs7 {...this.props} />
                <Jobs8 {...this.props} />
                <Jobs9 {...this.props} />
            </Auxiliary>
        );
    }
}

export default withTranslation()(Jobs);