import React, { Component } from 'react';
import Banner from '../../../components/Products/AttendenceManagement/AttendenceManagementBanner/AttendenceManagementBanner';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import AttendenceManagement2 from '../../../components/Products/AttendenceManagement/AttendenceManagement2/AttendenceManagement2';
import AttendenceManagement3 from '../../../components/Products/AttendenceManagement/AttendenceManagement3/AttendenceManagement3';
import AttendenceManagement4 from '../../../components/Products/AttendenceManagement/AttendenceManagement4/AttendenceManagement4';
import { withTranslation } from 'react-i18next';
import Head from '../../../components/Head/Head';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';
class AttendenceManagement extends Component {
    render(){
        return(
            <Auxiliary>
                <Head id="attendence-management"/>
                <Banner {...this.props} />
                <AttendenceManagement2 {...this.props} />
                <AttendenceManagement3 {...this.props} />
                <AttendenceManagement4 {...this.props} />
                <CallToAction
                    color="light-green"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnTextProducts')}
                    product='perceptum'
                    />
            </Auxiliary>
            );
    }
}

export default withTranslation()(AttendenceManagement);