import React from 'react';
import { Col, Container, Row , Image } from 'react-bootstrap';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import TitleText from '../../UI/TitleText/TitleText';
import img from '../../../assets/images/jobs/section-3/3-tracejado.svg';

const Jobs3 = props =>(
    <section id="jobs-section3" className="my p-t">
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="title-triangle top-md"></div>
                    <h2 className="h4 black fw-600">{props.t('jobs:section3.title')}</h2>
                    <TitleText>
                        <Auxiliary title={props.t('jobs:section3.1.title')} text={props.t('jobs:section3.1.text')} />
                        <Auxiliary title={props.t('jobs:section3.2.title')} text={props.t('jobs:section3.2.text')} />
                        <Auxiliary title={props.t('jobs:section3.3.title')} text={props.t('jobs:section3.3.text')} />
                        <Auxiliary title={props.t('jobs:section3.4.title')} text={props.t('jobs:section3.4.text')} />
                        <Auxiliary title={props.t('jobs:section3.5.title')} text={props.t('jobs:section3.5.text')} />
                        <Auxiliary title={props.t('jobs:section3.6.title')} text={props.t('jobs:section3.6.text')} />
                        <Auxiliary title={props.t('jobs:section3.7.title')} text={props.t('jobs:section3.7.text')} />
                        <Auxiliary title={props.t('jobs:section3.8.title')} text={props.t('jobs:section3.8.text')} />
                    </TitleText>
                </Col>
            </Row>
            <Image className="img-dashed d-none d-lg-block" src={img} />

        </Container>
    </section>
);

export default Jobs3;