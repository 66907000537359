import React, { useEffect, useState } from 'react';
import moldura from '../../../assets/images/team/Moldura.svg';

const TeamItem = props => {
    const [quote, setQuote] = useState(props.quote1);
    const [quoteI, setQuoteI] = useState(1);

    useEffect(() => {
        if (quoteI === 1)
            setQuote(props.quote1);
        else
            setQuote(props.quote2);
    }, [props]);

    return (
        <div className='teamItem'>
            <div className="teamItem-content">
                <div className="identification">
                    <h5 className="p white">{props.name}</h5>
                    <p className="white">{props.position}</p>
                </div>
                <div className="colaborador">
                    <picture>
                        {props.src1Webp && <source srcSet={props.src1Webp} type="image/webp" />}
                        <source src={props.src1} type="image/jpeg" />
                        <img src={props.src1} alt={props.alt}
                            className="mb-4"
                            onMouseOver={(e) => {
                                e.target.classList.add('d-none');
                                e.target.parentNode.nextElementSibling.lastChild.classList.remove('d-none');
                                setQuote(props.quote2);
                                setQuoteI(2);
                            }}
                        />
                    </picture>
                    <picture>
                        {props.src2Webp && <source srcSet={props.src2Webp} type="image/webp" />}
                        <source src={props.src2} type="image/jpeg" />
                        <img src={props.src2} alt={props.alt}
                            className="mb-4 d-none"
                            onMouseOut={(e) => {
                                e.target.classList.add('d-none');
                                e.target.parentNode.previousElementSibling.lastChild.classList.remove('d-none');
                                setQuote(props.quote1);
                                setQuoteI(1);
                            }}
                        />
                    </picture>
                </div>
            </div>
            <div className="info">
                <h3 className="p text-left ">{quote}</h3>
                {props.email ? <a href={'mailto:' + props.email} className="text-left email dark-green my-3">{props.email}</a> : null}
            </div>
        </div>
    );
}


export default TeamItem;