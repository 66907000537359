import React from 'react';
import auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import DashedSection from '../../../UI/DashedSection/DashedSection';
import step1 from "../../../../assets/images/development/section-4/reuniao.svg";
import step2 from "../../../../assets/images/development/section-4/definicao-objetivos.svg";
import step3 from "../../../../assets/images/development/section-4/desenvolvimento.svg";
import step4 from "../../../../assets/images/development/section-4/implementacao.svg";
import step5 from "../../../../assets/images/development/section-4/testes.svg";

const development4 = props => (
    <DashedSection
      id="development-section4"
      title={props.t('services:development:section-4.title')}>
        <auxiliary
            number={1}
            title={props.t('services:development:section-4.1')} 
            img={step1} />
        <auxiliary
            number={2}
            title={props.t('services:development:section-4.2')} 
            img={step2} />
        <auxiliary
            number={3}
            title={props.t('services:development:section-4.3')} 
            img={step3} />
        <auxiliary
            number={4}
            title={props.t('services:development:section-4.4')} 
            img={step4}/>
        <auxiliary
            number={5}
            title={props.t('services:development:section-4.5')} 
            img={step5} />
    </DashedSection>
);


export default development4;