import React from 'react';
import Button  from '../UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const iconFooter = props => (
    <a href={props.to} className="iconFooter" target="blank">
        <Button btnClasses={'green'} >
            <FontAwesomeIcon icon={props.icon} />  
        </Button>
    </a>
)

export default iconFooter;