/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Checkbox from '../../UI/Checkbox/Checkbox';
import FormField from '../../UI/FormField/FormField';
import Button from '../../UI/Button/Button';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { elementsToJson, isIE, maxChar } from '../../../shared/utility';
import FormModal from '../../UI/FormModal/FormModal';
import { rmDuplicatesElements } from '../../../shared/utility';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const JobsForm = props =>{
    const [checkTerms, setCheckTerms] = useState(true);
    const [checked, setChecked] = useState(false);
    const [ oldJobTitle, setOldJobTitle ] = useState(null);
    const [ elements , setElements ] = useState(null);
    const [ modalForm , setModalForm  ] = useState({
        title: null,
        text: null,
        btn: null
    });

    const [ cvTextBtn , setCvTextBtn ] = useState(props.t('jobs:section-6.btn1'));
    const [ cvError , setCvError ] = useState(null);
    const refBtnCv = useRef();
    const refInputCv = useRef();
    const refBtnSubmit = useRef();

    const {link_bucket, email} = props;
    
    const checkForm = (e) => {
        if(e.target.form.checkValidity()){
            submitHandler(e);
        }else{
            let scroll = true;
            
            const _elements = Object.entries(e.target.form.elements);
            _elements.forEach((obj) =>{
                if ( !isNaN( parseInt(obj[0]) && !obj[1].checkValidity() ) ){
                    const event = new Event('input',{
                        bubbles: true,
                        cancelable: true,
                    });
                    obj[1].dispatchEvent(event);
                    if ( scroll && obj[0] !== "0"  ){
                        scroll = false;
                        obj[1].scrollIntoView({
                            behavior: 'smooth', 
                            block: 'nearest', 
                            inline: 'nearest' 
                        });
                    }
                }
            });
            checkCv();
        }
    }

    const checkCv = () => {
        if ( !refInputCv.current.files[0] ){
            setCvTextBtn('Upload de CV');
            refInputCv.current.value = null;
            setCvError(true);
            return;
        }

        if(!/pdf|png|jpg|jpeg/.test(refInputCv.current.files[0].type) || refInputCv.current.files[0].size > 5242880){
            refInputCv.current.value = "";
            setCvError( 1 );
            setCvTextBtn('Upload de CV');
        }else{
            setCvTextBtn( maxChar( refInputCv.current.files[0].name , 15 ) );
            setCvError(false);
        }
        refBtnCv.current.classList.remove('loading');

    }

    const clickCv = () => {
        setCvError(null);
        if ( isIE() ){
            refBtnCv.current.classList.add('loading');
        }else{
            setTimeout(
                () => refBtnCv.current.classList.add('loading'),
                500
            );
        }
        refInputCv.current.click();
    }

    const submitHandler = (event) =>{
        event.preventDefault();
        if( !event.target.form.elements.terms.checked){
            if ( checkTerms ){
                //openModal X
                setModalForm(
                {
                    title: props.t('jobs:section-6.rgpd.title'),
                    text: <Trans t={props.t} i18nKey="jobs:section-6.rgpd.text" components={{linkMail: <Link to="mailto:emprego@sysmatch.com" />}} />,
                    btn: {
                        checkTo: props.t('routes:privacy.path'), 
                        checkText: props.t('jobs:section-6.terms'),
                        text: props.t('jobs:section-6.btn2'),
                        clicked: (e) => refBtnSubmit.current.click()                 
                    }
                });
                setCheckTerms(false);

            }else{
                //openModal Y
                setCheckTerms(true);
                refBtnSubmit.current.disabled =  true;
                refBtnSubmit.current.classList.add('loading');
                setTimeout(() => {
                    setModalForm({
                        title: props.t('jobs:section-6.errorRgpd.title'),
                        text: <Trans t={props.t} i18nKey="jobs:section-6.errorRgpd.text" 
                                components={{linkMail: <Link to="#"  onClick={(e)=> {
                                                e.preventDefault();
                                                window.location = 'mailto:emprego@sysmatch.com';
                                                }
                                } />}}  /> 
                    });
                    refBtnSubmit.current.disabled =  false;
                    refBtnSubmit.current.classList.remove('loading');
                },5000);
            }
            return;
        }

        if ( event.target.form.elements.nameH && event.target.form.elements.nameH.value !== '' ){
            setModalForm({ 
                title: props.t('jobs:section-6.errorSend.title'), 
                text: props.t('jobs:section-6.errorSend.txt') 
            });
            return;
        }
        const _elements = rmDuplicatesElements(event.target.form.elements);
        if ( _elements.btnCv )
            delete _elements.btnCv;
        if ( _elements.btnSubmit )
            delete _elements.btnSubmit;
        if ( _elements.nameH )
            delete _elements.nameH;

        setElements(_elements);

        refBtnSubmit.current.disabled =  true;
        refBtnSubmit.current.classList.add('loading');

    }

    useEffect(() => {
       
        if (  elements && elements.cv && elements.cv.files.length ){
            const file = elements.cv.files[0];
            props.ontryGetLinkbucket(file);
        }else if ( elements !== null ) {
            setModalForm({ 
                title: props.t('jobs:section-6.errorSend.title'), 
                text: props.t('jobs:section-6.errorSend.txt') 
            });
            refBtnSubmit.current.disabled =  false;
            refBtnSubmit.current.classList.remove('loading');
        }
    }, [elements])

    useEffect(() => {
        if(link_bucket){
            let _elm = elements;
            _elm = elementsToJson(_elm);
            _elm.cv = link_bucket;
            if ( !_elm.idOffer ) 
                _elm.idOffer = '';
            //send email
            props.onTryPostEmail('offer',_elm);
        }
        else if(link_bucket === false){
            setModalForm({ 
                title: props.t('jobs:section-6.errorSend.title'), 
                text: props.t('jobs:section-6.errorSend.txt') 
            });
            props.onEmailInit();
            refBtnSubmit.current.disabled =  false;
            refBtnSubmit.current.classList.remove('loading');    
        }
    }, [link_bucket]);

    useEffect(() => {
            
        if (email){
            setModalForm({
                title: props.t('jobs:section-6.sucessSend.title'),
                text: props.jobTitle ? props.t('jobs:section-6.sucessSend.withOffer') : props.t('jobs:section-6.sucessSend.withoutOffer'),
            });     
            props.onEmailInit();
            refBtnSubmit.current.classList.remove('loading');
        }else if( email === false ) {
            setModalForm({ 
                title: props.t('jobs:section-6.errorSend.title'), 
                text: props.t('jobs:section-6.errorSend.txt') 
            });

            props.onEmailInit();
            refBtnSubmit.current.disabled =  false;
            refBtnSubmit.current.classList.remove('loading');    
        }
    }, [email]);


    useEffect(() => {
        if ( props.jobTitle !== oldJobTitle ){
            setOldJobTitle( props.jobTitle );
            refBtnSubmit.current.disabled =  false;
        }
    },[props.jobTitle]);
    return(
        <section id="jobs-form" className={"my-4 py-4 my-md-5 py-md-5" + (modalForm.title ? ' openModal':'') }>
            <FormModal 
                id='jobsModal' 
                title={modalForm.title} 
                text={modalForm.text} 
                checked={checked} 
                btn={modalForm.btn} 
                closed={() => setModalForm({title:null,text:null})}  />
            <Container>
                <h2 className="h4 white fw-600 ml-5 pl-md-5 mb-5">
                    <div className="title-triangle top-lg black"></div>
                    {props.t('jobs:section-6.title1')} <br></br>{props.t('jobs:section-6.title2')}
                </h2> 
                <form action="POST" onSubmit={submitHandler} >
                    <input type="text" name="nameH" id="nameH" className="d-none" autoComplete="off" />
                    {props.jobTitle &&
                    <Row>
                        <Col>
                            <Col className="text-center">
                                <p className="fw-600">{props.jobTitle}</p>
                                <input type="hidden" name="idOffer" value={props.jobTitle} />
                            </Col>
                        </Col>
                    </Row>}
                    <Row>
                        <Col md={6}>
                            <FormField type="text" name="name" minLength={3} title={props.t('jobs:section-6.input1')} required errorMsg={props.t('jobs:section-6.error')} requiredMsg={props.t('jobs:section-6.errorRequired')} />
                            <Row>
                                <Col md={6} className="pr.md-0">
                                    <FormField type="text" name="phone" minLength={9} pattern="^[+\d](?:.*\d)?$" title={props.t('jobs:section-6.input3')}  errorMsg={props.t('jobs:section-6.errorPhone')} requiredMsg={props.t('jobs:section-6.errorRequired')} />
                                </Col>
                                <Col md={6} className="pl-md-0"> 
                                    <FormField type="text" name="linkedin" minLength={3} title={props.t('jobs:section-6.input4')}  errorMsg={props.t('jobs:section-6.error')} requiredMsg={props.t('jobs:section-6.errorRequired')} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <FormField type="email" name="email" placeholder={props.t('contacts:section-1.form.emailPlaceholder')} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title={props.t('jobs:section-6.input2')} required errorMsg={props.t('jobs:section-6.errorMail')} requiredMsg={props.t('jobs:section-6.errorRequired')} />
                            <FormField type="text" name="to-us" minLength={3} title={props.t('jobs:section-6.input5')} errorMsg={props.t('jobs:section-6.error')} requiredMsg={props.t('jobs:section-6.errorRequired')} />

                        </Col>
                        <Col md={12}>
                            <FormField _ref={refInputCv} id="cv" type="file" name="cv" title={props.t('jobs:section-6.input6')} errorMsg={props.t('jobs:section-6.error')} onChange={(e) => checkCv(e)} accept=".jpg, .jpeg, .png,.pdf" required/>
                            <Col>
                                <Button _ref={refBtnCv} id="btnCv" clicked={(e) => clickCv(e) } type="button" btnClasses={"mt-4 mb-5 p-l ml-0 h5 fw-600 dark-silver" + ( cvError ? ' error' : '' ) }>{cvTextBtn}</Button>
                                {cvError && <p className="errorMsg red">{cvError === 1 ? props.t('jobs:section-6.errorFileCv') : props.t('jobs:section-6.errorRequired')}</ p>}
                            </Col>
                        </Col>
                        <Col md={12}>
                            <FormField type="textarea" name="message" minLength={10} title={props.t('jobs:section-6.input7')} errorMsg={props.t('jobs:section-6.error')} requiredMsg={props.t('jobs:section-6.errorRequired')} />
                            <Checkbox id="terms" name="terms" link={props.t('routes:personal-data.path')} text={props.t('jobs:section-6.terms')} onChange={(e)=> setChecked(e.target.checked)} />
                            <Button id="btnSubmit" type="button" _ref={refBtnSubmit} clicked={(e) => checkForm(e)} btnClasses="float-lg-right my-4 mt-lg-5 p-l h5 fw-600 text-uppercase">{props.t('jobs:section-6.btn2')}</Button>
                        </Col>
                    </Row>
                </form>
            </Container>
        </section>
    );
}

const mapStateProps = state => {
    return {
        link_bucket: state.form.link_bucket,
        email: state.form.email
    }
  }
  
const mapDispatchProps = dispatch => {
    return {
        ontryGetLinkbucket: (file) => dispatch( actions.tryGetLinkbucket(file) ),
        onTryPostEmail: (typeForm,form) => dispatch( actions.tryPostEmail(typeForm,form) ),
        onEmailInit: () => dispatch( actions.emailInit() )
    }
}

export default connect(mapStateProps,mapDispatchProps)(JobsForm);