import React from 'react';
import Button from '../../../components/UI/Button/Button';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Jobs2 = props => (
    <section id="jobs-section2" className="my light-blue-bg">
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="title-triangle top-md"></div>
                    <h2 className="h4 black fw-600">{props.t('jobs:section2.title-1')}</h2>
                    <p>{props.t('jobs:section2.text-1')}</p>
                    <div className="d-flex flex-row my-5 justify-content-center justify-content-lg-start">
                        <Link to="#section-jobs">
                            <Button btnClasses="p-l h5 fw-600 text-uppercase">{props.t('jobs:section2.btn-1')}</Button>
                        </Link>
                        <Link to={{ pathname: "https://jobs.sysmatch.com/Candidatura" }} target="_new">
                            <Button btnClasses="p-l h5 fw-600 text-uppercase">{props.t('jobs:section2.btn-2')}</Button>
                        </Link>

                    </div>
                </Col>
                <Col xs={12} className="mt-5">
                    <div className="title-triangle top-md"></div>
                    <h2 className="h4 black fw-600">{props.t('jobs:section2.title-2')}</h2>
                    <p>{props.t('jobs:section2.text-2')}</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Jobs2;