import React from 'react';
import icon1 from "../../../../assets/images/meetingRoom/section-3/melhorias-qualidade-reunioes.svg";
import icon2 from "../../../../assets/images/meetingRoom/section-3/gestao-recursos-centralizada.svg";
import icon3 from "../../../../assets/images/meetingRoom/section-3/aumento-produtividade.svg";
import icon4 from "../../../../assets/images/meetingRoom/section-3/apoio-decisao.svg";
import { Col, Container, Row } from 'react-bootstrap';
import ItemIconButton from '../../../UI/ItemIconButton/ItemIconButton';

const MeetingRoom3 = props => (
    <section
        id="meetingRoom-section3"
        className="my-4 py-4 my-md-5 py-md-5">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600">{props.t('products:meetingRoom.section-3.title')}</h2>
            <Row>
                <Col lg={3} >
                    <ItemIconButton 
                        src={icon1}
                        title={props.t('products:meetingRoom.section-3.1')}
                        alt={props.t('products:meetingRoom.section-3.1_alt')}
                        isSquare
                    />
                </Col>
                <Col lg={3} >
                    <ItemIconButton 
                        src={icon2}
                        title={props.t('products:meetingRoom.section-3.2')}
                        alt={props.t('products:meetingRoom.section-3.1_alt')}
                        isSquare
                    />
                </Col>
                <Col lg={3} >
                    <ItemIconButton 
                        src={icon3}
                        title={props.t('products:meetingRoom.section-3.3')}
                        alt={props.t('products:meetingRoom.section-3.1_alt')}
                        isSquare
                    />
                </Col>
                <Col lg={3} >
                    <ItemIconButton 
                        src={icon4}
                        title={props.t('products:meetingRoom.section-3.4')}
                        alt={props.t('products:meetingRoom.section-3.1_alt')}
                        isSquare
                    />
                </Col>
            </Row>
        </Container>
    </section>
);

export default MeetingRoom3;