import React, { useState, useRef } from 'react';
import Banner from '../../UI/Banner/Banner';
import Span from '../JobsBannerSpan/JobsBannerSpan';

const HomeBanner = (props) => {
    const [ position, setPosition ] = useState({
        XAngle: 0,
		YAngle: 0,
    });

    const objRef = useRef(null);
    let timeout;
    const folowMouseHandler = (e) => {            
            const parent = objRef.current.offsetParent;
           
            const XRel = e.pageX - parent.getBoundingClientRect().left;
            const YRel = e.pageY - parent.getBoundingClientRect().top;
            const width = parent.getBoundingClientRect().width;
            const YAngle = -(0.5 - (XRel / width)) * 40;                
            const XAngle = (0.5 - (YRel / width)) * 40;
       
            setPosition({ XAngle  , YAngle  });        
    }

    
    let whenMouseMoves = (e) => {
        if( !objRef )
                return;
        clearTimeout(timeout);
        timeout = setTimeout(folowMouseHandler(e), 150);
      }

    const cleanPosition = () => {
        if( !objRef )
                return;
        clearTimeout(timeout);

            setPosition({
                XAngle: 0,
                YAngle: 0
            });
       
    }
    return (
        <Banner 
            id="jobs-banner" 
            imageBackground={<Span />}
            className="banner black pb-5" 
            title={props.t("jobs:section1.title")}
            text={props.t("jobs:section1.text")}
            timeDelay={2.4}
            mouseLeave={() => cleanPosition()}
            t={props.t}>
            <div ref={objRef} className="title text-center" onMouseMove={(e) => whenMouseMoves(e)}  
               style={{ 
                transform: "perspective(300px) translateZ( 30px) rotateX(" + position.XAngle + "deg) rotateY(" + position.YAngle + "deg)",
                transition: "transform 0.3s" 
             }}>
               
            <span className="span-top"><i></i> 
                <p className="h3 path fw-700">
                    <strong className="title-triangle top-md"></strong>
                    PROGRAM
                </p>
            </span>
            <p className="h3 fw-700">YOUR BUSINESS</p>
            <span className="span-button"><i></i> <p className="h3 path fw-700">FOR BETTER</p></span>
                 
            </div>
        </Banner>
    );
};

export default HomeBanner;