import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Head from '../../../components/Head/Head';
import Banner from '../../../components/Services/Outsourcing/OutsourcingBanner/OutsourcingBanner';
import Outsourcing2 from "../../../components/Services/Outsourcing/Outsourcing2/Outsourcing2";
import Outsourcing3 from "../../../components/Services/Outsourcing/Outsourcing3/Outsourcing3";
import { withTranslation } from 'react-i18next';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';

class Outsourcing extends Component {
    render(){
        return (
            <Auxiliary>
                <Head id="outsourcing"/>
                <Banner {...this.props} />
                <Outsourcing2 {...this.props} />
                <Outsourcing3 {...this.props} />
                <CallToAction
                    color="light-orange"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnText')}
                    />
            </Auxiliary>    
        );
    }
}

export default withTranslation()(Outsourcing);