import React from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import NewsUI from '../../UI/BlogUI/BlogUI';
import { Container } from 'react-bootstrap';
import { formatLink } from '../../../shared/utility';

// section 2 from Blog page with all articles listed
const Blog2 = props => {
    //format title to be the url
    const blogContent = props.i18n.getResource(props.i18n.language, 'blog')['news'];

    let blogArray = Object.keys(blogContent).reduce((array, key) => {
        const content = { ...blogContent[key], key: key };
        return [...array, content];
    }, [])

    let articles = blogArray.map((article, key) =>
        props.i18n.language !== 'pt' && !article['link'] ?
            (<Auxiliary
                key={key}
                title={article['content']['title']}
                text={article['summary']}
                img={require("../../../assets/images/blog/" + article["content"]["image"].name + ".jpg")}
                imgWebp={require("../../../assets/images/blog/" + article["content"]["image"].name + ".webp")}
                link={props.t('routes:blog.path') + '/' + formatLink(article['content']['url'])}
                target="_self"
                alt={article["content"]["image"].alt}
                date={article["content"]["fullDate"]}
            />) :
            (<Auxiliary
                key={key}
                title={article['content']['title']}
                text={article['summary']}
                img={require("../../../assets/images/blog/" + article["content"]["image"].name + ".jpg")}
                imgWebp={require("../../../assets/images/blog/" + article["content"]["image"].name + ".webp")}
                link={article['link'] ? article['link'] : (props.t('routes:blog.path') + '/' + formatLink(article['content']['url']))}
                target={article['link'] ? "_new" : "_self"}
                alt={article["content"]["image"].alt}
                date={article["content"]["fullDate"]}
            />)
    );

    articles.reverse();

    return (
        <section id="blog-section2" className="light-silver-bg">
            <Container>
                <div className="title-triangle top-md"></div>
                <h2 className="h4 black fw-600 text-uppercase">{props.t('blog:section2.title')}</h2>
            </Container>
            <NewsUI id="blog-section2">
                {articles}
            </NewsUI>
        </section>
    );
}
export default Blog2;