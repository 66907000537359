import React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormModal = props => (
    <Aux>
        <Backdrop className={"form " + (props.title ? '' : 'd-none')} clicked={props.closed} />
        {props.title &&
            <div id={props.id} className="modalForm">
                <Button btnClasses="fw-600 button-close" clicked={props.closed}><FontAwesomeIcon icon={['fas', 'times']} /></Button>
                <h2 className="black fw-600">{props.title}</h2>
                <p className="black">{props.text}</p>
                {props.btn && 
                    <Aux>
                        <Checkbox id="terms" link={props.btn.checkto} text={props.btn.checkText} checked={props.checked} />
                        {props.checked !== false &&
                            <Button btnClasses="my-4 mt-lg-5 p-l h5 fw-600 text-uppercase mx-0"  clicked={props.btn.clicked}>{props.btn.text}</Button>
                        }
                    </Aux>
                }
            </div>
        }
    </Aux>
) ;

export default FormModal;