import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Col } from 'react-bootstrap';
import ItemIconButton from '../../../UI/ItemIconButton/ItemIconButton';
import development from "../../../../assets/images/consulting/section-2/desenvolvimento-software.svg";
import outsourcing from "../../../../assets/images/consulting/section-2/outsourcing.svg";
import quality from "../../../../assets/images/consulting/section-2/sistemas-gestao.svg";
import nearshoring from "../../../../assets/images/consulting/section-2/nearshoring.svg";

const consulting2 = props => (
    <section 
        id="consulting-section2"
        className="my-4 py-4 my-md-5 py-md-5">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600">{props.t('services:consulting.section-2.title')}</h2>
            <Row>
                <Col xs={12} lg={3}>
                    <ItemIconButton 
                        src={development}
                        title={props.t('services:consulting.section-2.1')}
                        to={props.t('routes:development.path')}
                        alt={props.t('services:development.section-2.1_alt')}
 
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <ItemIconButton 
                        src={outsourcing}
                        title={props.t('services:consulting.section-2.2')}
                        to={props.t('routes:outsourcing.path')} 
                        alt={props.t('services:consulting.section-2.2_alt')}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <ItemIconButton 
                        src={quality}
                        title={props.t('services:consulting.section-2.3')}
                        to={props.t('routes:quality.path')}
                        alt={props.t('services:consulting.section-2.3_alt')}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <ItemIconButton 
                        src={nearshoring}
                        title={props.t('services:consulting.section-2.4')}
                        to={props.t('routes:nearshoring.path')} 
                        alt={props.t('services:consulting.section-2.4_alt')}
                    />
                </Col>
            </Row>
        </Container>
    </section>

);

export default consulting2;
