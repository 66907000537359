import React from 'react';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container';
import { Col } from 'react-bootstrap';
import Button from '../../../components/UI/Button/Button';
import {Link} from 'react-router-dom'

const techVisa = props => (
    <section 
        id="homepage-section7"
        className="my tech-visa-bg d-flex align-items-center">
        <Container>
            <div className="tech-visa-content">
                <Row>
                    <Col sm={7} className="white-bg arrow d-flex justify-content-center flex-column">
                        <div className="p-4">
                            <div className="title-triangle top-md"></div>
                            <h2 className="h4 black fw-600">{props.t("homepage:section-7.title")}</h2>
                            <p className="black">{props.t("homepage:section-7.txt")}</p>
                        </div>
                    </Col>
                    <Col sm={{ span: 4}} className="d-flex align-items-center justify-content-center flex-column pt-5"> 
                        <h2 className="white text-center fw-700">{props.t("homepage:section-7.claim")}</h2>
                        <Link to={props.t("routes:blog.path") +  "/6#top"} >
                            <Button btnClasses={'p-l h5 mt-4 fw-600'}>{props.t("homepage:section-7.btn")}</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>

);

export default techVisa;