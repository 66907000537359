import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/services-banner/Desenvolvimento-Medida.png";
import bannerImageWebpp from "../../../../assets/images/services-banner/Desenvolvimento-Medida.webp";

const developmentBanner = (props) =>(
    <Banner 
        id="development-banner" 
        className="banner black pb-5 services-banner" 
        title="services:development:section-1.title"
        text="services:development:section-1.txt"
        t={props.t}
        imageBannerRight={bannerImage}
        imageBannerRightWebp={bannerImageWebpp}
        timeDelay={1.55} 
    />
);

export default developmentBanner;