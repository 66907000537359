import React, { useEffect, useRef, useState } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';
import Button from '../../UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { isIE } from '../../../shared/utility';
import { structuredDataJob } from '../../../shared/structuredData';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

const JobModal = props => {
    const refTextarea = useRef(null);
    const refModalFooter = useRef(null);
    const refBtn = useRef(null);
    const [btnShared, setBtnShared] = useState(props.t('jobs:section-4.copy-url'));
    const url = window.location.href;
    const _isIE = isIE() ? ' ie' : '';

    let mailTo = props.t('jobs:section-4.mail-to').replace(';title', encodeURIComponent(props.id && props.job.jobTitle));
    mailTo = mailTo.replace(';url', url);

    const copy = () => {
        if (refTextarea.current) {
            refTextarea.current.value = url;
            refTextarea.current.select();
            refTextarea.current.setSelectionRange(0, 99999);
            document.execCommand("copy");
        } else
            navigator.clipboard.writeText(url);

        setBtnShared(props.t('jobs:section-4.copied-url'));

        setTimeout(() => {
            setBtnShared(props.t('jobs:section-4.copy-url'));
        }, 1500);
    }
    const overrideTooltip = (left, top, currentTarget) => {
        const _top = window.innerWidth > 767 ? (refModalFooter.current.offsetTop + currentTarget.offsetTop - 35) : top;
        return ({ left: left, top: _top });
    }

    useEffect(() => {
        window.addEventListener('resize', ReactTooltip.rebuild());
        return () => {
            window.removeEventListener('resize', ReactTooltip.rebuild());
        }
    }, []);

    //set structured Data
    useEffect(() => {
        if (props.job)
            //sve state globally
            props.onSetStructuredData(structuredDataJob(props.job));
        else
            props.onSetStructuredData(null);

    }, [props.job]);


    return (
        <Auxiliary>
            <Backdrop className={props.id === false ? 'd-none' : ''} clicked={props.closed} />
            {props.id !== false &&
                <div className="modalJob">
                    <Button btnClasses="fw-600" clicked={props.closed}><FontAwesomeIcon icon={['fas', 'times']} /></Button>
                    <div className="title-triangle top-md fw-600"></div>
                    <h3 className="h2 fw-600">{props.job.jobTitle}</h3>
                    <div className="p" dangerouslySetInnerHTML={{ __html: props.job.functionDescription.replace(/<[^>]+>/g, "").replace(/(\r\n\r\n\r\n)/gm, "") }} />
                    {/* <div className="p" dangerouslySetInnerHTML={{ __html: props.job.job_requirements.replace(/<[^>]+>/g, "").replace(/(\r\n\r\n\r\n)/gm, "") }} /> */}
                    <div ref={refModalFooter} className="modalFooter">
                        <Link to={{ pathname: "https://jobs.sysmatch.com/Candidatura?recruitmentRequestId=" + props.job.id }} target="_new" className="text-decoration-none">
                            <Button btnClasses="fw-600 p p-l" clicked={() => props.setJob(props.job.jobTitle)}>{props.t('jobs:section-4.btnSubmit')}</Button>

                        </Link>
                        <div className="icons">
                            <Link to='#' onClick={(e) => {
                                window.location = mailTo;
                                e.preventDefault();
                            }} >
                                <Button tip={props.t('jobs:section-4.share-email')} btnClasses={'wh-35 h5 fw-600 ml-0 green'}>
                                    <FontAwesomeIcon icon={['far', 'envelope']} />
                                </Button>
                            </ Link>
                            {/* <Link target="new" to={"//www.linkedin.com/shareArticle?mini=true&url=" + url} >
                                <Button tip={props.t('jobs:section-4.share-lk')} btnClasses={'wh-35 h5 fw-600 ml-0 green'}>
                                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                                </Button>
                            </ Link> */}
                            <Link target="new" to={"//www.facebook.com/sharer/sharer.php?u=" + url} >
                                <Button tip={props.t('jobs:section-4.share-fb')} btnClasses={'wh-35 h5 fw-600 ml-0 green'}>
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                </Button>
                            </ Link>
                            <Button _ref={refBtn} tip="" for="btnTooltip" clicked={() => copy()} btnClasses={'wh-35 h5 fw-600 ml-0 green'}>
                                <FontAwesomeIcon icon={['fas', 'link']} />
                            </Button>
                            {
                                !_isIE &&
                                <Auxiliary>
                                    <ReactTooltip overridePosition={({ left, top }, currentEvent, currentTarget, node, place, desiredPlace, effect, offset) => overrideTooltip(left, top, currentTarget)} />
                                    <ReactTooltip delayHide={1000} id="btnTooltip" overridePosition={({ left, top }, currentEvent, currentTarget, node, place, desiredPlace, effect, offset) => overrideTooltip(left, top, currentTarget)} >{btnShared}</ReactTooltip>
                                </Auxiliary>
                            }
                            <textarea className="hide_copy" ref={refTextarea} />
                        </div>
                    </div>
                </div>}
        </Auxiliary>
    );
}

const mapDispatchProps = dispatch => {
    return {
        onSetStructuredData: (data) => dispatch(actions.setJobStructuredData(data)),
    }
}

export default connect(null, mapDispatchProps)(JobModal);