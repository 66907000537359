import React, { useEffect, useState } from 'react';
import { Image, Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logos/logo_preto_2.png';
import Menu from './Menu/Menu';
import { getRouteKey } from "../../shared/utility";
import { useLocation } from "react-router-dom";

const Header = props => {
    const [classes, setClasses] = useState('');
    const { pathname } = useLocation();

    useEffect(() => {
        const [key] = getRouteKey(props.i18n, pathname);
        const logoColor = props.t('routes:' + key + '.logo');
        setClasses(logoColor);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);


    return (
        <header className={"header" + props.className}>
            <Container>
                <Row>
                    <Col xs={5} md={4} lg={3}>
                        <Link to={props.t('routes:homepage.path')} >
                            <Image className={classes} src={logo} alt={props.t('partials:logo_alt')} fluid />
                        </ Link>
                    </Col>
                    <Col xs={7} md={8} lg={9} >
                        <Menu {...props} />
                    </Col>
                </Row>
            </Container>

        </header>
    );
}

export default withTranslation(['routes', 'partials'])(Header);