import React from 'react';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';

const itemIconButton = props => {
    let icon =  <div className="diamond-top-icon">
                    <div className="icon">
                        <img alt={props.alt} src={props.src}/>
                    </div>
                </div>;
    if ( props.isSquare ){
        icon =  <div className="square-icon">
                    <img alt={props.alt} src={props.src}/>
                </div>;
    }
    return(
       <div id={props.id} className="d-flex align-items-center flex-row flex-lg-column h-100 my-4 my-lg-0"> 
            <div className="d-flex align-items-center flex-row flex-lg-column">
                {icon}
                <h2 className="p text-lg-center fw-700 black py-4 pl-4 m-0 pl-lg-0">{props.title}</h2>
            </div>
            {props.to &&
                <Link to={props.to + "#top"} className="mt-lg-auto">
                    <Button btnClasses={'wh-35 h5 fw-600'}>+</Button>
                </Link>
            }
        </div>
    );
};

export default itemIconButton;