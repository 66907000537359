import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Rgpc3 = props => (
    <section id="rgpc-section3" className="my pt-5">
        <div className='white-shape'></div>
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600 mb-5">{props.t('services:rgpc:section-3.title')}</h2>
            <p className='mb-5'>{props.t('services:rgpc:section-3.text')}</p>
            {/* Text content */}
            <div>
                <div className='d-flex align-items-center mb-4'>
                    <div className='arrow-container'>
                        <div className='arrow'>
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </div>
                    </div>
                    <h3 className='h5 fw-600 m-0'>{props.t('services:rgpc:section-3.1.title')}</h3>
                </div>
                <p>{props.t('services:rgpc:section-3.1.text')}</p>
            </div>
            {/* Text content */}
            <div>
                <div className='d-flex align-items-center mb-4'>
                    <div className='arrow-container'>
                        <div className='arrow'>
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </div>
                    </div>
                    <h3 className='h5 fw-600 m-0'>{props.t('services:rgpc:section-3.2.title')}</h3>
                </div>
                <p>{props.t('services:rgpc:section-3.2.text')}</p>
            </div>
            {/* Text content */}
            <div>
                <div className='d-flex align-items-center mb-4'>
                    <div className='arrow-container'>
                        <div className='arrow'>
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </div>
                    </div>
                    <h3 className='h5 fw-600 m-0'>{props.t('services:rgpc:section-3.3.title')}</h3>
                </div>
                <p>{props.t('services:rgpc:section-3.3.text')}</p>
            </div>
            {/* Text content */}
            <div>
                <div className='d-flex align-items-center mb-4'>
                    <div className='arrow-container'>
                        <div className='arrow'>
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </div>
                    </div>
                    <h3 className='h5 fw-600 m-0'>{props.t('services:rgpc:section-3.4.title')}</h3>
                </div>
                <p>{props.t('services:rgpc:section-3.4.text')}</p>
            </div>
        </Container>
    </section>
);


export default Rgpc3;