import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import TitleDegrade from "../../../UI/TitleDegrade/TitleDegrade";
import { ReactComponent as BgImage } from "../../../../assets/images/oval-seccao-17.svg";

const development6 = props =>(    
    <section 
        id="development-section6"
        className="development-section6 bg-oval-list my-4 py-4 my-md-5 py-md-5">
         <BgImage className="backgroundImg"/>
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600">{props.t('services:development.section-6.title')}</h2>
            <Row className="align-items-center pt-4 pt-md-5">
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic1')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic2')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic3')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic4')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic5')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic6')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic7')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic8')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic9')} />
                </Col>
                <Col lg={5}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic10')} />
                </Col>
                <Col lg={{offset:1, span:5}}>
                    <TitleDegrade className="green" title={props.t('services:development:section-6.topic11')} />
                </Col>
            </Row>
        </Container>
    </section>
);

export default development6;