import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Head from '../../../components/Head/Head';
import Banner from '../../../components/Services/Quality/QualityBanner/QualityBanner';
import Quality2 from '../../../components/Services/Quality/Quality2/Quality2';
import Quality3 from '../../../components/Services/Quality/Quality3/Quality3';
import Quality4 from '../../../components/Services/Quality/Quality4/Quality4';
import CallToAction from "../../../components/UI/CallToAction/CallToAction";
import { withTranslation } from 'react-i18next';
class Quality extends Component {
    render(){
        return (
            <Auxiliary>
                <Head id="quality"/>
                <Banner {...this.props} />
                <Quality2 {...this.props} />
                <Quality3 {...this.props} />
                <Quality4 {...this.props} />
                <CallToAction
                    color="light-orange"
                    subTitleSmall={this.props.t('services:quality:section-5.title')}
                    text={this.props.t('services:quality:section-5.text')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnText')}
                />
            </Auxiliary>    
        );
    }
}

export default withTranslation()(Quality);