import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/utility';

const initialState = {
    link_bucket: null,
    email: null,
    product: null
}

const reducer = ( state = initialState, action ) => {
    switch( action.type ){
        case actionTypes.GET_LINK_SUCESS: 
            return updateObject( state, { link_bucket: action.link_bucket } );
        case actionTypes.GET_LINK_FAIL: 
            return updateObject( state, { link_bucket: false } );
        case actionTypes.POST_EMAIL_SUCESS: 
            return updateObject( state, { email: true } );
        case actionTypes.POST_EMAIL_FAIL: 
            return updateObject( state, { email: false } );
        case actionTypes.EMAIL_INIT: 
            return updateObject( state, { link_bucket: null,email: null } );
        case actionTypes.SET_PRODUCT:
            return updateObject( state , { product: action.product } );    
        case actionTypes.PRODUCT_INIT:
            return updateObject( state, { product: null } );
        default:
            return state;           
    }
}

export default reducer;