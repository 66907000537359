import React, { Component } from 'react';
//import { withCookies } from 'react-cookie';
import * as actions from '../../store/actions/index';
import {connect}  from 'react-redux';
import { withTranslation } from 'react-i18next';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/UI/Button/Button'
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class CookiesBanner extends Component {

    acceptCookies = (e) => {
        e.preventDefault();
        this.props.onHandleSetCookie(this.props.cookies, 'accepted', true, this.props.localeOnState);
        localStorage.setItem('cookiesAccepted', true);
        this.props.hideBanner();
    }

    removeCookies = (e) => { 
        e.preventDefault();
        //cookies google
        let hostname = document.location.hostname.replace('www.','');
        document.cookie = '_gat= ;domain=.'+hostname+';expires=Thu, 01 Jan 1970 00:00:00 GMT';
        document.cookie = '_gat_UA-72968223-3= ;domain=.'+hostname+';expires=Thu, 01 Jan 1970 00:00:00 GMT';
        document.cookie = '_gid= ;domain=.'+hostname+';expires=Thu, 01 Jan 1970 00:00:00 GMT';
        document.cookie = '_ga= ;domain=.'+hostname+';expires=Thu, 01 Jan 1970 00:00:00 GMT';
        
        const cookiesAccepted = this.props.cookies.cookies.accepted;
        this.props.onHandleRemoveAllCookies(this.props.cookies);
        localStorage.setItem('cookiesAccepted', false);
        this.props.hideBanner();      
     
        if ( cookiesAccepted ){
            setTimeout(
                () => window.location.reload(),
                1000
            );        
        }
    }
    
    render(){
        return (
             this.props.showBanner && navigator.userAgent !== 'ReactSnap' ? (
                <Auxiliary>
                    <section id="cookies-bottom-banner" className="p-5 d-flex justify-content-end flex-column">
                        <h2 className="text-center white h5">{this.props.t('partials:cookies-banner.title')}</h2>
                        <p className="text-center white"> {this.props.t('partials:cookies-banner.text')} 
                            <Link className="orange p" to={this.props.t('routes:cookies.path')}> {this.props.t('partials:cookies-banner.btn1')}</Link>.
                        </p>
                        <Link onClick={(e) => this.removeCookies(e)} className="white text-center p">{this.props.t('partials:cookies-banner.btn2')}</Link>
                        <Row className="justify-content-center mt-3">
                            <Button btnClasses="p-l h5 fw-600 text-uppercase" clicked={(e) => this.acceptCookies(e)}>{this.props.t('partials:cookies-banner.btn3')}</Button>
                        </Row>
                    </section>
                </Auxiliary>) : null
        );
    }
}

const mapStateProps = (state, ownProps) => {
    return({
        localeCookie: state.cookies.locale,
        acceptedCookie: state.cookies.accepted,
        localeOnState: state.lang.locale
      });
}

const mapDispacthToProps = dispatch => {
   
    return{
        onHandleSetCookie: (cookies, name, value, localeOnState) => dispatch(actions.setCookie(cookies, name, value, localeOnState)),
        onHandleRemoveAllCookies: (cookies, name) => dispatch(actions.removeAllCookies(cookies)),
    }
}

export default connect(mapStateProps, mapDispacthToProps)(withTranslation()(CookiesBanner));