import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Banner from '../../../components/Products/MeetingRoom/MeetingRoomBanner/MeetingRoomBanner';
import MeetingRoom2 from '../../../components/Products/MeetingRoom/MeetingRoom2/MeetingRoom2';
import MeetingRoom3 from '../../../components/Products/MeetingRoom/MeetingRoom3/MeetingRoom3';
import Head from '../../../components/Head/Head';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';
class MeetingRoom extends Component {
    render(){
        return(
            <Auxiliary>
                <Head id="meeting-room" />
                <Banner {...this.props} />
                <MeetingRoom2  {...this.props} />
                <MeetingRoom3  {...this.props} />
                <CallToAction
                    color="light-green"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnTextProducts')}
                    product='meetingRoom'
                    />
                
            </Auxiliary>
        );
    }
}

export default withTranslation()(MeetingRoom);