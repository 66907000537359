import * as actionTypes from './actionsTypes';

export const trySetLocale = (locale,i18n,cookies) => {
    return {
        type: actionTypes.SET_LOCALE_TRY,
        locale,
        i18n,
        cookies
    }
}

export const sucessSetLocale = (locale) => {
    return {
        type: actionTypes.SET_LOCALE_SUCESS,
        locale: locale,
    }
}

export const failSetLocale = (locale) => {
    return {
        type: actionTypes.SET_LOCALE_FAIL,
        locale: locale,
    }
}

export const tryRedirectTo = ( i18n, locale , cookies , redirect = true ) => {
    return {
        type: actionTypes.REDIRECT_TRY,
        i18n,
        locale,
        cookies,
        redirect
    }
}
export const sucessRedirectTo = ( redirectTo ) => {
    return {
        type: actionTypes.REDIRECT_SUCESS,
        redirectTo
    }
}
export const failRedirectTo = () => {
    return {
        type: actionTypes.REDIRECT_FAIL,
    }
}

export const TryAutoSetLocale = ( i18n , locale , cookies , isHome ) => {
    return{
        type: actionTypes.LOCALE_CHECK_STATE,
        i18n,
        locale,
        cookies,
        isHome
    }
}