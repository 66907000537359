import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import JobsItem from './JobsItem';
import Spinner from '../../UI/Spinner/Spinner';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import FormField from '../../UI/FormField/FormField';
import JobModal from './JobModal';
import { updateObject } from '../../../shared/utility';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { useRef } from 'react';
import { Suspense } from 'react';
const CustomScroll = React.lazy(() => import('react-custom-scroll'));

const useStateWithLocalStorage = localStorageKey => {

    const [value, setValue] = React.useState(
        localStorage.getItem(localStorageKey) || ''
    );

    React.useEffect(() => {
        try {
            localStorage.setItem(localStorageKey, value);
        } catch (e) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return [value, setValue];
};

const Jobs = props => {

    const [listJobs, setListJobs] = useState(<Spinner />);
    const [modalId, setModalId] = useState(false);
    const [error, setError] = useState(null);
    const [jobsIdsVisited, setJobsIdsVisited] = useStateWithLocalStorage('jobs');
    const ref = useRef();
    const [loading, setLoading] = useState(true);
    const [scroll, setScroll] = useState(0);
    const [searchData, setSearchData] = useState({ work: '', locality: '' });
    const [listFilter, setListFilter] = useState(<Spinner />);
    let { id } = useParams();
    const history = useHistory()

    const uriRemoveId = () => {
        let url = history.location.pathname;
        const _id = listJobs[modalId] ? listJobs[modalId].props.id : id;
        url = url.replace('/' + _id, '');
        history.replace(url);
    };

    const uriAddId = () => {
        if (listJobs[modalId] && !history.location.pathname.includes(listJobs[modalId].props.id)) {
            let url = history.location.pathname + '/' + listJobs[modalId].props.id;
            url = url.replace('//', '/');
            history.replace(url);
        }
    }

    const setJobTitle = (jobTitle) => {
        props.setJobTitle(jobTitle);
        setModalId(false);
    }
    useEffect(() => {
        if (loading) {
            setLoading(false);
            return;
        }
        if (modalId !== false) {
            uriAddId();
            if (jobsIdsVisited && jobsIdsVisited.includes(listJobs[modalId].props.id))
                return;
            const ids = [...jobsIdsVisited, listJobs[modalId].props.id];

            setJobsIdsVisited(ids);
        } else
            uriRemoveId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalId]);

    useEffect(() => {
        if (typeof id === 'undefined' || !listJobs.length) {
            return;
        }

        let checkId = false;
        for (let i = 0; i < listJobs.length; i++) {
            if (listJobs[i] && listJobs[i].props.id === parseInt(id)) {
                checkId = i;
            }
        }
        if (checkId !== false && ref) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
            setModalId(checkId);
        } else {
            uriRemoveId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [listJobs, id]);

    useEffect(() => {
        if (props.allJobs) {
            let idsJobs = [];
            const jobs = props.nJobs ? Object.entries(props.allJobs).slice(0, props.nJobs) : props.allJobs;
            let list = Object.keys(jobs).map(key => {
                if (!props.allJobs[key].id)
                    return null;

                idsJobs.push(props.allJobs[key].id);
                if (props.nJobs)
                    return <JobsItem
                        home
                        key={key}
                        id={props.allJobs[key].id}
                        title={props.allJobs[key].jobTitle}
                        jobsIdsVisited={jobsIdsVisited}
                        link={props.t('routes:jobs.path') + '/' + props.allJobs[key].id} />;

                return <JobsItem
                    key={key}
                    id={props.allJobs[key].id}
                    title={props.allJobs[key].jobTitle}
                    jobsIdsVisited={jobsIdsVisited}
                    clicked={() => setModalId([key])}
                    link={props.t('routes:jobs.path') + '/' + props.allJobs[key].id}
                />;

            });
            list = list.filter(item => item !== null);
            setListJobs(list);
            setListFilter(list);
            setScroll(2);

            const jobsIds = localStorage.getItem('jobs');
            if (!jobsIds)
                return;
            let ids = jobsIds.split(',');
            if (ids.filter) {
                ids = ids.filter(id => idsJobs.includes(parseInt(id))); // remove old ids
                setJobsIdsVisited(ids);
            }
        }
        if (props.error)
            setError(<p>{props.msgError}</p>);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const search = e => {
        const id = e.target.id;
        const value = e.target.value;

        setSearchData(updateObject(searchData, { [id]: value }));
        let list = listJobs.filter((job) => {
            if (!job.props.title)
                return false;
            const explode = job.props.title.split(/[–|-]/);
            const work = explode[0];
            const locality = explode[1] ? explode[1] : explode[0];
            const data = id === 'work' ? { work: value, locality: searchData['locality'] } : { work: searchData['work'], locality: value };
            return work.toLowerCase().includes(data['work'].toLowerCase())
                && locality.toLowerCase().includes(data['locality'].toLowerCase());
        });

        if (!list.length)
            list = <div className="jobItem"><p className="fw-700">{props.t('jobs:section-4.search')}</p></div>;

        setListFilter(list);
    };

    return (
        <section
            id="section-jobs"
            ref={ref}
            className={(props.className ? props.className : '') + (modalId === null || modalId === false ? '' : ' modalOpen')}>
            {!props.nJobs &&
                <div className="light-yellow-bg"></div>
            }
            <Container>
                <Row>
                    <Col>
                        <h2 className="h4 black fw-600 pl3">
                            <div className="title-triangle top-md"></div>
                            {props.title}
                            {props.nJobs &&
                                <div className="title-triangle down-sm"></div>}
                        </h2>
                    </Col>
                </Row>
                {error ? error :
                    <Aux>
                        {props.search &&
                            <Row className="search">
                                <Col className="ml3" md={6}>
                                    <FormField type="text" id="work" title={props.t('jobs:section-4.work')} onInput={(e) => search(e)} onKeyUp={(e) => search(e)} />
                                </Col>
                                <Col className="mt-4 mt-md-0" md={6}>
                                    <FormField type="text" id="locality" title={props.t('jobs:section-4.locality')} onInput={(e) => search(e)} onKeyUp={(e) => search(e)} />
                                </Col>
                            </Row>
                        }
                        <div className="list-jobs my-5">
                            {props.nJobs ? listJobs :
                                <Auxiliary>
                                    <JobModal
                                        id={modalId}
                                        closed={() => setModalId(false)}
                                        job={modalId !== false && props.allJobs[modalId] ? props.allJobs[modalId] : null}
                                        setJob={setJobTitle}
                                        t={props.t} />
                                    <Suspense fallback={<Spinner />}>
                                        <CustomScroll heightRelativeToParent="100%" scrollTo={scroll}>
                                            {listFilter}
                                        </CustomScroll>
                                    </Suspense>
                                </Auxiliary>
                            }
                        </div>
                        {props.nJobs &&
                            <Link to={props.link} className="float">
                                <Button btnClasses={'m-0 p-l h5 fw-600 text-uppercase float-right'}>{props.button}</Button>
                            </Link>
                        }
                    </Aux>
                }
            </Container>
        </section>
    );
}

const mapStateProps = state => {
    return {
        allJobs: state.jobs.jobs,
        error: state.jobs.error
    }
}

export default connect(mapStateProps)(Jobs);