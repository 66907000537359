import React from 'react';
import Banner from '../../../UI/Banner/Banner';
import bannerImage from "../../../../assets/images/products-banner/Software-Assembleias-Acionistas.png";
import bannerImageWebpp from "../../../../assets/images/products-banner/Software-Assembleias-Acionistas.png";

const ShareholderMeetingBanner = props => (
        <Banner 
            id="shareholderMeeting-banner" 
            className="banner black pb-5 products-banner" 
            title="products:shareholderMeeting:section-1.title"
            text="products:shareholderMeeting:section-1.txt"
            button={{ to: "routes:contacts.path" , text: "partials:call-to-action.btnTextProducts" }}
            product='shareholderMeeting'
            t={props.t} 
            imageBannerRight={bannerImage} 
            imageBannerRightWebp={bannerImageWebpp} 
            timeDelay={1.55} 
        />
);

export default ShareholderMeetingBanner;