import React from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import ItemIconButton from  '../../../UI/ItemIconButton/ItemIconButton';
import icon1 from "../../../../assets/images/quality/section-3/eficacia-organizacao.svg";
import icon2 from "../../../../assets/images/quality/section-3/reducao-custos.svg";
import icon3 from "../../../../assets/images/quality/section-3/conformidade.svg";
import icon4 from "../../../../assets/images/quality/section-3/vantagem-competitiva.svg";
import icon5 from "../../../../assets/images/quality/section-3/documentacao.svg";

const quality3 = props =>(
    <section id="quality-section3">
        <Container>
            <div className="title-triangle top-md fw-600"></div>
            <h2 className="h4 black fw-600">{props.t('services:quality.section-3.title')}</h2>
            <Row>
                <Col sm={6} lg>
                    <ItemIconButton 
                        src={icon1}
                        title={props.t("services:quality:section-3.topic1")}
                        alt={props.t('services:quality:section-3.topic1_alt')}
                    />
                </Col>
                <Col sm={6} lg>
                    <ItemIconButton 
                        src={icon2}
                        title={props.t("services:quality:section-3.topic2")}
                        alt={props.t('services:quality:section-3.topic1_alt')}
                    />
                </Col>
                <Col sm={6} lg>
                    <ItemIconButton 
                        src={icon3}
                        title={props.t("services:quality:section-3.topic3")}
                        alt={props.t('services:quality:section-3.topic1_alt')}
                    />
                </Col>
                <Col sm={6} lg>
                    <ItemIconButton 
                        src={icon4}
                        title={props.t("services:quality:section-3.topic4")}
                        alt={props.t('services:quality:section-3.topic1_alt')}
                    />
                </Col>
                <Col sm={6} lg>
                    <ItemIconButton
                        src={icon5}
                        title={props.t("services:quality:section-3.topic5")}
                        alt={props.t('services:quality:section-3.topic1_alt')}
                    />
                </Col>
            </Row>

        </Container>
        
    </section>
  

);

export default quality3;