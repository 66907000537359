import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Banner from '../../../components/Products/ComercialPaper/ComercialPaperBanner/ComercialPaperBanner';
import ComercialPaper2 from '../../../components/Products/ComercialPaper/ComercialPaper2/ComercialPaper2';
import { withTranslation } from 'react-i18next';
import Head from '../../../components/Head/Head';
import CallToAction from '../../../components/UI/CallToAction/CallToAction';

class ComercialPaper extends Component {
    render(){
        return (
            <Auxiliary>
                <Head id="comercial-paper" />
                <Banner {...this.props} />
                <ComercialPaper2 {...this.props} />
                <CallToAction
                    color="light-green"
                    title={this.props.t('partials:call-to-action.title1')}
                    subTitle={this.props.t('partials:call-to-action.title2')}
                    link={this.props.t('routes:contacts.path')}
                    btn={this.props.t('partials:call-to-action.btnTextProducts')}
                    product="commercialPaper"
                    />
            </Auxiliary>
        );
    }
}

export default withTranslation()(ComercialPaper);