import React from 'react';
import ButtonOvalImage from '../../../UI/ButtonOvallmage/ButtonOvalImage';
import Aux from "../../../../hoc/Auxiliary/Auxiliary";
import pmesImg from "../../../../assets/images/attendenceManagement/section-2/10_PME.png";
import pmesImgWebp from "../../../../assets/images/attendenceManagement/section-2/10_PME.webp";
import bigCompaniesImg from "../../../../assets/images/attendenceManagement/section-2/6_empresas-grandes.png";
import bigCompaniesImgWebp from "../../../../assets/images/attendenceManagement/section-2/6_empresas-grandes.webp";
import hospitalsImg from "../../../../assets/images/attendenceManagement/section-2/7_hospitais.png";
import hospitalsImgWebp from "../../../../assets/images/attendenceManagement/section-2/7_hospitais.webp";
import schoolsImg from "../../../../assets/images/attendenceManagement/section-2/9_estabelecimento-ensino.png";
import schoolsImgWebp from "../../../../assets/images/attendenceManagement/section-2/9_estabelecimento-ensino.webp";
import publicInstitutionsImg from "../../../../assets/images/attendenceManagement/section-2/8_instituicoes-publicas.png";
import publicInstitutionsImgWebp from "../../../../assets/images/attendenceManagement/section-2/8_instituicoes-publicas.webp";

const images = [pmesImg, bigCompaniesImg, hospitalsImg, schoolsImg, publicInstitutionsImg];
const imagesWeb = [pmesImgWebp, bigCompaniesImgWebp, hospitalsImgWebp, schoolsImgWebp, publicInstitutionsImgWebp];

const AttendenceManagement2 = props => {
    const alts = [ 
        props.t('products:attendenceManagement.section-2.1_alt'),
        props.t('products:attendenceManagement.section-2.2_alt'),
        props.t('products:attendenceManagement.section-2.3_alt'),
        props.t('products:attendenceManagement.section-2.4_alt'),
        props.t('products:attendenceManagement.section-2.5_alt')
     ]
    return(
        <section id="attendenceManagement-section2" className="my pt-5">
            <ButtonOvalImage
                title={props.t('products:attendenceManagement.section-2.title')}
                images={images}
                imagesWeb={imagesWeb}
                alts={alts}>
                <Aux>
                    {props.t('products:attendenceManagement.section-2.1')}
                </Aux>
                <Aux>
                    {props.t('products:attendenceManagement.section-2.2')}
                </Aux>
                <Aux>
                    {props.t('products:attendenceManagement.section-2.3')}
                </Aux>
                <Aux>
                    {props.t('products:attendenceManagement.section-2.4')}
                </Aux>
                <Aux>
                    {props.t('products:attendenceManagement.section-2.5')}
                </Aux>
            </ButtonOvalImage>
        </section>
    );
}

export default AttendenceManagement2;