import { React, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

const Head = props => {
    // const imageName = 'metatags:' + props.id + '.image';
    const imageName = props.id + '.image';
    const langFolder = props.i18n.language === "pt" ? "pt" : "en";
    const img = props.i18n.exists('metatags:' + props.id + '.image', langFolder) ? require("../../assets/images/shared/" + langFolder + props.i18n.getResource(langFolder, 'metatags', imageName)) : null;
    const title = 'metatags:' + props.id + '.title';
    const description = 'metatags:' + props.id + '.description';
    const linkServer = 'http://sysmatch.com';
    const currentLink = navigator.userAgent !== 'ReactSnap' ? window.location.href : (linkServer + window.location.pathname);

    //Structured Data
    const [displayStructuredData, setDisplayStructuredData] = useState(null);
    useEffect(() => {
        setDisplayStructuredData(props.structuredData)
    }, [props.structuredData]);


    let imgLink = null;
    if (img)
        imgLink = navigator.userAgent !== 'ReactSnap' ? window.location.origin + img : linkServer + img;
    return (
        <Helmet defaultTitle="Sysmatch">
            <html lang={props.i18n.language} />
            <title>{props.t(title) + " | Sysmatch"}</title>
            <meta name="title" property="og:title" content={props.t(title) + " | Sysmatch"} />
            {props.id !== "cookies" && props.id !== "privacy" && props.id !== "personal-data" && (<meta name="description" content={props.t(description)} />)}
            {props.id !== "cookies" && props.id !== "privacy" && props.id !== "personal-data" && (<meta name="og:description" content={props.t(description)} />)}
            <meta property="og:url" content={currentLink} />
            <meta property="og:type" content="WebPage" />
            <meta property="og:site_name" content="Sysmatch" />
            {img ? (<meta name="image" property="og:image" content={imgLink} />) : null}
            {/* HELMET DOCS https://www.npmjs.com/package/react-helmet#reference-guide */}
            <meta name="google-site-verification" content="sRQCa3X6Eeih8fAnOTu5xl_JJOHLkMfqCmNuw_4xEsg" />
            {/* Uncomment for prod version*/}
            {displayStructuredData && <script type="application/ld+json">{displayStructuredData}</script>}
        </Helmet>

    );
}

const mapStateProps = state => {
    return {
        structuredData: state.jobs.jobStructuredData,
    }
}

export default connect(mapStateProps)(withTranslation('metatags')(Head));