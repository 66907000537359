import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Banner from '../../UI/Banner/Banner';
import Checkbox from '../../UI/Checkbox/Checkbox';
import FormField from '../../UI/FormField/FormField';
import Button from '../../UI/Button/Button';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { elementsToJson } from '../../../shared/utility';
import FormModal from '../../UI/FormModal/FormModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const ContactsBanner = (props) => {
    const { email, product, i18n, location } = props;
    const [checkTerms, setCheckTerms] = useState(true);
    const [checked, setChecked] = useState(false);
    const refBtnSubmit = useRef();
    const refFieldMessage = useRef();
    const [paramProduct, setParamProduct] = useState(null);
    const [oldProduct, setOldProduct] = useState(null);
    const [modalForm, setModalForm] = useState({
        title: null,
        text: null,
    });

    const checkForm = (e) => {
        if (e.target.form.checkValidity()) {
            submitHandler(e);
        } else {
            let scroll = true;

            const _elements = Object.entries(e.target.form.elements);
            _elements.forEach((obj) => {
                if (!isNaN(parseInt(obj[0]))) {
                    const event = new Event('input', {
                        bubbles: true,
                        cancelable: true,
                    });
                    obj[1].dispatchEvent(event);
                    if (scroll && obj[0] !== "0") {
                        scroll = false;
                        obj[1].scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'nearest'
                        });
                    }
                }

            })
        }
    }

    const checkProduct = (e) => {
        if (!product && !paramProduct)
            return;

        const temp = product ? product : paramProduct;
        const elm = e.target;
        const text = props.t('contacts:section-1.form.' + temp) + "\n";
        if (!elm.value.includes(text)) {
            elm.value = text;
            setOldProduct({
                trans: text,
                value: elm.value
            });
        }
    }

    const submitHandler = (event) => {
        event.preventDefault();
        if (!event.target.form.elements.terms.checked) {
            if (checkTerms) {
                //openModal X
                setModalForm(
                    {
                        title: props.t('contacts:section-1.rgpd.title'),
                        text: props.t('contacts:section-1.rgpd.text'),
                        btn: {
                            checkTo: props.t('routes:privacy.path'),
                            checkText: props.t('jobs:section-6.terms'),
                            text: props.t('jobs:section-6.btn2'),
                            clicked: (e) => refBtnSubmit.current.click()
                        }
                    });
                setCheckTerms(false);
            } else {
                //openModal Y
                setCheckTerms(true);
                refBtnSubmit.current.disabled = true;
                refBtnSubmit.current.classList.add('loading');
                setTimeout(() => {
                    setModalForm({
                        title: props.t('contacts:section-1.errorRgpd.title'),
                        text: props.t('contacts:section-1.errorRgpd.text')
                    });
                    refBtnSubmit.current.disabled = false;
                    refBtnSubmit.current.classList.remove('loading');
                }, 5000);
            }
            return;
        }
        if (event.target.form.elements.nameH && event.target.form.elements.nameH.value !== '') {
            setModalForm({
                title: props.t('contacts:section-1.errorSend.title'),
                text: props.t('contacts:section-1.errorSend.txt')
            });
            return;
        }
        const _elements = elementsToJson(event.target.form.elements);

        if (_elements.nameH)
            delete _elements.nameH;
        props.onTryPostEmail('contacts', _elements);
        refBtnSubmit.current.disabled = true;
        refBtnSubmit.current.classList.add('loading');
    }
    const history = useHistory();
    useEffect(() => {
        if (email) {
            /* setModalForm({
                title: "props.t('contacts:section-1.sucessSend.title')",
                text: props.t('contacts:section-1.sucessSend.txt'),
            }); */
            props.onEmailInit();
            refBtnSubmit.current.classList.remove('loading');
            history.push(props.t("routes:contacts.path") + '/' + props.t("routes:thank_you.name") + "#top");
        } else if (email === false) {
            setModalForm({
                title: props.t('contacts:section-1.errorSend.title'),
                text: props.t('contacts:section-1.errorSend.txt')
            });

            props.onEmailInit();
            refBtnSubmit.current.disabled = false;
            refBtnSubmit.current.classList.remove('loading');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    useEffect(() => {
        const param = location.search;
        if (product || param.includes('?product=')) {
            let temp = product;
            if (!temp) {
                temp = param.replace('?product=', '');
                setParamProduct(temp);
            }
            const text = props.t('contacts:section-1.form.' + temp) + "\n"
            refFieldMessage.current.value = text;
            refFieldMessage.current.classList.add('green');
            setOldProduct({
                trans: text,
                value: ''
            });
        }
        return () => {
            if (product) {
                props.onInitProduct();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, refFieldMessage])

    useEffect(() => {
        if ((product || paramProduct) && oldProduct) {
            let temp = product
            if (!temp) {
                temp = paramProduct;
                setParamProduct(temp);

            }
            const elm = refFieldMessage.current;
            const text = props.t('contacts:section-1.form.' + temp) + '\n';
            if (elm.value.includes(text))
                return;
            let value = elm.value;
            value = value.replaceAll(oldProduct.trans, '');

            elm.value = text + value;
            setOldProduct({
                trans: text,
                value: value
            });
        }

        if (!product && !oldProduct && paramProduct) {
            const text = props.t('contacts:section-1.form.' + paramProduct) + '\n';
            refFieldMessage.current.value = text;
            setOldProduct({
                trans: text,
                value: ''
            });
        }
    }, [i18n.language, refFieldMessage.current])
    return (
        <Banner
            id="contacts-banner"
            className="banner black pb-5 services-banner"
            t={props.t}
            timeDelay={1.55}>
            <h2 className="h2 white fw-600 ml-lg-5 pl-lg-5 mb-5">
                <div className="title-triangle top-md white"></div>
                {props.t('contacts:section-1.title')}
            </h2>
            <FormModal
                id='contactsModal'
                title={modalForm.title}
                text={modalForm.text}
                checked={checked}
                btn={modalForm.btn}
                closed={() => setModalForm({ title: null, text: null })} />
            <form >
                <Row>
                    <Col md={6}>
                        <input type="text" name="nameH" id="nameH" className="d-none" autoComplete="off" />
                        <FormField type="text" name="name" minLength={3} title={props.t('contacts:section-1.form.name')} errorMsg={props.t('jobs:section-6.error')} requiredMsg={props.t('jobs:section-6.errorRequired')} required></FormField>
                        <FormField type="email" name="email" placeholder={props.t('contacts:section-1.form.emailPlaceholder')} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title={props.t('contacts:section-1.form.email')} errorMsg={props.t('jobs:section-6.errorMail')} requiredMsg={props.t('jobs:section-6.errorRequired')} required></FormField>
                        <FormField type="text" name="company" minLength={2} title={props.t('contacts:section-1.form.company')} errorMsg={props.t('jobs:section-6.error')}  ></FormField>
                    </Col>
                    <Col md={6}>
                        <FormField _ref={refFieldMessage} type="textarea" name="message" minLength={10} title={props.t('contacts:section-1.form.message')} onKeyUp={(e) => checkProduct(e)} errorMsg={props.t('jobs:section-6.error')} requiredMsg={props.t('jobs:section-6.errorRequired')} required></FormField>
                        <FormField type="text" name="phone" minLength={9} pattern="^[+\d](?:.*\d)?$" title={props.t('contacts:section-1.form.phone')} errorMsg={props.t('jobs:section-6.errorPhone')} ></FormField>
                        <Checkbox id="terms" name="terms" link={props.t('routes:privacy.path')} text={props.t('contacts:section-1.form.privacy')} onChange={(e) => setChecked(e.target.checked)} />
                        <Button _ref={refBtnSubmit} type="button" clicked={(e) => checkForm(e)} btnClasses="float-right mt-5 p-l h5 fw-600">{props.t('contacts:section-1.form.btn')}</Button>
                    </Col>
                </Row>
            </form>
            <div className="black-bg black-section"></div>
            <a href={props.t("routes:contacts.path") + '/' + props.t("routes:thank_you.name")} className='d-none'></a>
        </Banner>
    );
}

const mapStateProps = state => {
    return {
        email: state.form.email,
        product: state.form.product
    }
}

const mapDispatchProps = dispatch => {
    return {
        onTryPostEmail: (typeForm, form) => dispatch(actions.tryPostEmail(typeForm, form)),
        onEmailInit: () => dispatch(actions.emailInit()),
        onInitProduct: () => dispatch(actions.initProduct())

    }
}

export default connect(mapStateProps, mapDispatchProps)(ContactsBanner);