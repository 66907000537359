import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { ReactComponent as BgImage } from "../../../assets/images/oval-seccao-19.svg";

const ItemDashedSection = props => {
    const classes = props.className;
    const item = props.left ?
    <Col className={"itemDashed d-flex flex-lg-row-reverse align-items-center py-5 py-lg-2 pr " + classes } sm={{ span: 10, offset: 1 }} md={{ span: 9, offset: 3 }} lg={{ span: 7, offset: 0 }}>
            <p className="number fw-700 mb-0 mr-3 mr-lg-0 order-1">{props.number}</p>
            <p className="title fw-700 mr-3 mr-md-5 mb-0 order-1">{props.title}</p>
            <div className="img order-0  order-lg-2 mr-lg-5">
                <BgImage className="backgroundImg"/>
                <Image src={props.img} />
            </div>
        </Col>:
        <Col className={"itemDashed d-flex flex-row align-items-center py-5 pl  py-lg-2 " + classes } sm={{ span: 10, offset: 1 }} md={{ span: 9, offset: 3 }} lg={{ span: 7, offset: 5 }}> 
            <p className="number fw-700 mr-3 mr-md-5 mb-0 order-1">{props.number}</p>
            <p className="title fw-700 mr-3 mr-md-5 mb-0 order-1">{props.title}</p>
            <div className="img order-0 order-lg-2">
                <BgImage className="backgroundImg"/>    
                <Image src={props.img} />
            </div>
        </Col>
        ;

    return(
        <Row>
            {item}
        </Row>
    );
}

export default ItemDashedSection;