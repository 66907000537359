import React from 'react';
import elmBlack from '../../../assets/images/jobs/section-3/elm-black.svg';
import { Col, Image } from 'react-bootstrap';

const TitleTextItem = props =>(
    <Col xs={12} sm={{span:8 , offset: 2}} md={{span:12 , offset: 0}} lg={4} className="p-0">
        <div className="titleTextItem">
            <div className="title">
                <h3 className="p fw-600" >{props.title}</h3>
                <Image src={elmBlack} />
            </div>
            <div className="text">
                <p>{props.text}</p>
            </div>
        </div>
    </Col>
);

export default TitleTextItem;