import React from 'react';
import { Container , Col, Row } from 'react-bootstrap';
import IconTitleButtonItem from './IconTitleButtonItem';

const IconTitleButton = props => {
    const items =  props.children.map( (child ,i) => (
        <IconTitleButtonItem
            key={i}
            {...child.props}
            />
    )); 
    
    //three columns
    const itemsMobile = [...items];
    const itemsDesktop = [...items];
    const cut = Math.round(itemsDesktop.length / 3);
    const items2 = items.splice(itemsDesktop.length % 2 ? cut + 1 : cut,itemsDesktop.length - cut);
    const items3 = items2.splice(cut,itemsDesktop.length);

    //two columns
    const cut2column = Math.round(itemsMobile.length / 2);
    const itemsMobile2 = itemsMobile.splice(cut2column, itemsMobile.length - cut2column);
    

    return(
        <section id={props.id} className="IconTitleButton my"> 
            <Container>
                <h2 className="h4 black fw-600 mb-5">
                    <div className="title-triangle top-sm"></div>
                        {props.title}
                    <div className="title-triangle down-md d-none d-md-block"></div>
                </h2>
                <Row className="d-none d-lg-flex">
                    <Col xs={12} lg={4} >
                        {items}     
                    </Col>
                    <Col xs={12} lg={4}>
                        {items2}
                    </Col>
                    <Col xs={12} lg={4}>
                        {items3}
                    </Col>
                </Row>
                <Row className="d-lg-none">
                    <Col xs={12} md={6} >
                        {itemsMobile}     
                    </Col>
                    <Col xs={12} md={6}>
                        {itemsMobile2}
                    </Col>
                </Row>
            </Container>            
        </section>
    );
};

export default IconTitleButton;