import React, { useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Col } from 'react-bootstrap';
import TitleDegradeButton from '../TitleDegradeButton/TitleDegradeButton';
import { ReactComponent as BgImage } from "../../../assets/images/oval-seccao15.svg";

const ButtonOvalImage = props => {
    const {images,imagesWeb, alts} = props;
    let imgs = [];
    const refImgs = useRef([]);
    const childrenCount = React.Children.count(props.children);
    let obj = {0: true};
    imgs = [...imgs, <picture key={0} ref={el => refImgs.current[0] = el}>
                        {imagesWeb && imagesWeb[0] && <source srcSet={imagesWeb[0]} type="image/webp" />}
                        <source srcSet={images[0]} type="image/png" />
                        <img src={images[0]} alt={alts && alts[0] && alts[0] } />
                    </picture>
            ];

    for(let i = 1; i <= childrenCount; i ++){
        obj[i] = false;
        imgs = [...imgs, <picture key={i} ref={el => refImgs.current[i] = el} className="d-none">
                            {imagesWeb && imagesWeb[i] && <source srcSet={imagesWeb[i]} type="image/webp" />}
                            <source srcSet={images[i]} type="image/png" />
                            <img src={images[i]} alt={alts && alts[i] && alts[i] } />
                        </picture>
                ];
    }
    const [active, setActive] = useState(
       {...obj}
    );

    const handleClickTitle = (area) => {
        const oldObj = active;
        //set all to false
        for( let prop in oldObj){
           oldObj[prop] = false;
        }
        //set new to true
        oldObj[area] = true;

        setActive({...oldObj});

        refImgs.current.forEach( (img,i) => (i !== area ? 
            img.classList.add('d-none') : 
            img.classList.remove('d-none'))
        );
 
    }
    //build titles section
    const titles = props.children.map((child,key) => {
        return(
            <TitleDegradeButton 
                key={key}
                title={child}
                active={active[key]} 
                clicked={() => handleClickTitle(key)}/>
        );
    });
    return (
        <div className="buttonOvalImage">
            <Container>
                <div className="title-triangle top-md fw-600"></div>
                <h2 className="h4 black fw-600">{props.title}</h2>
                <div className="OvalImage">
                    {props.bg ? 
                    props.bg
                    :<BgImage className="backgroundImg" alt="" />}
                    <Row>
                        <Col xs={{span:10, offset:1}} sm={{span:8, offset:2}} md={{span:6, offset:0}} lg={{span:5 ,offset:0}}>
                            {titles}
                        </Col>
                        <Col className="d-none d-md-flex align-items-center justify-content-center" md={{span:6, offset:0}} lg={6}>
                             {imgs}
                        </Col>
                    </Row>
                </div>
            </Container> 
        </div>
    );
};

export default ButtonOvalImage;