
import React from 'react';
import { Row } from 'react-bootstrap';
import Carousel from '../Carousel/Carousel';
import TitleTextItem from './TitleTextItem';

const TitleText = props =>{
    const titleTextItens = props.children.map((item,i) => <TitleTextItem key={i} {...item.props} />)
    return (
        <div className={'titleText' + (props.className ? props.className : ''  )}>
            <div className="d-none d-lg-block">
                <Row className="align-items-stretch">
                    {titleTextItens}
                </Row>
            </div>
            <div className="d-block d-lg-none pb-5">
                <Carousel className="carouselTitleText">
                        {titleTextItens}
                </Carousel>
            </div>
        </div>
    );
}

export default TitleText;