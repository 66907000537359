import { React, useState } from 'react';
import { Image } from 'react-bootstrap';
import Button from '../../components/UI/Button/Button';

import Seta from "../../assets/images/thankYou/icon-seta.svg";
import Like from "../../assets/images/icon-like-thankYou.svg";
import Instagram from "../../assets/images/icon-Instagram.svg";
import InstagramOrange from "../../assets/images/icon-Instagram-orange.svg";
import Facebook from "../../assets/images/icon-Facebook.svg";
import FacebookOrange from "../../assets/images/icon-Facebook-orange.svg";
import Linkedin from "../../assets/images/icon-Linkedin.svg";
import LinkedinOrange from "../../assets/images/icon-Linkedin-orange.svg";
import Youtube from "../../assets/images/icon-youtube.svg";
import YoutubeOrange from "../../assets/images/icon-youtube-orange.svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ThankYouPage = props => {

    const [instagramImage, setInstagramImage] = useState(Instagram);
    const [facebookImage, setFacebookImage] = useState(Facebook);
    const [linkedinImage, setLinkedinImage] = useState(Linkedin);
    const [youtubeImage, setYoutubeImage] = useState(Youtube);

    return (
        <section className='ThankYouPage' id='top'>
            <div className="banner-span">
                <span className="black-mountain"></span>
            </div>
            <div className='content container'>
                <div className='d-flex flex-column flex-md-row align-items-center justify-content-start'>
                    <div className='col-md-4 col-lg-3'>
                        <Image fluid className='like-icon' src={Like} />
                    </div>
                    <div className='d-flex flex-column justify-content-center col-lg-7'>
                        <h1 className='title'>{props.t("thankYou:title")}</h1>
                        <p className='text'>{props.t("thankYou:text")}</p>
                        <div className='socials d-flex'>
                            <a className='button' href="https://www.linkedin.com/company/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setLinkedinImage(LinkedinOrange) }} onMouseOut={() => { setLinkedinImage(Linkedin) }}>
                                <Image fluid src={linkedinImage} />
                            </a>
                            <a className='button' href="https://www.instagram.com/sysmatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setInstagramImage(InstagramOrange) }} onMouseOut={() => { setInstagramImage(Instagram) }}>
                                <Image fluid src={instagramImage} />
                            </a>
                            <a className='button' href="https://www.facebook.com/SysMatch/" target="_blank" rel="noreferrer" onMouseOver={() => { setFacebookImage(FacebookOrange) }} onMouseOut={() => { setFacebookImage(Facebook) }}>
                                <Image fluid src={facebookImage} />
                            </a>
                            <a className='button' href="https://www.youtube.com/channel/UCusRuLSE5ZvwiXjDvK7YuWg/" target="_blank" rel="noreferrer" onMouseOver={() => { setYoutubeImage(YoutubeOrange) }} onMouseOut={() => { setYoutubeImage(Youtube) }}>
                                <Image fluid src={youtubeImage} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='second-mountain d-flex align-items-center'>
                <span className="blue-mountain"></span>
                <div className='container d-flex justify-content-end'>
                    <Image src={Seta} fluid />
                </div>
            </div>
            <div className='third-mountain d-flex align-items-start'>
                <span className="orange-mountain"></span>
                <div className='container d-flex justify-content-end px-0'>
                    <a href={props.t("routes:homepage.path")} >
                        <Button btnClasses={'p-l h5 mt-4 fw-600'}>{props.t("thankYou:btn")}</Button>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default ThankYouPage;