import React, { Component, Suspense } from 'react';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Head from '../../components/Head/Head'
import Certifications from '../../components/Homepage/Certifications/Certifications';
// import Blog from '../../components/Homepage/Blog/Blog';
import Techvisa from '../../components/Homepage/TechVisa/TechVisa';
import Team from '../../components/Homepage/Team/Team';
import Team2 from '../../components/Homepage/Team2/Team2';
import Highlights from '../../components/Homepage/Highlights/Highlights';
import Tecnologies from '../../components/Homepage/Technologies/Technologies';
import { Container } from 'react-bootstrap';
import { Trans, withTranslation } from 'react-i18next';
import HomeBanner from '../../components/Homepage/HomeBanner/HomeBanner';
import HomeJobs from '../../components/Homepage/HomeJobs/HomeJobs';
import Spinner from '../../components/UI/Spinner/Spinner';
const CallToAction = React.lazy(() => import("../../components/UI/CallToAction/CallToAction"));
const Blog = React.lazy(() => import('../../components/Homepage/Blog/Blog'));
class Homepage extends Component {
    render() {
        return (
            <Auxiliary>
                <Head id="homepage" />
                <HomeBanner {...this.props} />
                <Highlights />
                <section id="homepage-section-3" className="pb-5">
                    <Container>
                        <div className="title">
                            <span className="span-top"><i></i>
                                <h2 className="h4 path">
                                    <div className="title-triangle down-md"></div>
                                    <Trans t={this.props.t} i18nKey="homepage:section-3.title-1" /> {/*render with html entities (br, strong , i) */}
                                </h2>
                            </span>
                            <span className="span-button"><i></i> <h2 className="h4 path">{this.props.t('homepage:section-3.title-2')}</h2></span>
                        </div>
                    </Container>
                </section>
                {/* <Team {...this.props} /> */}
                <Team2 {...this.props} />
                <Tecnologies {...this.props} />
                <HomeJobs {...this.props} />
                <Techvisa {...this.props} />
                <Suspense fallback={<Spinner />}>
                    <Blog {...this.props} />
                    <CallToAction
                        color="light-orange"
                        title={this.props.t('partials:call-to-action.title1')}
                        subTitle={this.props.t('partials:call-to-action.title2')}
                        link={this.props.t('routes:contacts.path')}
                        btn={this.props.t('partials:call-to-action.btnText')}
                    />
                    <Certifications {...this.props} />
                </Suspense>
            </Auxiliary>
        );
    }
}

export default withTranslation()(Homepage);